import React from "react";

function UserStatsShema() {
  return (
    <div className="w-[500px] h-[230px] rounded-lg bg-gray-100 p-3 flex j-c-b mr-2">
      <div className="min-w-[200px] rounded-lg h-full bg-gray-200 shema mr-3"></div>

      <div className="flex flex-col items-start w-full h-full py-1">
        <div className="w-full h-1/2 rounded-md bg-gray-200 flex p-3">
          <aside className="flex flex-col w-1/2 border-r-3 border-gray-300">
            <div className="w-12 h-12 rounded-lg shema"></div>
            <div className="mt-2 w-12 h-2 shema rounded-lg"></div>
          </aside>
          <aside className="flex flex-col w-1/2">
            <div className="w-12 h-12 rounded-lg shema"></div>
            <div className="mt-2 w-12 h-2 shema rounded-lg"></div>
          </aside>
        </div>
        <div className="w-full h-1/2 rounded-md bg-gray-200 flex p-3 mt-3">
          <aside className="flex flex-col w-1/2 border-r-3 border-gray-300">
            <div className="w-12 h-12 rounded-lg shema"></div>
            <div className="mt-2 w-12 h-2 shema rounded-lg"></div>
          </aside>
          <aside className="flex flex-col w-1/2">
            <div className="w-12 h-12 rounded-lg shema"></div>
            <div className="mt-2 w-12 h-2 shema rounded-lg"></div>
          </aside>
        </div>
      </div>
    </div>
  );
}

export default UserStatsShema;
