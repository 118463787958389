import React from "react";
import { colors } from "../../../../Utils/Colors";
import Button from "../../../../Common/Components/Button/Button";
import { useNavigate } from "react-router-dom";

type NavProps = {
  text: string;
  icon: any;
  activeIcon: any;
  link: string;
  isActive: boolean;
  disable?: boolean;
};

function NavItem({
  text,
  icon,
  activeIcon,
  link,
  isActive,
  disable,
}: NavProps) {
  const navigate = useNavigate();
  return (
    <div className={`w-full h-[40px] flex pl-6 relative my-1`}>
      {isActive && (
        <div
          className="absolute left-1"
          style={{
            background: colors.blue,
            height: "91%",
            width: "9px",
            borderRadius: "10px",
          }}
        ></div>
      )}

      <Button
        text={text}
        icon={isActive ? activeIcon : icon}
        onClick={() => {
          localStorage.setItem("seenMobileUpdateModal", "true");
          navigate(link);
        }}
        iconSize={18}
        style={{
          background: isActive ? colors.blue : "transparent",
          width: "90%",
          height: "100%",
          fontSize: "0.85rem",
          fontWeight: isActive ? "500" : "300",
        }}
        className={` ${isActive ? "text-white" : "text-[#85899d]"} pointer-events-${disable ? "none" : "auto"}`}
      />
    </div>
  );
}

export default NavItem;
