import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./Slice/authSlice";
import modalSlice from "./Slice/modalSlice";

// Define the initial state
const initialState = {
  // Add your initial state properties here
};

// Create the Redux store using configureStore
const store = configureStore({
  reducer: {
    auth: authSlice,
    modal: modalSlice,
  },
  preloadedState: initialState,
});

export default store;
