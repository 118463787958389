import React, { useState, useEffect } from "react";
import PageWrapper from "../Wrapper/Wrapper";

import check from "../../../../Common/Assets/Images/check.png";
import backarrow from "../../../../Common/Assets/Images/backarrow.png";
import ghs from "../../../../Common/Assets/Images/ghana.png";
import us from "../../../../Common/Assets/Images/america.png";
import { useNavigate } from "react-router-dom";
import Constants from "../../../../Utils/Constants";
import axiosInstance from "../../../../setup/axios";
import Cookies from "js-cookie";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import Momo from "./Momo";
import momoimage from "../../../../Common/Assets/Icons/momoimage.png";
import bank from "../../../../Common/Assets/Images/bank.png";

import closeIcon from "../../../../Common/Assets/Images/Close.png";
import ValidateMomoModal from "./ValidateMomoModal";
import MomoContactDetails from "./MomoContactDetails";
import Bank from "./Bank";
import BankContactDetails from "./BankContactDetails";
import Select from "react-select";

function Sell() {
  const [assets, setAssets] = useState([]);
  const [normalFee, setNormalFee] = useState<any>();
  const [fastFee, setFastFee] = useState<any>();
  const [sellRate, setsellRate] = useState<any>();
  const [amount, setAmount] = useState<any>();
  //eslint-disable-next-line
  const [address, setAddress] = useState("");
  //eslint-disable-next-line
  const [screenName, setScreenName] = useState("sell");

  const [limitErrors, setLimitErrors] = useState("");
  //eslint-disable-next-line
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  //eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState("");
  const [amountError, setAmountError] = useState("");
  //eslint-disable-next-line
  const [checked, setChecked] = useState("");
  // const [paymentType, setPaymentType] = useState<any>();
  const [selectedAssetId, setSelectedAssetId] = useState<any>();
  const [totalAmount, setTotalAmount] = useState(""); // State for total amount
  //eslint-disable-next-line
  const [currency, setCurrency] = useState("");
  const [minimumAmount, setMinimumAmount] = useState("");
  //eslint-disable-next-line
  const [instock, setInstock] = useState(false);
  //eslint-disable-next-line
  const [order, setOrder] = useState<any>({});
  const [sellOrder, setSellOrder] = useState<any>({});
  const [showForm, setShowForm] = useState(false);
  //eslint-disable-next-line
  const [currencyName, setCurrencyName] = useState("");
  const [payoutMethods, setPayoutMethods] = useState<any[]>([]);
  const [openMomoModal, setOpenMomoModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [supportedPayments, setSupportedPayments] = useState<any>([]);
  const [selectedPayment, setSelectedPayment] = useState<any>();
  const [selectedPaymentId, setSelectedPaymentId] = useState<string>("");

  const [momoOperator, setMomoOperator] = useState<string>("");
  const [showNameDisplay, setShowNameDisplay] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [input, setInput] = useState("");
  const [fullname, setFullName] = useState("");

  const [openBankModal, setOpenBankModal] = useState(false);
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [accountNumberInput, setAccountNumberInput] = useState("");
  const [accountNameInput, setAccountNameInput] = useState("");
  const [selectedBankId, setSelectedBankId] = useState<any>("");
  const [selectBankName, setSelectBankName] = useState("");
  const [supportedPayouts, setSupportedPayouts] = useState<any>([]);
  const [paymentType, setPaymentType] = useState<any>("");
  const [verifyError, setVerifyError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlePaymentMethods = (selectedPayment: any) => {
    // console.log("selectedPayment", selectedPayment);
    setSelectedPayment(selectedPayment);
    setPaymentType(selectedPayment.value);
  };
  // console.log("paymentType", selectedPayment);

  const paymentStyles = {
    control: (styles: any) => ({
      ...styles,
      border: "1px solid #E0E0E0", // Set style (solid) and width (2px)

      height: "53px",
      borderRadius: "10px",
    }),
  };

  const customeTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "lightblue",
      primary: "#E5E4E2",

      textAlign: "left",
    },
  });
  const handleDisplayBankDets = () => {
    setOpenBankModal(false);
    verifyBankName();
    setShowBankDetails(true);
  };
  useEffect(() => {
    const getPayoutMehtods = async () => {
      try {
        const response = await axiosInstance.get(
          `${Constants.API_BASE_URL}/payments/supported?type=BANK`,
        );
        setSupportedPayouts(
          response.data.map((payment: any) => ({
            value: payment?.id,
            label: payment?.accountNumber,
            image: payment?.payment?.logoUrl,
          })),
        );
      } catch (error) {
        // console.error("Error loading payment methods:", error);
      }
    };

    getPayoutMehtods();
  }, []);

  const verifyBankName = async () => {
    setIsLoading(true);

    axiosInstance
      .get(
        `${Constants.API_BASE_URL}/payments/account/query?accountNumber=${accountNumberInput}&paymentId=${selectedBankId}`,
      )
      .then((res) => {
        // console.log("response", res.data);
        setAccountNameInput(res.data.accountName);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log("error", err);
        setVerifyError("There is something wrong with the information entered");
        setIsLoading(false);
      });
  };
  const handlePayout = (e: any) => {
    const selectedPaymentId = e.value;
    setSelectedPaymentId(selectedPaymentId);
    const selectedPayment = supportedPayouts.find(
      (payment: any) => payment.value === selectedPaymentId,
    );

    if (selectedPayment) {
      setSelectedBankId(selectedPayment.value);
      setSelectBankName(selectedPayment.label);
    }
  };
  // const handleShowBankDetails = () => {
  //   verifyBankName();
  //   setShowBankDetails(true);
  //   setShowNameDisplay(false);
  // };
  const handleBank = () => {
    // console.log("handleBankMomo");
    setIsOpen(false);
    setOpenBankModal(true);
    // Open Bank modal
  };

  // const verifyName = async () => {
  //   axiosInstance
  //     .get(
  //       `${Constants.API_BASE_URL}/payments/account/query?accountNumber=${input}&paymentId=${selectedPaymentId}`
  //     )
  //     .then((res) => {
  //       console.log("response", res.data);
  //       setFullName(res.data.accountName);
  //     })
  //     .catch((err) => {
  //       console.log("error", err);
  //     });
  // };

  const verifyName = async () => {
    setIsLoading(true);

    axiosInstance
      .get(
        `${Constants.API_BASE_URL}/payments/account/query?accountNumber=${input}&paymentId=${selectedPaymentId}`,
      )
      .then((res) => {
        // console.log("response", res.data);
        setFullName(res.data.accountName);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log("error", err);
        setVerifyError("There is something wrong with the information entered");
        setIsLoading(false);
      });
  };
  const handleInput = (e: any) => {
    setInput(e.target.value);
  };

  const handleShowDetails = () => {
    verifyName();
    setShowDetails(true);
    setShowNameDisplay(false);
  };
  const handleContinue = () => {
    setOpenMomoModal(false);
    setShowNameDisplay(true);
  };

  const handleClickMomo = (value: any, id: string, operator: string) => {
    setSelectedPayment(value);
    setSelectedPaymentId(id);
    setMomoOperator(operator);
  };

  useEffect(() => {
    const getSupportedPayments = async () => {
      try {
        const response = await axiosInstance.get(
          `${Constants.API_BASE_URL}/payments/supported?type=MOMO`,
        );
        setSupportedPayments(response.data);
        // console.log("supportedPayments", supportedPayments);
      } catch (error) {
        // console.error("Error loading payment methods:", error);
      }
    };

    getSupportedPayments();
    //eslint-disable-next-line
  }, []);

  const handleMomo = () => {
    // console.log("handleMomo");
    setIsOpen(false);
    setOpenMomoModal(true);
    // Open Momo modal
  };

  const getPayoutMehtods = () => {
    axiosInstance
      .get(`${Constants.API_BASE_URL}/payments/user?type=PAYOUT`)
      .then((res) => {
        // console.log(res.data);
        setPayoutMethods(
          res.data.map((payment: any) => ({
            value: payment?.id,
            label: payment?.accountNumber,
            image: payment?.payment?.logoUrl,
          })),
        );
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    getPayoutMehtods();
  }, []);

  const [code, setCode] = useState("");
  const userLevel = Cookies.get("level") ?? "Limited";
  const level = {
    cap: 300,
    Limited: 300,
    Intermediate: 1000,
    Full: 2000,
  };

  const hasErrors =
    !!amountError ||
    !!errorMessage ||
    limitErrors.includes("upgrade") ||
    !amount ||
    !selectedPayment;

  //eslint-disable-next-line
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  // console.log("selectedassetid", selectedAssetId);

  const submitOrder = (event: React.FormEvent) => {
    event.preventDefault();

    if (parseFloat(amount) >= 10000) {
      window.open("https://wa.me/message/HT3MBH3X46FPG1", "_blank");
      return; // Exit the function if Whatsapp redirect is triggered
    }
    axiosInstance
      .post(`${Constants.API_BASE_URL}/orders/sell`, {
        amount: amount,
        walletId: selectedAssetId,
        rate: Number(sellRate),

        asset: code,
        paymentMethodId: paymentType,
      })
      .then((res) => {
        // console.log("res", res);
        setSellOrder(res.data);
        // console.log("order", order);
      })
      .catch((err) => {
        //console.log("err", err);
      });
  };

  // console.log("sellOrder", paymentType);

  useEffect(() => {
    // console.log("buyOrder", sellOrder);
    if (sellOrder.status === "success") {
      navigate("/sell-details", { state: { sellOrder, screenName } });
    }
    //eslint-disable-next-line
  }, [sellOrder]);

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAmount(Number(value));
  };
  // const handleRadioChange = (value: any) => {
  //   setChecked(value);
  // };
  const calculateTotalAmount = () => {
    const enteredAmount = amount;
    let fee = "";
    if (checked === "normal") {
      fee = normalFee;
    } else if (checked === "fast") {
      fee = fastFee;
    }
    const totalInGhs =
      Number(enteredAmount) * sellRate + Number(fee) * sellRate;

    if (amount) {
      setTotalAmount(totalInGhs.toFixed(2));
    } else {
      setTotalAmount("0");
    }
  };
  useEffect(() => {
    calculateTotalAmount();
    //eslint-disable-next-line
  }, [amount, selectedAssetId, checked]);

  const handleClick = (
    assetId: any,
    normalFee: any,
    fastFee: any,
    sellRate: any,
    currencyName: any,
    currency: any,
    minimumAmount: any,
    instock: any,
    code: any,
  ) => {
    setSelectedAssetId(assetId);

    setNormalFee(normalFee);
    setFastFee(fastFee);
    setsellRate(sellRate);
    setCurrencyName(currencyName);
    setCurrency(currency);
    setMinimumAmount(minimumAmount);
    setInstock(instock);
    setCode(code);
    setShowForm(true);
  };

  const canGoBack = window.history.state.idx !== 0;
  const navigate = useNavigate();
  const goBack = (e: any) => {
    e.preventDefault();
    navigate(-1);
  };

  // const SubmitPaymentMethod = () => {
  //   if (!Boolean(paymentMethod?.network)) {
  //     Notify("top-center", "Please add a payment method", "warning");
  //     setShowPaymentMethod(true);

  //     return;
  //   }
  // };

  // console.log("paymentMethod", paymentMethod?.network);

  const loadAssets = async () => {
    try {
      const response = await axiosInstance.get(
        `${Constants.API_BASE_URL}/assets`,
      );
      setAssets(response.data);
      // console.log("assets", assets);
    } catch (error) {
      // console.error("Error loading assets:", error);
    }
  };

  useEffect(() => {
    loadAssets();
    //eslint-disable-next-line
  }, [address, selectedAssetId]);

  useEffect(() => {
    if (amount === "0") {
      setAmountError("");
    } else {
      if (minimumAmount > amount) {
        setAmountError(
          `Minimum amount is ${minimumAmount}. Cannot proceed with transaction`,
        );
        setIsButtonDisabled(true);
      } else {
        setAmountError("");
        setIsButtonDisabled(false);
      }
    }
  }, [amount, minimumAmount]);

  // const payment = [
  //   {
  //     value: "mtn",
  //     label: "MTN Mobile Money",
  //     image: momo, // Imported directly without string path
  //   },
  // ];

  // const selectStyles = {
  //   control: (styles: any) => ({
  //     ...styles,
  //     border: "none",
  //     width: "150px",
  //   }),
  // };

  // const paymentStyles = {
  //   control: (styles: any) => ({
  //     ...styles,
  //     border: "1px solid #E0E0E0", // Set style (solid) and width (2px)

  //     height: "53px",
  //     borderRadius: "10px",
  //   }),
  // };

  // const handleChange = (selectedOption: any) => {
  //   console.log("selectedOption", selectedOption);
  //   setSelectedOption(selectedOption);
  // };

  // const handlePayment = (selectedPayment: any) => {
  //   console.log("selectedPayment", selectedPayment);
  //   setSelectedPayment(selectedPayment);
  //   setPaymentType(selectedPayment.value);
  // };

  // const customeTheme = (theme: any) => ({
  //   ...theme,
  //   colors: {
  //     ...theme.colors,
  //     primary25: "lightblue",
  //     primary: "#E5E4E2",

  //     textAlign: "left",
  //   },
  // });

  useEffect(() => {
    if (parseFloat(amount) >= 10000) {
      setLimitErrors(
        `Contact support on whatsapp for orders 10,000 USD and above`,
      );
    } else if (
      parseFloat(amount) >
      (userLevel === "Limited"
        ? level.Limited
        : userLevel === "Intermediate"
          ? level.Intermediate
          : level.Full)
    ) {
      setLimitErrors(
        `You cannot sell more than  ${
          userLevel === "Limited"
            ? level.cap
            : userLevel === "Intermediate"
              ? level.Intermediate
              : level.Full
        }.00 ${code.toUpperCase()}. Please upgrade your account to increase limit`,
      );
    } else {
      setLimitErrors("");
    }

    // eslint-disable-next-line
  }, [amount]);

  // console.log("asset", assets);
  return (
    <PageWrapper
      content={
        <div className=" items-center lg:items-start md:items-start flex flex-col">
          <div className="lg:w-full w-[350px] flex justify-between pr-5  ">
            <button
              onClick={(e) => goBack(e)}
              className={` flex cursor-pointer  focus:outline-none ${
                !canGoBack ? "opacity-50" : <> </>
              }`}
              disabled={!canGoBack}
            >
              {" "}
              <div>
                <img src={backarrow} alt="" className="h-3 w-4" />
              </div>
              <div className="ml-3 cursor-pointer text-[12px]">Back</div>
            </button>
          </div>
          <div className=" lg:w-full w-[350px] text-[30px] font-bold text-left mt-4">
            Place A Sell Order{" "}
          </div>

          <div className="flex flex-col lg:flex-row md:flex-row items-center lg:items-start md:items-start w-[600px] lg:w-full md:w-full my-10  ">
            {" "}
            {/* Updated here */}
            {/* currencies */}
            <div>
              <div className="flex flex-col items-start w-[350px] lg:w-[540px] md:w-[540px] border-l border-r border-t rounded-[10px] pt-2 overflow-hidden  overflow-x-visible lg:mt-0 md:mt-2">
                <div className="border-b py-2 px-4  w-full">
                  <li className="text-left"> Select assets to sell</li>
                </div>
                <div className="w-full">
                  <ul className="list-none">
                    {assets &&
                      assets.map((asset: any) => (
                        <div
                          key={asset.id} // Use a unique identifier for each item
                          className={`py-3 px-4 border-b 
                            ${
                              selectedAssetId === asset.walletId
                                ? " border border-theme last:rounded-b-[10px]  "
                                : ""
                            }`}
                          onClick={() =>
                            handleClick(
                              asset.walletId,
                              asset.data.normalProcessingFee,
                              asset.data.priorityProcessingFee,
                              asset.data.sellRate,
                              asset.name,
                              asset.network,
                              asset.data.minimumAmount,
                              asset.data.instock,
                              asset.code,
                            )
                          }
                          style={{
                            background: `${
                              selectedAssetId === asset.walletId
                                ? "rgb(0,80,255, .05)"
                                : "white"
                            }`,
                          }}
                        >
                          <div className="flex w-full cursor-pointer">
                            <div>
                              <img
                                src={asset.icon}
                                alt=""
                                className="w-8 h-8"
                              />
                            </div>
                            <div className="ml-3 items-start flex flex-col">
                              <div>
                                {" "}
                                <li className="text-left text-bold text-[16px]">
                                  {asset.name}
                                </li>
                              </div>

                              <div className="flex">
                                <li className="text-left">{asset.code}</li>
                                <li className="text-left bg-green-500 rounded-[5px] ml-2 px-1 text-white text-[14px]">
                                  {asset.networkName}
                                </li>
                              </div>
                            </div>

                            {selectedAssetId === asset.walletId && (
                              <img
                                src={check}
                                alt="Selected"
                                className="ml-auto h-6 w-6"
                              />
                            )}
                          </div>
                        </div>
                      ))}
                  </ul>
                </div>
              </div>

              <div className="flex flex-col items-start w-[350px] lg:w-[540px]">
                <div className="text-[18px] font-bold p-2 text-left mt-2">
                  Payment Instructions
                </div>
                <div className="p-4 mb-4 text-sm text-cyan-800 rounded-lg bg-cyan-100">
                  <div className="text-left">
                    Please send your transaction with priority. We will only
                    process your payment after 1 confirmation. Sending with
                    higher priority ensures that your transaction is confirmed
                    faster, allowing us to complete your payment promptly.
                  </div>
                </div>
              </div>
            </div>
            {/* form */}
            {showForm ? (
              <div className="flex flex-col items-start w-[350px] lg:w-[540px] md:w-[540px]  border rounded-[10px]  mt-6 md:mt-2 lg:mt-0 lg:mx-4 pt-2 ml-0 md:ml-4 ">
                <div className="py-2 px-4 overflow-hidden w-full">
                  <li className="text-left font-medium">Amount to sell</li>
                </div>
                <div className="w-full">
                  <ul className="list-none">
                    <div className="py-2 px-2 border rounded-[10px] mx-4 flex ">
                      {/* {openSelect && (
                      <div className="">
                        <Select
                          formatOptionLabel={(options) => (
                            <div className="flex items-center">
                              {options?.image && (
                                <img
                                  src={options.image}
                                  className="w-4 h-4 "
                                  alt=""
                                />
                              )}
                              <span className="text-[12px] font-medium ml-2">
                                {options?.label}
                              </span>
                            </div>
                          )}
                          defaultValue={selectedOption}
                          onChange={handleChange}
                          options={options}
                          placeholder="BTC"
                          styles={selectStyles}
                          theme={customeTheme}
                        />
                      </div>
                    )} */}
                      <div className="flex ">
                        <img src={us} alt="" className="w-7 h-7 rounded-full" />

                        <input
                          className=" text-[14px] py-2 ml-2"
                          placeholder="USD 0.00"
                          type="text"
                          required
                          value={amount}
                          onChange={handleAmountChange}
                        />
                      </div>
                    </div>
                    {amountError && (
                      <div className="ml-4 mt-2">
                        {" "}
                        <p className="text-red-500 mt-2 text-left ml-2  text-[13px]">
                          {amountError}
                        </p>
                      </div>
                    )}

                    {limitErrors.length > 0 && (
                      <div className="text-red-500 mt-2 text-left text-[13px] ml-4">
                        {limitErrors}
                      </div>
                    )}
                  </ul>

                  {/* fees */}

                  {/* amount you will get */}
                  <div className="py-2 px-4 overflow-hidden w-full mt-2">
                    <li className="text-left font-medium">
                      You will receive <small>( Rate: {sellRate})</small>
                    </li>
                    <div className="py-2 px-2 border rounded-[10px] flex mt-3">
                      <img src={ghs} alt="" className="w-7 h-7 rounded-full" />
                      <div className="font-bold text-[14px] flex py-2 ml-2">
                        <h1>GHS</h1> <div className="ml-2">{totalAmount}</div>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="py-2 px-4  w-full mt-4">
                    {payoutMethods.length > 0 && (
                      <div className="text-left font-medium">
                        {" "}
                        Select your payout method
                      </div>
                    )}
                    <div className=" mt-2  ">
                      {payoutMethods.length > 0 && (
                        <Select
                          required
                          formatOptionLabel={(payoutMethods) => (
                            <div className="flex items-center">
                              {payoutMethods?.image && (
                                <img
                                  src={payoutMethods?.image}
                                  className="w-8 h-8 rounded-full"
                                  alt=""
                                />
                              )}
                              <span className="text-[14px] font-medium ml-2">
                                {payoutMethods?.label}
                              </span>
                            </div>
                          )}
                          defaultValue={selectedPayment}
                          onChange={handlePaymentMethods}
                          options={payoutMethods}
                          placeholder={
                            <div className="flex items-start">
                              <span className="text-[14px] font-medium ml-2 mt-1">
                                Select your payout method
                              </span>{" "}
                            </div>
                          }
                          styles={paymentStyles}
                          theme={customeTheme}
                          isSearchable={false}
                          blurInputOnSelect={false}
                        />
                      )}
                    </div>
                    <div></div>
                  </div>
                  {payoutMethods.length === 0 && (
                    <div
                      className="underline text-[14px] cursor-pointer text-left ml-4 mt-1"
                      onClick={() => setIsOpen(true)}
                    >
                      Add Payout Method
                    </div>
                  )}

                  <Dialog
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    className="relative z-50"
                  >
                    <div
                      className="fixed inset-0 bg-black/30"
                      aria-hidden="true"
                    />

                    <div className="fixed inset-0 flex w-screen items-center justify-center p-4 ">
                      <DialogPanel className="max-w-lg space-y-4 rounded-[10px] bg-white p-12">
                        <div className="flex justify-end">
                          <button onClick={() => setIsOpen(false)}>
                            <img
                              src={closeIcon}
                              alt="Close"
                              className="w-4 h-4"
                            />
                          </button>
                        </div>
                        <DialogTitle className="font-bold text-[20px]">
                          Payment Methods
                        </DialogTitle>
                        <div
                          className="flex gap-4 py-4 cursor-pointer"
                          onClick={handleMomo}
                        >
                          <div>
                            {" "}
                            <img src={momoimage} alt="" className="h-10 w-10" />
                          </div>
                          <div className="">
                            {" "}
                            <Description className="font-semibold">
                              Mobile Money
                            </Description>
                            <p>Proceed to add a mobile money account.</p>
                          </div>
                          <div>
                            <i className="fa fa-caret-right text-gray-500"></i>
                          </div>
                        </div>
                        <div
                          className="flex gap-4 py-4 cursor-pointer w-full "
                          onClick={handleBank}
                        >
                          <div>
                            {" "}
                            <img src={bank} alt="" className="h-10 w-10" />
                          </div>
                          <div className="">
                            {" "}
                            <Description className="font-semibold">
                              Bank Account
                            </Description>
                            <p>Proceed to add a bank account.</p>
                          </div>
                          <div>
                            <i className="fa fa-caret-right text-gray-500"></i>
                          </div>
                        </div>
                        {/* <div className="py-6 ">
                                <button
                                  onClick={handleMomo}
                                  className=" bg-blue text-white rounded-[10px] px-4 py-2 w-full"
                                >
                                  Continue
                                </button>
                              </div> */}
                      </DialogPanel>
                    </div>
                  </Dialog>
                  {openMomoModal && (
                    <Momo
                      openMomoModal={openMomoModal}
                      setOpenMomoModal={setOpenMomoModal}
                      handleClickMomo={handleClickMomo}
                      handleContinue={handleContinue}
                      supportedPayments={supportedPayments}
                      selectedPayment={selectedPayment}
                      setIsOpen={setIsOpen}
                    />
                  )}
                  {showNameDisplay && (
                    <ValidateMomoModal
                      selectedPayment={selectedPayment}
                      selectedPaymentId={selectedPaymentId}
                      momoOperator={momoOperator}
                      showNameDisplay={showNameDisplay}
                      setShowNameDisplay={setShowNameDisplay}
                      input={input}
                      handleInput={handleInput}
                      fullname={fullname}
                      verifyName={verifyName}
                      handleShowDetails={handleShowDetails}
                      setOpenMomoModal={setOpenMomoModal}
                      isLoading={isLoading}
                      verifyError={verifyError}
                    />
                  )}

                  {showDetails && (
                    <MomoContactDetails
                      input={input}
                      selectedPaymentId={selectedPaymentId}
                      momoOperator={momoOperator}
                      fullname={fullname}
                      showDetails={showDetails}
                      setShowDetails={setShowDetails}
                      setShowNameDisplay={setShowNameDisplay}
                    />
                  )}

                  {openBankModal && (
                    <Bank
                      openBankModal={openBankModal}
                      setOpenBankModal={setOpenBankModal}
                      accountNameInput={accountNameInput}
                      accountNumberInput={accountNumberInput}
                      selectedBankId={selectedBankId}
                      selectedBankName={selectBankName}
                      supportedPayouts={supportedPayouts}
                      handlePayout={handlePayout}
                      verifyBankName={verifyBankName}
                      handleDisplayBankDets={handleDisplayBankDets}
                      setAccountNumberInput={setAccountNumberInput}
                      setIsOpen={setIsOpen}
                      verifyError={verifyError}
                      isLoading={isLoading}
                      setVerifyError={setVerifyError}
                    />
                  )}

                  {showBankDetails && (
                    <BankContactDetails
                      showBankDetails={showBankDetails}
                      setShowBankDetails={setShowBankDetails}
                      accountNameInput={accountNameInput}
                      accountNumberInput={accountNumberInput}
                      selectedBankId={selectedBankId}
                      selectedBankName={selectBankName}
                      setOpenBankModal={setOpenBankModal}
                    />
                  )}
                  <div className="py-2 mx-4  w-full mt-3"></div>
                  <button
                    className={` ${
                      hasErrors
                        ? "bg-gray-200 text-gray-300"
                        : "bg-blue text-white"
                    } border bg-blue flex rounded-[10px] lg:px-4 py-4 mt-0 mx-4 items-center justify-center  w-[320px] lg:w-[510px] mb-4 cursor-pointer`}
                    onClick={submitOrder}
                    disabled={hasErrors}
                  >
                    <div className={` text-[15px] text-white cursor-pointer`}>
                      {amount >= 10000 ? "Continue on Whatsapp" : "Continue"}
                    </div>
                  </button>
                </div>

                {/* <Button
                      disabled={isButtonDisabled}
                      text={"Continue"}
                      onClick={() => {
                        // console.log(order);
                        // if (order.currency === "btc" && order.orderType === "buy") {
                        //   Notify(
                        //     "top-center",
                        //     "Sorry, we are currently out of stock, \nTry selling.",
                        //     "info"
                        //   );
                        //   setOrder({ type: "AMOUNT", payload: 0 });
                        // } else {
                        //   handleTransaction();
                        // }
                        submitOrder();
                      }}
                      className={`${
                        limitErrors.includes("upgrade")
                          ? "bg-gray-200 text-gray-300"
                          : isButtonDisabled
                          ? "bg-gray-200 text-gray-300"
                          : "bg-blue text-white"
                      } my-4 h-[50px] px-5 lg:w-[500px] w-[330px] mx-4`}
                    /> */}
              </div>
            ) : null}
          </div>
        </div>
      }
    />
  );
}

export default Sell;
