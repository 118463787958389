import React, { useEffect, useState } from "react";
import Card from "./Card";
import WalletTransactions from "./WalletTransactions";
import RecieveModal from "./RecieveModal";
import SendModal from "./SendModal";
import ConfirmModal from "./ConfirmModal";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import {
  AmountContext,
  AddressContext,
  PriorityContext,
  BtcAmountContext,
  FiatAmountContext,
} from "./SendModalContext";
import SuccessModal from "./SuccessModal";
import WalletStats from "./WalletStats";
import ForgetPinModal from "./ForgetPinModal";
import OtpSuccess from "./OtpSuccess";
import OtpModal from "./OtpModal";
import WalletSetup from "./WalletSetup";

function WalletDetails(walletdata: any) {
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const [transferPriority, setTransferPriority] = useState("");
  const [amountError, setAmountError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [transferPriorityError, setTransferPriorityError] = useState("");
  const [isReceiveModalOpen, setIsReceiveModalOpen] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isForgetModalOpen, setIsForgetModalOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [checked, setChecked] = useState(false);
  const [money, setMoney] = useState("");
  const [btcamount, setBtcAmount] = useState("");
  const [fiatamount, setFiatAmount] = useState("");
  const [transactions, setTransactions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [otpPin, setOtpPin] = useState("");
  const [token, setToken] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [transactionPin, setTransactionPin] = useState("");
  const [confirmTransactionPin, setConfirmTransactionPin] = useState("");
  const [isOtpSuccessOpen, setIsOtpSuccessOpen] = useState(false);
  const [otpPinError, setOtpPinError] = useState("");
  //eslint-disable-next-line
  const [isSetup, setIsSetup] = useState(false);

  const currency = walletdata?.walletdata?.walletName.toLowerCase();
  const btcbalance = walletdata?.walletdata?.balance;
  const usdbalance = walletdata?.walletdata?.balanceUsd;

  const hasErrors = !!amountError || !!addressError || !!transferPriorityError;
  const isEmpty = amount === "" || address === "" || transferPriority === "";

  const minAmount = "0.00001";

  const resetPin = () => {
    if (transactionPin !== confirmTransactionPin) {
      setOtpPinError("Transaction PINs do not match.");
      return;
    }

    const data = {
      token: token,
      transactionPin: transactionPin,
    };

    axiosInstance
      .post(`${Constants.API_BASE_URL}/wallet/reset/pin`, data)
      .then((response) => {
        // console.log("response", response.data);
        if (response.data.status === "success") {
          setIsOtpModalOpen(false);
          setIsOtpSuccessOpen(true);
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const handleTransactionPin = (transactionPin: string) => {
    setTransactionPin(transactionPin);

    // console.log("transactionPin", transactionPin);
  };

  const handleConfirmTransactionPin = (confirmTransactionPin: string) => {
    setConfirmTransactionPin(confirmTransactionPin);
  };
  const handlePin = (otpPin: string) => {
    setOtpError("");
    setOtpPin(otpPin);
    // console.log("otpPin", otpPin);
  };

  const verifyPin = () => {
    axiosInstance
      .post(`${Constants.API_BASE_URL}/wallet/verify/pin/otp`, {
        otpNumber: otpPin,
      })
      .then((res) => {
        // console.log(res.data.token);
        setToken(res.data.token);
        setIsForgetModalOpen(false);
        setTransactionPin("");
        setConfirmTransactionPin("");
        setOtpPinError("");
        setIsOtpModalOpen(true);
      })
      .catch((err) => {
        // console.log(err.response.data.message)
        setOtpError(err.response.data.message);
      });
  };

  const getWalletTransactions = () => {
    axiosInstance
      .get(`${Constants.API_BASE_URL}/wallet/transactions`)
      .then((res) => {
        setTransactions(res.data.data);

        // console.log("transactions", res.data.total);
        setTotalTransactions(res.data.total);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log("Error fetching transactions:", err);
      });
  };

  useEffect(() => {
    if (checked) {
      axiosInstance
        .get(`${Constants.API_BASE_URL}/assets/crypto/amount?amount=${amount}`)
        .then((response) => {
          setBtcAmount(response.data.btc);
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      axiosInstance
        .get(`${Constants.API_BASE_URL}/assets/fiat/amount?amount=${amount}`)
        .then((response) => {
          setFiatAmount(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    getWalletTransactions();
  }, [checked, amount]);

  useEffect(() => {
    if (checked) {
      if (Number(amount) > usdbalance) {
        setAmountError("Insufficient USD Balance");
      } else if (
        (isNaN(Number(amount)) || amount === "" || amount === ".") &&
        amount !== ""
      ) {
        setAmountError(
          "Invalid input. Please enter a valid number or decimal.",
        );
      } else if (Number(btcamount < minAmount) && amount !== "") {
        setAmountError("Minimum amount is 0.00001 BTC");
      } else {
        setAmountError("");
      }
    } else {
      if (Number(amount) > btcbalance) {
        setAmountError("Insufficient BTC Balance");
      } else if (
        (isNaN(Number(amount)) || amount === "" || amount === ".") &&
        amount !== ""
      ) {
        setAmountError(
          "Invalid input. Please enter a valid number or decimal.",
        );
      } else if (Number(amount < minAmount) && amount !== "") {
        setAmountError("Minimum amount is 0.00001 BTC");
      } else {
        setAmountError("");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, btcamount, amount]);

  const getPin = () => {
    axiosInstance
      .post(`${Constants.API_BASE_URL}/wallet/forgot/pin`)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleForgotPin = () => {
    setOtpPin("");
    setIsForgetModalOpen(true);
    setIsConfirmModalOpen(false);
    getPin();
  };
  const handleChange = (nextChecked: boolean) => {
    setMoney(nextChecked ? "USD" : "BTC");
    setChecked(nextChecked);
    setAmount("");

    setAmountError("");
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setAmount(inputValue);

    // if (isNaN(Number(inputValue)) && inputValue !== "" && inputValue !== ".") {
    //   setAmountError("Invalid input. Please enter a valid number or decimal.");
    // } else {
    //   setAmountError("");

    // if (inputValue.trim() !== "" && address.trim() !== "" && transferPriority.trim() !== "") {
    //   // Here you can add logic to handle when all fields are not empty
    // }
    // }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    setAmount(amount);
    setAddress(address);
    setTransferPriority(transferPriority);
    handleOpenConfirmModal();
    setIsSendModalOpen(false);
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;

    setAddress(inputValue);
    // validateAddress(inputValue);
    setAddressError("");
  };

  const handleTransferPriorityChange = (priority: string) => {
    setTransferPriority(priority);
    setTransferPriorityError("");
  };

  const handleOpenRecieveModal = () => {
    setIsReceiveModalOpen(true);
  };

  const handleReceiveModal = () => {
    setIsReceiveModalOpen(true);
    setIsSendModalOpen(false);
  };
  const goBack = () => {
    setIsSendModalOpen(true);
    setIsConfirmModalOpen(false);
  };
  const handleOpenSendModal = () => {
    setAmount("");
    setAddress("");
    setTransferPriority("");
    setAddressError("");
    setAmountError("");
    setTransferPriorityError("");
    setIsButtonDisabled(true);
    setIsSendModalOpen(true);
    setIsSuccessModalOpen(false);

    setMoney("USD");
    setChecked(true);
  };

  const handleCloseSendModal = () => {
    setIsSendModalOpen(false);
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleOpenConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };

  const handleOpenSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  //eslint-disable-next-line
  const validateAddress: any = async (address: string) => {
    try {
      const response = await axiosInstance.get(
        `${Constants.API_BASE_URL}/assets/validate/address?address=${address}&currency=${currency}`,
      );

      if (response.data === false) {
        setAddressError("Invalid address. Please enter a valid address.");
      } else {
        setAddressError("");
      }
    } catch (error) {
      // console.error("Error validating address:", error);
    }
  };

  return (
    <AmountContext.Provider value={{ amount }}>
      <AddressContext.Provider value={{ address }}>
        <PriorityContext.Provider value={{ transferPriority }}>
          <FiatAmountContext.Provider value={{ fiatamount }}>
            <BtcAmountContext.Provider value={{ btcamount }}>
              <div className="lg:w-full">
                <div className="mt-4 flex flex-col items-start  ">
                  <div className="text-xl font-bold  ">My Wallet</div>
                  <div className="mt-4 flex flex-col lg:flex-row md:flex-row  w-[370px] md:w-[800px] lg:w-full ">
                    <div className=" w-full ">
                      <Card
                        handleOpenRecieveModal={handleOpenRecieveModal}
                        handleOpenSendModal={handleOpenSendModal}
                        handleCloseConfirmModal={handleCloseConfirmModal}
                        handleCloseSendModal={handleCloseSendModal}
                        walletdata={walletdata}
                      />
                    </div>

                    <div className="w-full">
                      {" "}
                      <WalletStats walletdata={walletdata} />
                    </div>
                  </div>
                </div>
                <WalletTransactions
                  transactions={transactions}
                  totalTransactions={totalTransactions}
                  isLoading={isLoading}
                  handleOpenRecieveModal={handleOpenRecieveModal}
                />

                {isReceiveModalOpen && (
                  <RecieveModal
                    walletdata={walletdata}
                    isOpen={isReceiveModalOpen}
                    onClose={() => setIsReceiveModalOpen(false)}
                  />
                )}

                {isSendModalOpen && (
                  <SendModal
                    walletdata={walletdata}
                    isOpen={isSendModalOpen}
                    onClose={() => setIsSendModalOpen(false)}
                    handleOpenRecieveModal={handleOpenRecieveModal}
                    handleCloseSendModal={handleCloseSendModal}
                    handleOpenConfirmModal={handleOpenConfirmModal}
                    handleAmountChange={handleAmountChange}
                    handleAddressChange={handleAddressChange}
                    handleTransferPriorityChange={handleTransferPriorityChange}
                    handleSubmit={handleSubmit}
                    amount={amount}
                    address={address}
                    transferPriority={transferPriority}
                    amountError={amountError}
                    addressError={addressError}
                    transferPriorityError={transferPriorityError}
                    isButtonDisabled={isButtonDisabled}
                    money={money}
                    checked={checked}
                    handleChange={handleChange}
                    btcamount={btcamount}
                    fiatamount={fiatamount}
                    hasErrors={hasErrors}
                    isEmpty={isEmpty}
                    handleReceiveModal={handleReceiveModal}
                  />
                )}

                {isConfirmModalOpen && (
                  <ConfirmModal
                    isOpen={isConfirmModalOpen}
                    onClose={() => setIsConfirmModalOpen(false)}
                    handleOpenSuccessModal={handleOpenSuccessModal}
                    handleCloseConfirmModal={handleCloseConfirmModal}
                    walletdata={walletdata}
                    checked={checked}
                    fiatamount={fiatamount}
                    btcamount={btcamount}
                    handleForgotPin={handleForgotPin}
                    goBack={goBack}
                  />
                )}

                {isSuccessModalOpen && (
                  <SuccessModal
                    isOpen={isSuccessModalOpen}
                    onClose={handleCloseSuccessModal}
                    handleOpenSendModal={handleOpenSendModal}
                  />
                )}

                {isForgetModalOpen && (
                  <ForgetPinModal
                    isOpen={isForgetModalOpen}
                    onClose={() => setIsForgetModalOpen(false)}
                    otpPin={otpPin}
                    handlePin={handlePin}
                    verifyPin={verifyPin}
                    token={token}
                    getPin={getPin}
                    otpError={otpError}
                  />
                )}
                {isOtpModalOpen && token && (
                  <OtpModal
                    token={token}
                    isOpen={isOtpModalOpen} // Use isOtpModalOpen instead of isForgetModalOpen
                    onClose={() => setIsOtpModalOpen(false)} // Close the OTP modal
                    handleTransactionPin={handleTransactionPin}
                    handleConfirmTransactionPin={handleConfirmTransactionPin}
                    resetPin={resetPin}
                    transactionPin={transactionPin}
                    confirmTransactionPin={confirmTransactionPin}
                    otpPinError={otpPinError}
                  />
                )}

                {isOtpSuccessOpen && (
                  <OtpSuccess
                    isOpen={isOtpSuccessOpen} // Use isOtpModalOpen instead of isForgetModalOpen
                    onClose={() => setIsOtpSuccessOpen(false)} // Close the OTP modal
                  />
                )}
                {isSetup && <WalletSetup walletdata={walletdata} />}
              </div>
            </BtcAmountContext.Provider>
          </FiatAmountContext.Provider>
        </PriorityContext.Provider>
      </AddressContext.Provider>
    </AmountContext.Provider>
  );
}

export default WalletDetails;
