import React, { ReactNode, useEffect, useState } from "react";

import style from "./style.module.css";
import Notifications from "./Notifications";
import Profile from "./Profile";
import Constants from "../../../../Utils/Constants";

import axiosInstance from "../../../../setup/axios";
import { orderInfoData } from "../NewOrder/types";
import { logo3 } from "../../../../Common/Assets/Images";
import { FolderOpen } from "solar-icon-set/folders";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import downarrow from "../../../../Common/Assets/Images/downarrow.png";
import uparrow from "../../../../Common/Assets/Images/uparrow.png";

type props = {
  newContent?: ReactNode;
  close: () => void;
};

function RightSideBar2({ newContent, close }: props) {
  const navigate = useNavigate();
  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  const [state, setState] = useState({
    isProfile: false,
    isNotification: false,
    orders: [],
    reverseAnimation: false,
  });

  useEffect(() => {
    if (state.reverseAnimation) {
      setTimeout(() => {
        setState({
          ...state,
          reverseAnimation: false,
          isNotification: false,
          isProfile: false,
        });
      }, 1100);
    }
    // eslint-disable-next-line
  }, [state.reverseAnimation]);

  useEffect(() => {
    if (state.isNotification) {
      setState({ ...state, isProfile: false });
    } else {
      setState({ ...state, isNotification: false });
    }
    // eslint-disable-next-line
  }, [state.isNotification, state.isProfile]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axiosInstance.get(
          `${Constants.API_BASE_URL}/users/orders?limit=5`,
        );
        const data = response.data.data;
        setState((prev) => ({ ...prev, orders: data }));
      } catch (error) {
        // console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div
      className={`${style.rightSide2} fixed right-0 animatefromright bg-white  flex flex-col a-i-s px-10 h-full overflow-y-auto`}
      style={{
        minWidth: "320px",
        minHeight: "100%",
        boxShadow: "-1px 0 6px 1px rgb(0,0,0,.1)",
      }}
    >
      {state.isNotification && (
        <div className="z-50 w-full h-full fixed top-0 right-0 bottom-0 left-0  flex justify-end items-start">
          <section
            onClick={() => {
              setState({ ...state, reverseAnimation: true });
              setTimeout(() => {
                setState({ ...state, isNotification: false });
              }, 1000);
            }}
            className="w-full h-full grow bg-modalBg"
          ></section>
          <Notifications
            isOn={state.reverseAnimation}
            hasNewNotifications={hasNewNotifications}
            setHasNewNotifications={setHasNewNotifications}
          />
        </div>
      )}
      {state.isProfile && (
        <div className="z-10 w-full h-ful fixed top-0 right-0 bottom-0 left-0  flex justify-end items-start">
          <section
            onClick={() => {
              setState({ ...state, reverseAnimation: true });
              setTimeout(() => {
                setState({ ...state, isProfile: false });
              }, 1000);
            }}
            className="w-full h-full grow bg-modalBg"
          ></section>
          <Profile />
          {/*state.reverseAnimation*/}
        </div>
      )}
      <div className="w-full flex justify-end pt-5">
        <img src={logo3} alt="" width={100} />
      </div>
      {/* <section className="flex j-c-end w-full mb-5 min-h-[100px] bg-white top-0 z-0">
                <div className="notify relative mx-2" onClick={()=>setState({...state,  isNotification: true})}>
                    <aside className="w-2 h-2 rounded-full bg-red-500 absolute top-0 right-0"></aside>
                    <img src={notification} alt="" width={25}/>
                </div>
                <img src={profile} alt="" width={25} onClick={()=>setState({...state, isProfile: true})}/>
                
                <Button text={'New Order'} onClick={()=>{dispatch({type: 'TURNON', payload: <NewOrder/>})}} className={'bg-theme text-white ml-2'}/>
            </section> */}

      {newContent ? (
        <>{newContent}</>
      ) : (
        <>
          {/* <h1 className='text-xl font-semibold'>Wallet Total</h1>

                        <div className="w-full flex flex-col j-c-c rounded-3xl bg-blue min-h-[140px] mb-2 mt-2">
                            <h1 className='text-2xl text-white'>$1,445,000</h1>
                            <div className="rounded-lg flex j-c-c bg-theme text-white px-2 py-1 mt-2">
                                <i className="fa fa-chevron-down"></i>
                                <small>2.8%</small>
                            </div>
                        </div>

                        <div className="flex w-full py-2 j-c-b mb-10 min-h-[60px]">
                            <Button textClass={'ml-1'} icon={deposit} onClick={()=>{dispatch({type: 'TURNON', payload: <Deposit/>})}} text={'Deposit'} className={'bg-blue text-white text-sm'} style={{fontWeight: '400', minWidth: '48%'}}/>
                            <Button textClass={'ml-1'} icon={withdraw} onClick={()=>{dispatch({type: 'TURNON', payload: <Withdrawal/>})}} text={'Withdraw'} className={'bg-theme text-white text-sm'} style={{fontWeight: '400', minWidth: '48%'}}/>
                        </div> */}

          <div
            className="flex flex-col a-i-s w-full h-full overflow-auto mt-12 relative "
            style={{}}
          >
            <div className="w-full flex justify-between">
              <h1 className="text-xl font-semibold">Recent Orders</h1>
              {state.orders.length > 5 && (
                <small className="text-theme cursor-pointer">View All</small>
              )}
            </div>

            <div className="flex flex-col a-i-s mt-3 w-full h-full overflow-y-auto ">
              {state.orders.length > 0 &&
                state.orders.map((order: orderInfoData, idx) => {
                  return (
                    <aside
                      key={order.id}
                      style={{}}
                      onClick={() =>
                        order.type === "sell"
                          ? navigate("/sell-details", { state: { order } })
                          : navigate("/buy-details", { state: { order } })
                      }
                      className={`flex j-c-b w-full my-3`}
                    >
                      <div className="flex a-i-c mr-6">
                        <div className={`flex j-c-c rounded-xl`}>
                          <div className="relative w-[35px] h-[35px]">
                            <img
                              src={order.assetEntity.icon}
                              alt=""
                              className="w-8 h-8"
                            />
                            {order.type === "sell" ? (
                              <img
                                src={downarrow}
                                alt=""
                                className=" absolute w-4 h-4 right-0 bottom-0"
                              />
                            ) : (
                              <img
                                src={uparrow}
                                alt=""
                                className=" absolute w-4 h-4 right-0 bottom-0"
                              />
                            )}
                          </div>
                        </div>

                        <div className="flex flex-col a-i-s ml-2">
                          <h1 className="text-[0.9rem]">{order.asset}</h1>
                          <small className="text-gray-400 ">
                            {dayjs(new Date(order.createdAt)).format(
                              "MMM D, YYYY ",
                            )}
                          </small>{" "}
                        </div>
                      </div>
                      <div className="flex flex-col items-end text-right">
                        <h1
                          className={`${
                            order.type === "buy" ? "text-theme" : "text-red-500"
                          } text-sm`}
                        >
                          100
                        </h1>
                      </div>
                    </aside>
                  );
                })}
              {state.orders.length <= 0 && (
                <div className="flex j-c-c w-full h-32 flex-col">
                  <FolderOpen
                    color="#040406"
                    size={40}
                    iconStyle="Bold"
                    className="mb-5"
                  />
                  <p className="text-gray-500">No Orders Yet ...</p>
                </div>
              )}
            </div>

            <div className="absolute bottom-0 right-o left-0 flex justify-center py-6 text-gray-400 text-center w-full">
              <small>Hodlpay © {new Date().getFullYear()}</small>
            </div>
          </div>
        </>
      )}

      <div
        onClick={() => close()}
        className="absolute top-0 left-0 w-[60px] h-[50px] flex j-c-c"
      >
        <i className="fa fa-times text-2xl text-blue font-semibold"></i>
      </div>
    </div>
  );
}

export default RightSideBar2;
