import React from "react";
import style from "./style.module.css";
import mobAds from "../../../../Common/Assets/Images/hodlpayAds.svg";
import playStore from "../../../../Common/Assets/Images/hodlpayAds5.svg";
import appStore from "../../../../Common/Assets/Images/hodlpayAds4.svg";

type sideImageProp = {
  mainImage: string;
  logo: string;
  notice?: string;
};

const SideImage = ({ mainImage, logo, notice }: sideImageProp) => (
  <div
    className={`overflow-hidden overflow-y-auto relative flex flex-col j-c-b items-start justify-between bg-theme h-full px-8 pt-8 min-w-[35%] ${style.sideImg}`}
  >
    <div className="flex flex-col items-start text-left text-white text-lg">
      <img src={logo} alt="" className="mb-14" />
      <p className="font-[500]">
        Download the hodlpay <br />
        mobile app from the <br />
        playstore.
      </p>
      <div className={`flex items-center mt-[1rem] ${style.storeImg}`}>
        <a href="https://play.google.com/store/apps/details?id=com.app.hodlpay&hl=en&gl=US">
          <img src={playStore} alt="" width={160} className={`mr-4`} />
        </a>
        <a href="https://apps.apple.com/gh/app/hodlpay/id6463806798">
          <img src={appStore} alt="" width={160} />
        </a>
      </div>
    </div>
    <div className={`flex w-full h-[600px] j-c-c mt-[3rem] items-end`}>
      {/* <img src={mainImage} alt="" /> */}
      <div
        className={`px-3 pt-3 bg-gray-200 rounded-tr-[2.3rem] rounded-tl-[2.3rem] h-fit`}
      >
        <img
          src={mobAds}
          alt=""
          className={`w-full h-full object-contain rounded-t-[2.3rem]`}
        />
      </div>
    </div>
  </div>
);

export default SideImage;
