import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./phoneNumber.css";

type props = {
  className?: string;
  value: string;
  change: (val: string) => void;
};

function PhoneInputComp({ className, value, change }: props) {
  return (
    <PhoneInput
      country={"gh"}
      value={value}
      onChange={(e) => change(e)}
      inputProps={{ require: true, class: `${className}` }}
    />
  );
}

export default PhoneInputComp;
