import React from "react";
import { bitcoin } from "../../../Common/Assets/Icons";
import uparrow from "../../../Common/Assets/Images/uparrow.png";
import downarrow from "../../../Common/Assets/Images/downarrow.png";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { dotSpinner } from "ldrs";
import arrowBlack from "../../../Common/Assets/Images/arrow-down.png";
import { FolderOpen } from "solar-icon-set/folders";

interface props {
  transactions?: any;
  totalTransactions?: any;
  isLoading?: boolean;
  handleOpenRecieveModal?: any;
}

function WalletTransactions({
  transactions,
  totalTransactions,
  isLoading,
  handleOpenRecieveModal,
}: props) {
  // const [transactions, setTransactions] = useState<any>([]);
  // const [isLoading, setIsLoading] = useState(true);
  // const [totalTransactions, setTotalTransactions] = useState(0);

  const navigate = useNavigate();
  // console.log("transactions", transactions);
  // useEffect(() => {
  //   console.log("inside wallet transactions");
  //   getWalletTransactions();
  // }, []);

  // const getWalletTransactions = () => {
  //   axiosInstance
  //     .get(`${Constants.API_BASE_URL}/wallet/transactions`)
  //     .then((res) => {
  //       setTransactions(res.data.data);
  //       console.log("transactions", res.data.total);
  //       setTotalTransactions(res.data.total);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log("Error fetching transactions:", err);
  //     });
  // };

  const handleViewMore = () => {
    navigate("/more-wallet-transactions");
  };

  dotSpinner.register();

  function getStatusColorClass(status: string) {
    switch (status) {
      case "pending":
        return "inline-block bg-yellow-100 text-yellow-500 px-3 rounded-full text-sm align-middle ";
      case "canceled":
        return "bg-red-200 text-red-500 inline-block align-middle  px-3 rounded-full text-sm";
      case "processing":
        return "bg-cyan-100 inline-block align-middle  text-cyan-500 px-3 rounded-full text-sm";
      case "completed":
        return "bg-green-200 inline-block align-middle  text-green-500 px-3 rounded-full text-sm";
      case "failed":
        return "bg-red-200 inline-block align-middle  text-red-500 px-3 rounded-full text-sm";
      case "expired":
        return "bg-gray-200 inline-block align-middle text-gray-500 px-3 rounded-full text-sm";
      default:
        return "";
    }
  }

  return (
    <div className="flex flex-col items-start mt-10 w-full">
      <div className="font-bold text-xl">Recent Transactions</div>
      <div className="flex justify-between w-full">
        <div>
          <small>Table showing your wallet transactions</small>{" "}
        </div>
        <div className="flex">
          {totalTransactions > 5 && (
            <small
              className="underline cursor-pointer mr-2"
              onClick={handleViewMore}
            >
              View More
            </small>
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="mx-auto w-full my-auto ">
          <div className="pt-36">
            <l-dot-spinner size="80" speed="0.9" color="black"></l-dot-spinner>
          </div>
          <div>
            <div className="mt-14">
              <h2 className="p-8">Loading...</h2>
            </div>
          </div>
        </div>
      ) : (
        <ul className="w-full">
          {transactions.length === 0 ? (
            <div className="w-full py-[5rem] flex flex-col items-center justify-center mt-20   ">
              <div>
                <FolderOpen
                  color="#040406"
                  size={40}
                  iconStyle="Bold"
                  className="mb-5"
                />
              </div>{" "}
              <p className="">You have no transactions in your wallet</p>
              <div
                className="flex items-center justify-center cursor-pointer mt-8 bg-prevBlue w-80 text-white p-2 rounded-lg"
                onClick={handleOpenRecieveModal}
              >
                <div className="text-[15px] font-bold pr-2 ">Receive</div>
                <div>
                  <img src={arrowBlack} alt="" className="h-5 w-5" />
                </div>
              </div>
            </div>
          ) : (
            transactions.map((transaction: any, index: number) => (
              <div key={index} className="mt-6 w-full ">
                <div className="flex justify-between w-full">
                  <div className="flex items-center">
                    <div className="relative">
                      <div className="rounded-full bg-btc flex items-center justify-center w-12 h-12">
                        <img src={bitcoin} alt="" className="w-5 h-5" />
                      </div>
                      {transaction.type === "deposit" ? (
                        <img
                          src={uparrow}
                          alt=""
                          className=" visible lg:invisible absolute w-5 h-5 right-0 bottom-0  z-10"
                        />
                      ) : (
                        <img
                          src={downarrow}
                          alt=""
                          className="absolute visible lg:invisible w-5 h-5 right-0 bottom-0  z-10"
                        />
                      )}
                    </div>

                    <div className="ml-4 flex flex-col items-start">
                      <div>BTC</div>
                      <div className="text-gray-400 text-sm">
                        {dayjs(new Date(transaction.createdAt)).format(
                          "MMM D, YYYY hh:mm a",
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start w-20 ">
                    <div
                      className={
                        transaction.type === "deposit"
                          ? " invisible lg:visible lg:text-theme lg:pr-2 lg:font-bold "
                          : " invisible  lg:visible lg:text-red-500 lg:pr-2 lg:font-bold "
                      }
                    >
                      •
                    </div>
                    <div
                      className={
                        transaction.type === "deposit"
                          ? " invisible lg:visible lg:text-theme"
                          : " invisible  lg:visible lg:text-red-500"
                      }
                    >
                      {transaction.type}
                    </div>
                  </div>
                  <div className="flex flex-col items-end ">
                    <div className=" flex  flex-col lg:w-40  items-end ">
                      {transaction.amount} BTC
                    </div>
                    <div
                      className={` mt-2 ${getStatusColorClass(
                        transaction.status,
                      )}`}
                    >
                      {transaction.status}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </ul>
      )}
    </div>
  );
}

export default WalletTransactions;
