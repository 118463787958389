import React, { ReactNode, useEffect, useState } from "react";
import style from "./style.module.css";
import Notifications from "./Notifications";
import Profile from "./Profile";
import Constants from "../../../../Utils/Constants";
import InfoOrder from "../NewOrder/InfoOrder";
import axiosInstance from "../../../../setup/axios";
import { orderInfoData } from "../NewOrder/types";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal/Modal";
import Select from "react-select";
import userprofile from "../../../../Pages/Main/Components/SideBar/Assets/Icons/UserProfile.png";
import notifs from "../../../../Pages/Main/Components/SideBar/Assets/Icons/Notifications.png";
import { dotSpinner } from "ldrs";
import { useQuery } from "@tanstack/react-query";
import RecentOrders from "./RecentOrders";

type Props = {
  newContent?: ReactNode;
};

function RightSideBar({ newContent }: Props) {
  const navigate = useNavigate();

  const [state, setState] = useState({
    isProfile: false,
    isNotification: false,
    orders: [] as orderInfoData[],
    reverseAnimation: false,
    hasPendingOrders: false,
  });
  //eslint-disable-next-line
  const [openNewOrder, setOpenNewOrder] = useState(false);
  const [showOrderInfo, setShowInfo] = useState({ status: false, data: {} });
  //eslint-disable-next-line
  const [openSelect, setOpenSelect] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  dotSpinner.register();

  const options = [
    { value: "buy", label: "Buy" },
    { value: "sell", label: "Sell" },
  ];

  const handleChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    if (selectedOption.value === "sell") {
      navigate("/sell");
    } else if (selectedOption.value === "buy") {
      navigate("/buy");
    }
  };

  const selectStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "#01847C",
      borderRadius: 10,
    }),
    input: (styles: any) => ({ ...styles, color: "#FFFFFF" }),
    placeholder: (styles: any) => ({ ...styles, color: "#FFFFFF" }),
    singleValue: (styles: any) => ({ ...styles, color: "#FFFFFF" }),
  };

  const customeTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "lightblue",
      primary: "#white",
    },
  });

  const getNotifications = async () => {
    const response = await axiosInstance.get(
      `${Constants.API_BASE_URL}/notifications/count`,
    );
    return response.data;
  };

  const { data: notificationCount } = useQuery({
    queryKey: ["notifications"],
    queryFn: getNotifications,
  });

  useEffect(() => {
    if (state.reverseAnimation) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          reverseAnimation: false,
          isNotification: false,
          isProfile: false,
        }));
      }, 1100);
    }
  }, [state.reverseAnimation]);

  useEffect(() => {
    if (state.isNotification) {
      setState((prevState) => ({ ...prevState, isProfile: false }));
    } else {
      setState((prevState) => ({ ...prevState, isNotification: false }));
    }
  }, [state.isNotification, state.isProfile]);



  return (
    <div
      className={`${style.rightSide} bg-white flex flex-col a-i-s pr-2 h-full overflow-y-auto `}
      style={{ minWidth: "230px", minHeight: "100%" }}
    >
      {state.isNotification && (
        <div
          className="z-10 w-full h-full fixed top-0 right-0 bottom-0 left-0 flex justify-end items-start"
          onClick={() => setHasNewNotifications(false)}
        >
          <section
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                reverseAnimation: true,
                isNotification: false,
              }))
            }
            className="w-full h-full grow bg-modalBg"
          ></section>
          <Notifications
            isOn={state.reverseAnimation}
            hasNewNotifications={hasNewNotifications}
            setHasNewNotifications={setHasNewNotifications}
          />
        </div>
      )}
      {state.isProfile && (
        <div className="z-10 w-full h-full fixed top-0 right-0 bottom-0 left-0 flex justify-end items-start">
          <section
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                reverseAnimation: true,
                isProfile: false,
              }))
            }
            className="w-full h-full grow bg-modalBg"
          ></section>
          <Profile isOn={state.reverseAnimation} />
        </div>
      )}

      <section className="flex j-c-end w-full mb-5 min-h-[100px] bg-white top-0 z-0 ">
        <div
          className="notify cursor-pointer relative mx-2"
          onClick={() =>
            setState((prevState) => ({ ...prevState, isNotification: true }))
          }
        >
          {notificationCount > 0 ? (
            <aside className="w-3 h-3 rounded-full bg-red-500 absolute top-0 right-0 flex items-center justify-center">
              <div className="text-white text-[8px] font-bold">
                {notificationCount}
              </div>
            </aside>
          ) : null}
          <img src={notifs} alt="" width={25} />
        </div>
        <img
          className="cursor-pointer"
          src={userprofile}
          alt=""
          width={25}
          onClick={() =>
            setState((prevState) => ({ ...prevState, isProfile: true }))
          }
        />
        {openSelect && (
          <div className="ml-4">
            <Select
              defaultValue={selectedOption}
              onChange={handleChange}
              options={options}
              placeholder="Buy or Sell"
              styles={selectStyles}
              theme={customeTheme}
              isSearchable={false}
              blurInputOnSelect={false}
            />
          </div>
        )}
      </section>

      {newContent ? (
        newContent
      ) : (
        <div className="flex flex-col a-i-s w-full h-full overflow-auto">
          <RecentOrders />
        </div>
      )}

      {showOrderInfo.status && (
        <Modal
          close={() => setShowInfo({ ...showOrderInfo, status: false })}
          content={
            <InfoOrder
              close={() => setShowInfo({ status: false, data: {} })}
              orderInfo={showOrderInfo.data as orderInfoData}
            />
          }
        />
      )}
    </div>
  );
}

export default RightSideBar;
