export function passwordValidation(password: string) {
  let result = [""];
  const hasNumber = /\d/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const regex = /[!@#$%^&*(),.?":{}|<>]/;
  const hasSymbol = regex.test(password);

  if (password?.length < 8) {
    result.push("8char");
  } else {
    result.filter((item) => item !== "8char");
  }

  if (!hasNumber) {
    result.push("num");
  } else {
    result.filter((item) => item !== "num");
  }

  if (!hasUpperCase) {
    result.push("upper");
  } else {
    result.filter((item) => item !== "upper");
  }

  if (!hasSymbol) {
    result.push("symbol");
  } else {
    result.filter((item) => item !== "symbol");
  }

  if (hasNumber && hasUpperCase && hasSymbol) {
    result = [""];
  }

  return result;
}

export const isPasswordValid = (password: string, confirmPassword: string) => {
  if (
    passwordValidation(password).length === 1 &&
    confirmPassword === password
  ) {
    return true;
  } else {
    return false;
  }
};

export const isEmailValid = (email: string) => {
  if (email.includes("@") && email.includes(".") && email.length > 5) {
    return true;
  }
  return false;
};

export const copyText = (textToCopy: string) => {
  const textarea = document.createElement("textarea");
  textarea.value = textToCopy;
  textarea.setAttribute("readonly", "");
  textarea.style.position = "absolute";
  textarea.style.left = "-9999px";
  document.body.appendChild(textarea);

  textarea.select();
  document.execCommand("copy");

  // Remove the temporary textarea
  document.body.removeChild(textarea);
};
