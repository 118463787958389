import { ReactNode, useReducer } from "react";
import Context from "./Contex";
import { State, authReducer } from "./Reducer";

const UserStore = sessionStorage.getItem("userInfo");
let userInfo = UserStore ? JSON.parse(UserStore) : {};

const TokenStore = localStorage.getItem("tokens");
const tokens = TokenStore ? JSON.parse(TokenStore) : {};

const LoginStore = `${sessionStorage.getItem("isLoggedIn") ?? "false"}`;
const isLoggedIn = JSON.parse(LoginStore);

export const initialState: State = {
  isLoggedIn: isLoggedIn,
  isLoading: false,
  modal: { status: false, content: <></> },
  userInfo: { ...userInfo },
  tokens: { ...(tokens || {}) },
};

type providerProps = {
  children: ReactNode;
};

const AuthStoreProvider = ({ children }: providerProps) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export { AuthStoreProvider };
