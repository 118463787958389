import React, { useEffect, useState } from "react";
import PageWrapper from "../../../Pages/Main/Components/Wrapper/Wrapper";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import uparrow from "../../../Common/Assets/Images/uparrow.png";
import downarrow from "../../../Common/Assets/Images/downarrow.png";
import refresh from "../../../Common/Assets/Images/refresh.png";
import backarrow from "../../../Common/Assets/Images/backarrow.png";
import dayjs from "dayjs";
import { dotSpinner } from "ldrs";
import { bitcoin } from "../../../Common/Assets/Icons";
import { useNavigate } from "react-router-dom";

function MoreWalletTransactions() {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();

  const fetchByPagination = (count: number) => {
    axiosInstance
      .get(
        `${
          Constants.API_BASE_URL
        }/wallet/transactions?limit=${itemsPerPage}&skip=${
          (count - 1) * itemsPerPage
        }`,
      )
      .then((res) => {
        setTransactions(res.data.data);
        setTotalPages(Math.ceil(res.data.total / itemsPerPage));
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log("Error fetching transactions:", err);
      });
  };
  useEffect(() => {
    fetchByPagination(currentPage);
  }, [currentPage]);

  dotSpinner.register();

  function getStatusColorClass(status: string) {
    switch (status) {
      case "pending":
        return "inline-block bg-yellow-100 text-yellow-500 px-3 rounded-full text-sm align-middle ";
      case "canceled":
        return "bg-red-200 text-red-500 inline-block align-middle  px-3 rounded-full text-sm";
      case "processing":
        return "bg-cyan-100 inline-block align-middle  text-cyan-500 px-3 rounded-full text-sm";
      case "completed":
        return "bg-green-200 inline-block align-middle  text-green-500 px-3 rounded-full text-sm";
      case "failed":
        return "bg-red-200 inline-block align-middle  text-red-500 px-3 rounded-full text-sm";
      case "expired":
        return "bg-gray-200 inline-block align-middle text-gray-500 px-3 rounded-full text-sm";
      default:
        return "";
    }
  }

  return (
    <PageWrapper
      content={
        <div className="flex flex-col  items-start  ">
          <div className="flex flex-col items-start w-full ">
            <button
              onClick={() => navigate("/wallet")}
              className="flex -mb-4 cursor-pointer"
            >
              <div>
                <img src={backarrow} alt="" className="h-4 w-6" />
              </div>
              <div className="ml-3 cursor-pointer">Back</div>
            </button>
            <div className="flex justify-between w-full mt-10 lg:mt-10">
              <h1 className="font-semibold text-3xl text-left ">
                Your Wallet Transactions
              </h1>
              <div
                onClick={() => {
                  setIsLoading(true);
                  fetchByPagination(currentPage);
                }}
              >
                <img src={refresh} alt="" className="cursor-pointer h-6 w-6" />
              </div>
            </div>

            <div className="flex  flex-col justify-between w-full  items-start">
              <small className="mt-4">
                Table showing page {currentPage} of your wallet transactions
              </small>{" "}
              <div className="w-full mt-2">
                {isLoading ? (
                  <div className="mx-auto w-full my-auto ">
                    <div className="pt-36">
                      <l-dot-spinner
                        size="80"
                        speed="0.9"
                        color="black"
                      ></l-dot-spinner>
                    </div>
                    <div>
                      <div className="mt-14">
                        <h2 className="p-8">Loading...</h2>
                      </div>
                    </div>
                  </div>
                ) : transactions.length === 0 ? (
                  <div className="w-full py-[5rem] flex flex-col items-center justify-center mt-20   ">
                    <i className="fa fa-folder-open text-4xl mb-5 text-theme"></i>
                    <p className="">You have no transactions in your wallet</p>
                  </div>
                ) : (
                  <ul className="w-full ">
                    {transactions.map((transaction: any, index: number) => (
                      <div key={index} className="mt-5 w-full ">
                        <div className="flex justify-between w-full">
                          <div className="flex items-center">
                            <div className="relative">
                              <div className="rounded-full bg-btc flex items-center justify-center w-12 h-12">
                                <img src={bitcoin} alt="" className="w-5 h-5" />
                              </div>
                              {transaction.type === "deposit" ? (
                                <img
                                  src={uparrow}
                                  alt=""
                                  className="absolute visible lg:invisible absolute w-5 h-5 right-0 bottom-0  z-10"
                                />
                              ) : (
                                <img
                                  src={downarrow}
                                  alt=""
                                  className="absolute visible lg:invisible w-5 h-5 right-0 bottom-0  z-10"
                                />
                              )}
                            </div>

                            <div className="ml-4 flex flex-col items-start">
                              <div>BTC</div>
                              <div className="text-gray-400 text-sm whitespace-nowrap">
                                {dayjs(new Date(transaction.createdAt)).format(
                                  "MMM D, YYYY hh:mm a",
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-start w-20 ">
                            <div
                              className={
                                transaction.type === "deposit"
                                  ? " invisible lg:visible lg:text-theme lg:pr-2 lg:font-bold "
                                  : " invisible  lg:visible lg:text-red-500 lg:pr-2 lg:font-bold "
                              }
                            >
                              •
                            </div>
                            <div
                              className={
                                transaction.type === "deposit"
                                  ? " invisible lg:visible lg:text-theme"
                                  : " invisible  lg:visible lg:text-red-500"
                              }
                            >
                              {transaction.type}
                            </div>
                          </div>
                          <div className="flex flex-col items-end ">
                            <div className=" flex  flex-col lg:w-40  items-end ">
                              {transaction.amount} BTC
                            </div>
                            <div
                              className={` mt-2 ${getStatusColorClass(
                                transaction.status,
                              )}`}
                            >
                              {transaction.status}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-between mb-20 mt-10 w-full ">
            <div>
              <button
                onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
                disabled={currentPage === 1}
                className="flex min-w-[90px] cursor-pointer focus:outline-none disabled:cursor-not-allowed"
              >
                <i className="fa fa-arrow-left mr-2"></i>
                <p>Previous</p>
              </button>
            </div>
            <span className="mx-4 text-medium font-medium">
              Page {currentPage}
            </span>
            <div className="flex">
              <button
                onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                disabled={currentPage === totalPages}
                className="flex cursor-pointer focus:outline-none disabled:cursor-not-allowed"
              >
                <p>Next</p>
                <i className="fa fa-arrow-right ml-2"></i>
              </button>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default MoreWalletTransactions;
