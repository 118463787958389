import React, { useEffect, useState } from "react";
import PageWrapper from "../Components/Wrapper/Wrapper";
import exolix from "../../../Common/Assets/Images/exolix.jpeg";
import changenow from "../../../Common/Assets/Images/changenow.webp";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import ExchangeModal from "../Components/ExchangeModal/ExchangeModal";
import ChangeNowModal from "../Components/ExchangeModal/ChangeNowModal";
import ExchangeDetails from "../Components/ExchangeModal/ExchangeDetailsModal";
import SwapTransactions from "./SwapTransactions";
import LineLoading from "../../../Common/Components/Loading/LineLoading";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import info from "../../../Common/Assets/Images/info.png";
import updown from "../../../Common/Assets/Images/updown.png";
import Switch from "react-switch";
import locked from "../../../Common/Assets/Images/locked.png";
import unlocked from "../../../Common/Assets/Images/unlocked.png";
import { FolderOpen } from "solar-icon-set/folders";

function Swap() {
  const Content = () => {
    const [fromValue, setFromValue] = useState("300");
    const [isFromDropdownOpen, setFromDropdownOpen] = useState(false);
    const [isToDropdownOpen, setToDropdownOpen] = useState(false);
    const [currencies, setCurrencies] = useState([]);
    const [originalCurrencies, setOriginalCurrencies] = useState([]);

    const [isExolixModalOpen, setExolixModalOpen] = useState(false);
    const [isChangeNowModalOpen, setChangeNowModalOpen] = useState(false);
    const [showExchangeDetails, setShowExchangeDetails] = useState(false);
    const [data, setData] = useState<any>({});

    const [xchanger, setXchanger] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [checked, setChecked] = useState(false);
    const [rateType, setRateType] = useState("floating");

    const handleChange = (nextChecked: boolean) => {
      setRateType(nextChecked ? "fixed" : "floating");
      setChecked(nextChecked);
    };

    useEffect(() => {
      // console.log(showExchangeDetails);
      // console.log(data);
    }, [showExchangeDetails, data]);

    const handleOpenExchangeDetails = (responseData: any) => {
      setData(responseData);
      // console.log("cosmos is annoying");
      setShowExchangeDetails(true);
    };

    const openExolixModal = () => {
      setExolixModalOpen(true);

      axiosInstance
        .get(
          `${Constants.API_BASE_URL}/swap/rates?fromCurrency=${fromCurrency.code}&toCurrency=${toCurrency.code}&amount=${fromValue}&fromNetwork=${fromCurrency.network}&toNetwork=${toCurrency.network}&rateType=${rateType}`,
        )
        .then((res) => {
          if (res.data && res.data.length > 0) {
            setXchanger(res.data[0]?.exchanger);
            // console.log("Exchanger (First):", res.data[0].exchanger);
          } else {
            // console.error("Empty or undefined data array");
          }
        })

        .catch((error) => {
          // console.error("Error fetching rates:", error);
          if (error.response) {
            //console.log("API response:", error.response.data);
          }
        });
    };

    const openChangeNowModal = () => {
      setChangeNowModalOpen(true);

      axiosInstance
        .get(
          `${Constants.API_BASE_URL}/swap/rates?fromCurrency=${fromCurrency.code}&toCurrency=${toCurrency.code}&amount=${fromValue}&fromNetwork=${fromCurrency.network}&toNetwork=${toCurrency.network}&rateType=${rateType}`,
        )
        .then((res) => {
          if (res.data && res.data.length > 1) {
            setXchanger(res.data[1]?.exchanger);
            // console.log("Exchanger (2):", res.data[1]?.exchanger);
          } else if (res.data && res.data.length === 1) {
            setXchanger(res.data[0]?.exchanger);
          } else {
            // console.error("Empty or undefined data array");
          }
        })
        .catch((error) => {
          // console.error("Error fetching rates:", error);
          if (error.response) {
            // console.log("API response:", error.response.data);
          }
        });
    };

    const closeModal = () => {
      // console.log("hi");
      setShowExchangeDetails(false);
    };

    const closeChangeNowModal = () => {
      setChangeNowModalOpen(false);
    };

    const closeExolixModal = () => {
      setExolixModalOpen(false);
    };
    const switchCurrencies = () => {
      const tempCurrency = fromCurrency;
      setFromCurrency(toCurrency);
      setToCurrency(tempCurrency);
    };

    const [toCurrency, setToCurrency] = useState({
      name: "Bitcoin",
      code: "btc",
      icon: "https://exolix.com/icons/coins/BTC.png",
      network: "btc",
      networkName: "Bitcoin",
    });

    const [fromCurrency, setFromCurrency] = useState({
      name: "TetherUS",
      code: "usdt",
      icon: "https://exolix.com/icons/coins/USDT.png",
      network: "trx",
      networkName: "Tron",
    });

    const [rates, setRates] = useState<any>([
      { minAmount: 10, exchanger: "exolix" },
      // other objects
    ]);
    // console.log("isloadin", isLoading);

    useEffect(() => {
      setIsLoading(true);

      if (fromCurrency && toCurrency && Number(fromValue) > 0) {
        axiosInstance
          .get(
            `${Constants.API_BASE_URL}/swap/rates?fromCurrency=${fromCurrency.code}&toCurrency=${toCurrency.code}&amount=${fromValue}&fromNetwork=${fromCurrency.network}&toNetwork=${toCurrency.network}&rateType=${rateType}`,
          )
          .then((res) => {
            setRates(res.data);
            // console.log("rates", res);
            setIsLoading(false); // Set isLoading to false when data fetching is finished
            // console.log("res.data:", res.data);
          })
          .catch((error) => {
            // console.error("Error fetching rates:", error);
            if (error.response) {
              // console.log("API response:", error.response.data);
            }
          });
      }
    }, [
      fromCurrency.code,
      toCurrency.code,
      fromValue,
      fromCurrency,
      toCurrency,
      rateType,
    ]);

    useEffect(() => {
      axiosInstance
        .get(`${Constants.API_BASE_URL}/swap/currencies`)
        .then((res) => {
          setCurrencies(res.data);
          setOriginalCurrencies(res.data);
        })
        .catch((error) => {
          // console.error("Error fetching currencies:", error);
          if (error.response) {
            // console.log("API response:", error.response.data);
          }
          // console.log("oh", error.response.data);
        });
    }, []);

    const handleFromValueChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const value = event.target.value;

      setFromValue(value);

      setErrorMessage("");
      // console.log("fromValue", value);

      if (isNaN(Number(value)) && value !== "" && value !== ".") {
        setErrorMessage(
          "Invalid input. Please enter a valid number or decimal.",
        );
      }
    };

    const closeDropdown = () => {
      setToDropdownOpen(false);
      setFromDropdownOpen(false);
    };

    const toggleFromDropdown = () => {
      // console.log("setCurrencies", currencies);
      setFromDropdownOpen(!isFromDropdownOpen);
      setToDropdownOpen(false);

      const filteredCurrencies = originalCurrencies.filter((currency: any) => {
        return (
          currency.code !== toCurrency.code ||
          currency.networkName !== toCurrency.networkName
        );
      });
      setCurrencies(filteredCurrencies);
    };

    const toggleToDropdown = () => {
      setToDropdownOpen(!isToDropdownOpen);
      setFromDropdownOpen(false);

      const filteredCurrencies = originalCurrencies.filter((currency: any) => {
        return (
          currency.code !== fromCurrency.code ||
          currency.networkName !== fromCurrency.networkName
        );
      });

      setCurrencies(filteredCurrencies);
    };

    const handleCurrencySelect = (
      selectedCurrency: {
        id: string;
        name: string;
        icon: string;
        networkName: string;
        code: string;
        network: string;
      },
      isFromCurrency: boolean,
    ) => {
      // console.log(`Selected currency: ${selectedCurrency.name}`);
      if (isFromCurrency) {
        setFromCurrency(selectedCurrency);
      } else {
        setToCurrency(selectedCurrency);
      }

      setFromDropdownOpen(false);
      setToDropdownOpen(false);
    };

    return (
      <div
        onClick={closeDropdown}
        className="w-full mx-auto min-h-[calc(100%-50px)] flex flex-col items-start "
      >
        <div className="my-2 mb-10 flex w-full justify-between">
          <h1 className="font-semibold text-3xl">Swap</h1>
          <div
            data-tooltip-id="my-tooltip-multiline"
            data-tooltip-html="This service is fully integrated with third-party functionalities, and while Hodlpay takes responsibility<br />for the overall service, any issues or concerns arising from the third-party integrations <br />will be addressed by Hodlpay!"
            data-tooltip-variant="info"
            className="flex items-center"
          >
            <img src={info} className="h-6 w-6 mr-2" alt="Info Icon" />
            <p>Info</p>
          </div>
        </div>
        <Tooltip
          className="text-left max-w-[200px]"
          place="bottom-end"
          id="my-tooltip-multiline"
        />

        <section className="flex flex-col w-full">
          <div className="flex flex-col w-full items-start mb-3">
            <b className={`font-bold`}>From</b>
            <div
              className="flex justify-between w-full my-2 px-6 py-4 mx-auto min-h-[calc(100%-50px)] rounded-[17px] "
              style={{ boxShadow: "0 0 4px rgb(0,0,0,.1)" }}
            >
              <div className="lg:pr-60 md:pr-60 sm:pr-20 pr-10 lg:max-pr-[60px] md:max-pr-[60px] sm:max-pr-[20px]">
                <input
                  type="text"
                  id="amount"
                  value={fromValue}
                  onChange={handleFromValueChange}
                  name="amount"
                />
              </div>

              <div className="flex justify-end  ">
                <div
                  className="flex  justify-end cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleFromDropdown();
                  }}
                >
                  <div
                    className="flex j-c-c rounded-xl "
                    style={{ width: "30px", height: "30px" }}
                  >
                    <img src={fromCurrency.icon} alt={fromCurrency.name} />
                  </div>
                  <div className="flex flex-col items-start mr-4 sm:mr-8 ml-4 sm:ml-8">
                    <b className="uppercase">{fromCurrency.code}</b>
                    <small className="text-gray-400">
                      {fromCurrency.networkName}
                    </small>
                  </div>
                  <i className="fa fa-caret-down text-lg text-gray-400 "></i>
                </div>

                <div className="relative inline-block w-fit">
                  {isFromDropdownOpen && (
                    <div>
                      <ul className="absolute right-[-35px] top-[-45px] md:right-[-40px] md:top-[-45px] lg:right-[-30px] lg:top-[-50px] z-1000 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="text-left px-4 py-2 ">
                          <div className="inline-block whitespace-no-wrap text-base font-medium">
                            Select a currency from
                          </div>
                          <div className="whitespace-no-wrap text-sm text-gray-500">
                            Popular Currencies
                          </div>
                        </div>

                        {currencies.map(
                          (fromCurrency: {
                            id: string;
                            name: string;
                            networkName: string;
                            icon: string;
                            network: string;
                            code: string;
                          }) => (
                            <li
                              key={fromCurrency.id.toString()}
                              onClick={(e) =>
                                handleCurrencySelect(fromCurrency, true)
                              }
                              style={{
                                padding: "8px",
                                cursor: "pointer",
                                transition: "background-color 0.3s ease",
                                borderRadius: "4px",
                              }}
                            >
                              <div className="flex min-w-[100px] max-w-[200px] sm:min-w-[180] md:min-w-[280px] lg:min-w-[200px] xl:min-w-[324px]">
                                <div
                                  className="flex  j-c-c rounded-xl"
                                  style={{ width: "30px", height: "30px" }}
                                >
                                  <img
                                    src={fromCurrency.icon}
                                    alt={fromCurrency.name}
                                  />
                                </div>
                                <div className="flex flex-col items-start mr-30 ml-4 ">
                                  <b className="uppercase">
                                    {fromCurrency.code}
                                  </b>

                                  <div className="flex ">
                                    <small className="text-gray-400 pr-2">
                                      {fromCurrency.name}
                                    </small>

                                    {fromCurrency.code === "usdt" && (
                                      <div className="flex items-start bg-green-500 px-2 rounded ">
                                        <small className="text-white">
                                          {fromCurrency.networkName ===
                                          "Ethereum"
                                            ? "Ethereum"
                                            : "Tron"}
                                        </small>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          ),
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <small>You will send</small>
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
          </div>
          <div className="flex w-full justify-between">
            <div className="w-full">
              <div className="border border-gray-100 w-full "></div>
            </div>
            <div className="bg-gray-100 rounded-[10px] p-2 border border-gray-100 ml-auto  ">
              <img
                onClick={switchCurrencies}
                src={updown}
                alt="arrow"
                className="h-6 w-6 cursor-pointer"
              />
            </div>
          </div>

          <div className="flex flex-col w-full items-start mb-8">
            <b className={`font-bold`}>To</b>
            <div
              className="flex  w-full my-2 px-6 py-4 rounded-[17px]"
              style={{ boxShadow: "0 0 4px rgb(0,0,0,.1)" }}
            >
              <div className="flex justify-between w-full">
                <div className="">
                  {rates && rates.length > 0 ? (
                    <>
                      <span>
                        {rates.reduce(
                          (max: any, rate: any) =>
                            rate.toAmount > max ? rate.toAmount : max,
                          rates[0].toAmount,
                        )}
                      </span>
                    </>
                  ) : (
                    <span>No value</span>
                  )}
                </div>

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleToDropdown();
                  }}
                  className="flex cursor-pointer "
                >
                  <div
                    className="flex  j-c-c rounded-xl"
                    style={{ width: "30px", height: "30px" }}
                  >
                    <img src={toCurrency.icon} alt={toCurrency.name} />
                  </div>
                  <div className="flex flex-col items-start mr-4 sm:mr-8 ml-4 sm:ml-8">
                    <b className="uppercase">{toCurrency.code}</b>
                    <small className="text-gray-400">
                      {toCurrency.networkName}
                    </small>
                  </div>
                  <i className="fa fa-caret-down text-lg text-gray-400 "></i>
                </div>
              </div>
              <div className="relative inline-block w-fit  ">
                {isToDropdownOpen && (
                  <div onClick={(e) => e.stopPropagation()}>
                    <ul className="absolute right-[-35px] top-[-45px] md:right-[-40px] md:top-[-45px] lg:right-[-30px] lg:top-[-50px] z-1000 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="text-left px-4 py-2 ">
                        <div className="inline-block whitespace-no-wrap text-base font-medium">
                          Select a currency from
                        </div>
                        <div className="whitespace-no-wrap text-sm text-gray-500">
                          Popular Currencies
                        </div>
                      </div>

                      {currencies.map(
                        (currency: {
                          id: string;
                          name: string;
                          networkName: string;
                          icon: string;
                          code: string;
                          network: string;
                        }) => (
                          <li
                            key={currency.id.toString()}
                            onClick={(e) =>
                              handleCurrencySelect(currency, false)
                            }
                            style={{
                              padding: "8px",
                              cursor: "pointer",
                              transition: "background-color 0.3s ease",
                              borderRadius: "4px",
                            }}
                          >
                            <div className="flex min-w-[100px] max-w-[200px] sm:min-w-[180] md:min-w-[280px] lg:min-w-[200px] xl:min-w-[324px]">
                              <div
                                className="flex  j-c-c rounded-xl"
                                style={{ width: "30px", height: "30px" }}
                              >
                                <img src={currency.icon} alt={currency.name} />
                              </div>
                              <div className="flex flex-col items-start mr-30 ml-4 ">
                                <b className="uppercase">{currency.code}</b>

                                <div className="flex ">
                                  <small className="text-gray-400 pr-2">
                                    {currency.name}
                                  </small>

                                  {currency.code === "usdt" && (
                                    <div className="flex items-center bg-green-500 px-2 rounded ">
                                      <small className="text-white">
                                        {currency.networkName === "Ethereum"
                                          ? "Ethereum"
                                          : "Tron"}
                                      </small>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <small>Get up to</small>
          </div>
        </section>

        <div className="flex justify-between items-center w-full">
          <div>
            <b className="font-bold">Select Quotes</b>
          </div>
          <div className="flex items-center">
            <p className={checked ? "mr-2" : ""}>
              <span>{checked ? "Fixed Rate" : " Floating Rate"}</span>
            </p>
            <Switch
              onChange={handleChange}
              checked={checked}
              className="mx-2"
              checkedIcon={
                <img src={locked} alt="locked" className="h-5 w-5 pl-2 pt-2" />
              }
              uncheckedIcon={
                <img
                  src={unlocked}
                  alt="unlocked"
                  className="h-5 w-5 pl-2 pt-2"
                />
              }
              onColor="#01A69F"
              // onHandleColor="#01A69F"
              handleDiameter={20}
              offColor="#E3E6EA"
              offHandleColor="#01A69F"
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={30}
              width={58}
            />
          </div>
        </div>

        <div className="w-full  ">
          {isLoading ? (
            <div className="mt-4">
              <LineLoading isOn={isLoading} />
            </div>
          ) : rates.length === 0 ? (
            <div className="w-full py-[5rem] flex flex-col items-center justify-center">
              {/* <i className="fa fa-folder-open text-4xl mb-5 text-theme"></i> */}
              <FolderOpen
                color="#040406"
                size={40}
                iconStyle="Bold"
                className="mb-5"
              />
              <p>There are currently no rates available, try another amount.</p>
            </div>
          ) : (
            <table className="w-full bg-gray-100 border rounded-[17px] my-3 overflow-hidden ">
              <thead className="w-full">
                <tr className="border-b bg-white  ">
                  <th className=" lg:px-4 py-6 text-left font-bold">
                    Exchanger
                  </th>
                  <th className="py-6 text-left font-bold">You receive</th>
                  <th className="py-6 text-left font-bold ">Action</th>
                </tr>
              </thead>
              <tbody className="w-full ">
                {rates.map(
                  (rate: any, index: any) =>
                    rate?.toAmount && (
                      <tr key={index} className="border-b">
                        <td className="lg:px-4 py-4 text-left font-bold flex">
                          {rate.exchanger === "exolix" && (
                            <img
                              src={exolix}
                              alt="Exolix Logo"
                              className="w-8 h-8 mr-2 rounded-full"
                            />
                          )}
                          {rate.exchanger === "change_now" && (
                            <img
                              src={changenow}
                              alt="Change Now Logo"
                              className="w-8 h-8 mr-2 rounded-full"
                            />
                          )}
                          {rate.exchanger === "exolix" && "Exolix"}
                          {rate.exchanger === "change_now" && "Change Now"}
                        </td>
                        <td className=" text-left">
                          {rate.toAmount ? (
                            <span>
                              {rate.toAmount} {rate.toCurrency}
                            </span>
                          ) : (
                            <span>no value</span>
                          )}
                        </td>
                        <td className="text-left">
                          {rate.exchanger === "exolix" ? (
                            <button
                              onClick={openExolixModal}
                              style={{ backgroundColor: "#01A69F" }}
                              className={`text-white w-full md:w-[124px] h-[33px] px-3 py-30 rounded-[27px] my-2 font-bold`}
                            >
                              Exchange
                            </button>
                          ) : (
                            <button
                              onClick={openChangeNowModal}
                              style={{ backgroundColor: "#01A69F" }}
                              className={`text-white w-full md:w-[124px] h-[33px] px-3 py-30 rounded-[27px] my-2 font-bold`}
                            >
                              Exchange
                            </button>
                          )}
                        </td>
                      </tr>
                    ),
                )}
              </tbody>
            </table>
          )}
        </div>
        {isExolixModalOpen && (
          <ExchangeModal
            fromCurrency={fromCurrency}
            xchanger={xchanger}
            rates={rates}
            fromValue={fromValue}
            toCurrency={toCurrency}
            rateType={rateType}
            isOpen={isExolixModalOpen}
            closeExolixModal={closeExolixModal}
            handleOpenExchangeDetails={handleOpenExchangeDetails}
            closeModal={closeModal}
          />
        )}
        {isChangeNowModalOpen && (
          <ChangeNowModal
            fromCurrency={fromCurrency}
            xchanger={xchanger}
            rates={rates}
            fromValue={fromValue}
            toCurrency={toCurrency}
            rateType={rateType}
            isOpen={isChangeNowModalOpen}
            closeChangeNowModal={closeChangeNowModal}
            handleOpenExchangeDetails={handleOpenExchangeDetails}
            closeModal={closeModal}
          />
        )}
        {showExchangeDetails && (
          <ExchangeDetails
            isOpen={showExchangeDetails}
            closeModal={closeModal}
            responseData={data}
          />
        )}

        <SwapTransactions />

        <section className="walletHistory flex flex-col w-full mx-auto mt-16">
          {/* <div className="w-full flex border-b-[1px]">
                    <div className="flex j-c-c px-8 py-2 bg-theme text-white rounded-tl-[17px] rounded-tr-[17px]">
                        History
                    </div>
                    <div className="flex j-c-c px-8 py-2 bg-blue text-white rounded-tl-[17px] rounded-tr-[17px]">
                        Swap
                    </div>
                </div> */}

          {/* <div className="flex flex-col items-start w-full">
                    <aside className="flex j-c-b w-full py-3 border-b-[1px]">
                        <div className="flex">
                            <img src={historyIcon} alt="" className='mr-4'/>
                            <div className="flex flex-col items-start">
                                <p>BTC - USDT</p>
                                <small className='text-gray-400'>2023-04-01 07:40</small>
                            </div>
                        </div>
                        <div className="flex flex-col items-end">
                            <p>0.5 BTC - 14,225.05 USDT</p>
                            <small className='text-green-400'>Pending</small>
                        </div>
                    </aside>
                </div> */}
        </section>
      </div>
    );
  };
  return <PageWrapper content={<Content />} />;
}

export default Swap;
