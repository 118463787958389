import React, { useContext } from "react";
import { logout } from "../SideBar/Assets/Icons";
import Context from "../../../../store/Contex";
import userprofile from "../../../../Pages/Main/Components/SideBar/Assets/Icons/UserProfile.png";

type props = {
  isOn?: boolean;
};

function Profile({ isOn }: props) {
  const { state, dispatch } = useContext(Context);

  return (
    <section
      className={`absolute rounded-xl overflow-hidden w-[300px] h-52 flex flex-col bg-white mt-20 ${!isOn ? "animatefromrigh" : "reverseright"} mr-1`}
    >
      <div className="w-full h-[70px] bg-blue flex px-5">
        <img src={userprofile} alt="" className="h-8 w-" />
        <div className="flex flex-col items-start ml-5">
          <h1 className="text-theme">Hi, {state.userInfo.user.lastName}</h1>
          <small className="text-white">
            {state.userInfo.emailIdentity.email}
          </small>
        </div>
      </div>
      <div className="w-full grow flex j-c-c">
        <img
          onClick={() => dispatch({ type: "LOGOUT" })}
          src={logout}
          alt=""
          className="mr-2 h-8 w-8"
        />
        <h1
          onClick={() => dispatch({ type: "LOGOUT" })}
          className="text-red-500 font-semibold"
        >
          Logout
        </h1>
      </div>
    </section>
  );
}

export default Profile;
