import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface auth {
  email?: string;
  name?: string;
  token?: string;
  refreshToken?: string;
  isLogin?: boolean;
}

const initialState = {
  email: "",
  name: "dfsd",
  token: "",
  isLogin: false,
};

// Create the counter slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<auth>) => {
      state = {
        ...state,
        email: action.payload.email ?? state.email,
        name: action.payload.name ?? state.name,
      };
    },
    setEmail: (state, action: PayloadAction<auth>) => {
      state.email = action.payload.email ?? state.email;
    },
    setName: (state, action: PayloadAction<auth>) => {
      state.name = action.payload.name ?? state.name;
    },
    setToken: (state, action: PayloadAction<auth>) => {
      state.token = action.payload.token ?? "";
    },
  },
});

export const { setEmail, setName, setToken, setUser } = authSlice.actions;

export default authSlice.reducer;
