export const faqs = [
  {
    question: "What cryptocurrencies can I trade on Hodlpay?",
    ans: `Hodlpay allows you to trade major cryptocurrencies like Bitcoin, 
            Ethereum, Litecoin, Ripple, Binance Coin as well as many up-andcoming altcoins. We are continually expanding our offerings to include 
            the most popular digital assets on the market.
            `,
  },
  {
    question: "Is Hodlpay available worldwide?",
    ans: `No, Hodlpay is currently only available in Ghana but available to users 
            around the world subject to local regulations. However, certain advanced 
            features may be limited depending on your country of residence.
            `,
  },
  {
    question: "How do I deposit/withdraw funds on Hodlpay?",
    ans: [
      ` Sign Into your account on hodlpay.io`,
      ` Click Buy.`,
      ` Select Bitcoin or USDT.`,
      ` Enter the amount of Bitcoin or USDT to buy in USD.`,
      ` Enter your bitcoin or USDT address and choose preferred Fee.`,
      ` Choose your preferred payment method and follow the instructions to complete payment.`,
      ` Click Buy Now After sending payment to complete your buy order.`,
    ],
    note: ` NB: Hodlpay does not allow direct fiat deposits. However, we enable fiat 
                withdrawals to mobile money and bank accounts when you exchange 
                crypto to fiat within Hodlpay. Convert your crypto, cash out to mobile 
                money or bank transfer easily.`,
  },
  {
    question: "*How safe is Hodlpay for storing my crypto?",
    ans: `  Safety of your assets is our top priority. Hodlpay utilizes industry-leading 
                security measures like cold storage and 2-factor authentication to keep 
                your funds protected in our exchange wallets. However, for long-term 
                holdings we recommend using a hardware wallet.`,
  },
  {
    question: "What are Hodlpay's fees?",
    ans: `  Hodlpay currently does not charge any trading or transaction fees. Users 
                only pay blockchain network fees for deposits and withdrawals. While we 
                aim to maintain a fee-free structure for now, we may introduce 
                competitive fees in the future as we expand our services. For now, enjoy 
                fee-free trading! Only blockchain network charges apply.`,
  },
  {
    question: "Does Hodlpay have any limits for trading or withdrawals?",
    ans: [
      `For non-verified accounts on Hodlpay, the maximum buy order is $100, and the maximum sell order is $1000.`,
      `For verified accounts, the limit for buy orders is $1000, while sell orders have no limit.`,
      `We also offer unlimited OTC (over-the-counter) trading via WhatsApp once verified as an OTC trader. This allows large purchases and sales with no caps on order size.`,
    ],
  },
];
