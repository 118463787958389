import React from "react";
import close from "../../../Common/Assets/Images/Close.png";
import OtpInput from "react-otp-input";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;

  token: string;
  handleTransactionPin: (transactionPin: string) => void;
  handleConfirmTransactionPin: (confirmTransactionPin: string) => void;
  resetPin: () => void;
  transactionPin: string;
  confirmTransactionPin: string;
  otpPinError: string;
}

function OtpModal({
  isOpen,
  onClose,

  token,
  handleTransactionPin,
  handleConfirmTransactionPin,
  resetPin,
  transactionPin,
  confirmTransactionPin,
  otpPinError,
}: ModalProps) {
  return (
    <>
      <div
        className={`modal fixed w-full h-full top-0 left-0 flex justify-center overflow-y-auto z-50  p-3 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div className="relative bg-white p-4 rounded-lg shadow-md w-[480px] sm:w-full sm:p-8 min-w-[200px] max-w-[500px] min-h-[250px] max-h-[600px] sm:min-h-[400px] sm:max-h-[6000px] md:min-h-[400px] md:max-h-[600px]">
          <button onClick={onClose} className="absolute top-0 right-0 p-4 z-50">
            <img src={close} alt="Close" className="h-3 w-3" />
          </button>

          <>
            <div className="modal-content text-center">
              <div className="modal-header pb-2 flex items-center justify-center">
                <div className="pr-3">
                  <img
                    src="https://exolix.com/icons/coins/BTC.png"
                    alt=""
                    className="w-10 h-10"
                  />
                </div>
                <h3 className="modal-title text-[24px] font-bold ">
                  Reset Pin{" "}
                </h3>
              </div>

              <div className="flex flex-col items-start mt-10">
                {/* <div className=" font-bold text-[16px]">Transaction PIN</div> */}

                <div className="flex flex-col items-start   ">
                  <div className=" font-bold text-[16px]"> New Pin</div>

                  <OtpInput
                    value={transactionPin}
                    onChange={handleTransactionPin}
                    numInputs={6}
                    renderSeparator={<span className="mx-2"></span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      border: "1px solid #d1d5db",
                      textAlign: "center",
                      fontSize: "1.5rem",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      borderRadius: "10px",
                      boxSizing: "border-box",
                      maxWidth: "100%",
                      width: "100%",
                      height: "60px",
                      minHeight: "60px",
                      maxHeight: "20px",
                    }}
                    inputType="password"
                    containerStyle={{
                      display: "flex",
                      width: "100%",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      height: "100%",
                    }}
                  />
                </div>

                <div className="flex flex-col items-start mt-5 ">
                  <div className=" font-bold text-[16px]"> Confirm New Pin</div>
                  <OtpInput
                    value={confirmTransactionPin}
                    onChange={handleConfirmTransactionPin}
                    numInputs={6}
                    renderSeparator={<span className="mx-2"></span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      border: "1px solid #d1d5db",
                      textAlign: "center",
                      fontSize: "1.5rem",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      borderRadius: "10px",
                      boxSizing: "border-box",
                      maxWidth: "100%",
                      width: "100%",
                      height: "60px",
                      minHeight: "60px",
                      maxHeight: "20px",
                    }}
                    inputType="password"
                    containerStyle={{
                      display: "flex",
                      width: "100%",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </div>
              {otpPinError && (
                <div className="text-red-500 text-red-500 text-left text-[13px] mt-2">
                  {otpPinError}
                </div>
              )}

              {/* <div className="flex justify-between mt-2">
                  <div className="text-[10px] lg:text-[13px] whitespace-nowrap"><span className="text-theme font-medium whitespace-nowrap text-[10px] lg:text-[13px]"></span> </div>
                  <div className="text-[10px] lg:text-[13px] whitespace-nowrap">Didn't get the code? <button onClick={getPin} className="text-theme font-medium cursor-pointer">Resend</button></div>
                </div> */}
              <div className="mt-12 mb-4">
                <button
                  onClick={resetPin}
                  className={`bg-prevBlue text-white rounded-[10px] px-4 py-4 w-full `}
                >
                  Reset
                </button>

                <button
                  onClick={onClose}
                  className="bg-gray-200 text-gray-500 rounded-[10px] px-4 py-4 w-full mt-4"
                >
                  Cancel
                </button>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default OtpModal;
