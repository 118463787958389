import React, { useState } from "react";
import closeIcon from "../../../../Common/Assets/Images/Close.png";
import copyIcon from "../../../../Common/Assets/Images/Copy.png";
import exolix from "../../../../Common/Assets/Images/exolix.jpeg";
import changenow from "../../../../Common/Assets/Images/changenow.webp";
import refresh from "../../../../Common/Assets/Images/refresh.png";
import axiosInstance from "../../../../setup/axios";
import Constants from "../../../../Utils/Constants";
import { dotSpinner } from "ldrs";
import Countdown from "react-countdown";
import dayjs from "dayjs";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  responseData: any;
}

const ExchangeDetails: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  responseData,
}) => {
  const modalStyles = isOpen ? "block" : "hidden";

  function getStatusColorClass(status: string) {
    switch (status) {
      case "waiting":
        return "inline-block bg-green-200 text-green-400 px-2 mx-2 rounded-full text-sm align-middle";
      case "confirming":
      case "exchanging":
        return "bg-cyan-200 text-cyan-400 inline-block align-middle px-2  mx-2rounded-full text-sm";
      case "sending":
        return "bg-purple-200 inline-block align-middle text-purple-400 px-2 mx-2 rounded-full text-sm";
      case "finished":
        return "bg-green-200 inline-block align-middle text-green-400 px-2 mx-2 rounded-full text-sm";
      case "failed":
        return "bg-red-200 inline-block align-middle text-red-400 px-2 mx-2 rounded-full text-sm";
      case "refunded":
        return "bg-gray-200 inline-block align-middle text-gray-400 px-2 mx-2  rounded-full text-sm";
      default:
        return ""; // Default style
    }
  }

  const exchanger = responseData.exchanger
    ? { exolix: "Exolix", change_now: "Change Now" }
    : {};
  const exchangerImage =
    responseData?.exchanger === "exolix" ? exolix : changenow;

  const [isCopied, setIsCopied] = useState(false);
  //eslint-disable-next-line
  const [refreshData, setRefreshData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  dotSpinner.register();

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = responseData?.depositAddress;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const fetchDataDetails = (dataId: any) => {
    setIsLoading(true);

    axiosInstance
      .get(`${Constants.API_BASE_URL}/swap/transactions/${dataId}`)
      .then((res) => {
        setRefreshData(res.data);
        // const counter = dayjs(res.data?.createdAt).add(20, 'minute')

        // setTimer(counter.$m)
        // console.log("counter", res.data);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      className={`fixed inset-0 ${modalStyles} overflow-y-auto z-50 flex justify-center items-center bg-black bg-opacity-50`}
    >
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex justify-end">
          <button onClick={closeModal}>
            <img src={closeIcon} alt="Close" className="w-4 h-4" />
          </button>
        </div>
        <div className="mt-4">
          <Countdown
            date={dayjs(new Date(responseData?.createdAt))
              .add(20, "minute")
              .valueOf()}
            onComplete={() => fetchDataDetails(responseData?.id)}
            renderer={({ hours, minutes, seconds, completed }) => {
              if (completed) {
                // Render completed state
                return;
              } else {
                // Render countdown
                return (
                  <div className="flex justify-between w-full mb-4 mt-2 border-b ">
                    <div className="font-bold mb-2">Expires In</div>
                    <div className="countdown-container flex justify-end items-center border border-gray-200 rounded-[10px] mb-2">
                      <div className="countdown-item flex flex-col px-2">
                        <span className=" font-bold text-xl">{minutes}</span>
                        {/* <span className=" text-sm"> Minutes</span> */}
                      </div>
                      <span className="text-xl">:</span>
                      <div className="countdown-item flex flex-col px-2">
                        <span className=" font-bold text-xl">{seconds}</span>
                        {/* <span className=" text-sm">Seconds</span> */}
                      </div>
                    </div>
                  </div>
                );
              }
            }}
          />
          <div className="flex flex-col justify-start items-start w-full">
            <div className="flex items-center justify-between w-full ">
              <div className="font-bold text-lg">
                <div className="flex ">
                  <div className="font-bold text-lg">Send</div>

                  <span className="font-bold text-lg px-2 text-green-700">
                    {responseData?.amount}
                  </span>
                  {responseData?.fromCurrency}
                  <span className={getStatusColorClass(responseData?.status)}>
                    {responseData?.status}
                  </span>
                </div>
              </div>
              <div onClick={() => fetchDataDetails(responseData.id)}>
                <img src={refresh} alt="" className="cursor-pointer h-4 w-4" />
              </div>
            </div>
            <div className="mt-4">To {responseData?.fromCurrency} address</div>
          </div>

          {isLoading ? (
            <div className="flex flex-col justify-center items-center min-h-[450px] min-w-[390px] p-6">
              <div className="mb-4">
                <l-dot-spinner
                  size="80"
                  speed="0.9"
                  color="black"
                ></l-dot-spinner>
              </div>
              <div>
                <h2 className="p-8">Loading...</h2>
              </div>
            </div>
          ) : (
            <>
              <div className="border flex justify-between rounded-[27px] px-2 mt-2">
                <div className="text-sm py-2">
                  {responseData?.depositAddress}
                </div>
                <div
                  onClick={copyToClipboard}
                  className="text-sm border-l border-gray-200 pl-2 py-2 flex items-center overflow-hidden cursor-pointer"
                >
                  <button>
                    <img src={copyIcon} alt="Copy" className="w-4 h-4" />
                  </button>
                  {isCopied ? (
                    <span className="ml-1 text-xs text-green-500">Copied</span>
                  ) : (
                    <span className="ml-1">Copy</span>
                  )}
                </div>
              </div>

              <div className="flex flex-col items-start justify-start">
                <div className="uppercase font-medium py-2">
                  {responseData?.amount}
                  {responseData?.fromCurrency} ≈ {responseData?.amountToReceive}
                  {responseData?.toCurrency}
                </div>

                <div className="text-gray-400">
                  Transaction Id:{" "}
                  <span className="text-green-700">{responseData?.id}</span>
                </div>
                <div className="font-semibold text-base mt-4">
                  Transaction Info
                </div>
              </div>

              <div className="border rounded-[27px] py-8 my-2 ">
                <div className=" px-6 ">
                  <div className="flex flex-col items-start justify-start px-2 mb-2 mt-[-40] ">
                    {" "}
                    <small style={{ fontSize: "12px" }}>You send</small>
                  </div>
                  <div className="bg-gray-100 flex justify-between py-2 rounded-[27px] px-4 ">
                    <div className="flex ">
                      <img
                        src={responseData?.fromCurrencyIconUrl}
                        alt="Bitcoin"
                        className="w-6 h-6 "
                      />
                      <div className="pl-2">{responseData?.amount}</div>
                    </div>

                    <div className="uppercase">
                      {responseData?.fromCurrency}
                    </div>
                  </div>
                  <div
                    style={{ fontSize: "12px" }}
                    className="text-gray-400 flex flex-col items-start justify-start px-2 mb-4 "
                  >
                    {responseData?.depositAddress}
                  </div>
                </div>

                <div className=" border-t px-6 ">
                  <div className="flex flex-col items-start justify-start px-2 mt-4 mb-2">
                    {" "}
                    <small style={{ fontSize: "12px" }}>You get</small>
                  </div>
                  <div className="bg-gray-100 flex justify-between  py-2 rounded-[20px] px-4">
                    <div className="flex">
                      <img
                        src={responseData?.toCurrencyIconUrl}
                        alt="Bitcoin"
                        className="w-6 h-6 "
                      />
                      <div className="pl-2">
                        {responseData?.amountToReceive}
                      </div>
                    </div>

                    <div className="uppercase">{responseData?.toCurrency}</div>
                  </div>
                  <div
                    style={{ fontSize: "12px" }}
                    className="text-gray-400 flex flex-col items-start justify-start px-2"
                  >
                    {responseData?.receiveAddress}
                  </div>
                </div>
              </div>

              <div>
                {exchanger && (
                  <div className="flex flex-col items-start justify-start">
                    <div className="font-medium pb-2">Exchanger</div>
                    <div className="flex items-center">
                      <img
                        src={exchangerImage}
                        alt=""
                        className="w-6 h-6 mr-2 rounded-full"
                      />
                      <div className="font-medium">
                        {responseData.exchanger === "change_now"
                          ? exchanger.change_now
                          : exchanger.exolix}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExchangeDetails;
