// axios.ts
import axios, { AxiosInstance } from "axios";
import Constants from "../Utils/Constants";
import Notify from "../Helpers/Toast";
// import { useNavigate } from 'react-router-dom';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: Constants.API_BASE_URL,
});

// Add a request interceptor to attach the access token
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken =
      JSON.parse(`${localStorage.getItem("tokens")}`)?.token ?? "";
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !window.location.pathname.includes("login")
    ) {
      const newAccessToken = await refreshAccessToken();
      if (newAccessToken) {
        error.config.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axiosInstance(error.config);
      }
    }
    if (error.response.message === "Network Error") {
      Notify("bottom-right", "Slow network detected", "warning");
    }
    return Promise.reject(error);
  },
);

// Function to refresh the access token
export async function refreshAccessToken() {
  const RefreshToken = JSON.parse(
    localStorage.getItem("tokens") ?? "{}",
  )?.refreshToken;
  if (!RefreshToken) {
    window.location.href = "/login";
  }
  try {
    const response = await axiosInstance.post("/auth/refresh/token", {
      refreshToken: RefreshToken,
    });
    localStorage.setItem(
      "tokens",
      JSON.stringify({
        token: response.data.token,
        refreshToken: response.data.refreshToken,
      }),
    );
    window.location.reload();
    return {
      token: response.data.token,
      refreshToken: response.data.refreshToken,
    };
  } catch (error: any) {
    try {
      if (error.response.status === 403) {
        window.location.href = "/login";
      }
    } catch (e) {
      return e;
    }
  }
}

export default axiosInstance;
