import React, { useContext, useEffect, useState } from "react";
import Loading from "../../../../Common/Components/Loading/Loading";
import { Link, useNavigate } from "react-router-dom";
import Constants from "../../../../Utils/Constants";
import Context from "../../../../store/Contex";
import Input from "../../../../Common/Components/Input/Input";
import Button from "../../../../Common/Components/Button/Button";
import axiosInstance from "../../../../setup/axios";
import style from "../../style.module.css";
import Notify from "../../../../Helpers/Toast";
import PhoneInputComp from "./PhoneInput";

const PhoneForm = () => {
  const { state, dispatch } = useContext(Context);
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    details: {
      phone: "",
      otp: "",
    },
    phone: "",
    otpSent: false,
    isRequesting: false,
    loadText: "",
    error: "",
  });

  const GetOtp = () => {
    setFormState({
      ...formState,
      isRequesting: true,
      loadText: "Sending verification code",
    });
    // console.log(formState.details.phone)
    axiosInstance
      .post(Constants.API_BASE_URL + "/auth/user/phone", {
        phone: "+" + formState.details.phone,
      })
      .then((res) => {
        // console.log(res)
        setFormState({
          ...formState,
          isRequesting: false,
          loadText: "",
          otpSent: true,
        });
        Notify(
          "top-center",
          "Phone code sent to this number " + formState.phone,
        );
      })
      .catch((err) => {
        // console.log(err)
        setFormState({
          ...formState,
          isRequesting: false,
          loadText: "",
          error: err?.response?.data?.message ?? "",
        });
        Notify("top-center", "Something went wrong", "error");
      });
  };

  const Submit = (e: React.FormEvent) => {
    setFormState({
      ...formState,
      isRequesting: true,
      loadText: "Sending verification code to your phone",
    });
    e.preventDefault();

    if (formState.details.otp.length > 3) {
      axiosInstance
        .post(`${Constants.API_BASE_URL}/auth/verify/phone/otp`, {
          otpNumber: `${formState.details.otp}`,
        })
        .then((res) => {
          setFormState({ ...formState, loadText: "Phone number is verified." });
          setTimeout(() => {
            setFormState({ ...formState, isRequesting: false, loadText: "" });
          }, 2000);

          let userInfo = state.userInfo;
          userInfo = {
            ...userInfo,
            phoneIdentity: { ...userInfo.phoneIdentity, phoneVerified: true },
          };
          dispatch({ type: "USER_INFO", payload: userInfo });

          navigate("/login", { replace: true });
          Notify("top-right", "Successfully Submitted");
        })
        .catch((err) => {
          // console.log(err)
          setFormState({ ...formState, loadText: "Failed !" });
          setTimeout(() => {
            setFormState({
              ...formState,
              isRequesting: false,
              loadText: "",
              error: err.response.data.message,
            });
          }, 200);
          Notify("top-center", "Sorry, Something went wrong", "error");
        });
    } else {
      Notify(
        "top-center",
        "Your verification code is not up to the required number of characters",
        "error",
      );
      return;
    }
  };

  const ResendOtp = () => {
    setFormState({
      ...formState,
      isRequesting: true,
      loadText: "Resending phone verification code",
    });
    axiosInstance
      .post(Constants.API_BASE_URL + "/auth/user/phone/otp", {
        access_token: "access_token",
      })
      .then((res) => {
        setFormState({ ...formState, loadText: "Verification code resent" });
        setTimeout(() => {
          setFormState({ ...formState, isRequesting: false, loadText: "" });
        }, 2000);
        Notify("top-right", "New token sent to your phone");
      })
      .catch((err) => {
        setFormState({ ...formState, loadText: "Failed !" });
        setTimeout(() => {
          setFormState({
            ...formState,
            isRequesting: false,
            loadText: "",
            error: err.response.data.message,
          });
        }, 200);
        Notify("top-center", "Something went wrong", "error");
      });
  };

  useEffect(() => {
    if (formState.otpSent) {
      setTimeout(() => {
        setFormState({ ...formState, otpSent: false });
      }, 30000);
    }
    // eslint-disable-next-line
  }, [formState.otpSent]);

  useEffect(() => {
    // console.log(formState.details.phone)
    // let phone = `${formState.details.phone[0] !== '+'?'+':''}`+formState.details.phone
    // if(phone.slice(0,5) === '+2330'){
    //     phone = phone.slice(0,4)+phone.slice(5)
    //     console.log(phone)
    // }
    // setFormState({...formState, phone})
    // eslint-disable-next-line
  }, [formState.details.phone]);

  return (
    <form
      onSubmit={(e) => Submit(e)}
      action=""
      className={`w-full h-full overflow-y-auto px-1 ${style.loginForm}`}
    >
      {formState.isRequesting && <Loading loadText={formState.loadText} />}

      <div className="flex flex-col a-i-s mb-16 mt-20">
        <h1 className={`text-3xl md:text-4xl font-bold text-left`}>
          Account Activation
        </h1>
        <h1 className={`text-lg text-blue font-semibold`}>
          Phone Verification
        </h1>
        <p className={`font-normal text-[13px] mt-3`}>
          Verify the Phone Number you provided
        </p>

        {formState.error && (
          <big className="text-red-500 mt-8">{formState.error}</big>
        )}
      </div>
      <div className="flex flex-col a-i-s ">
        <section className="flex flex-col ">
          <div className="flex flex-col items-start w-full mb-6">
            <div className="w-full flex items-end justify-between">
              <h1 className={`text-gray-500`}>Phone</h1>
            </div>
            <div
              className={`flex flex-col items-start w-full ${style.phoneIpt}`}
            >
              <PhoneInputComp
                change={(val: string) =>
                  setFormState({
                    ...formState,
                    details: { ...formState.details, phone: val },
                  })
                }
                value={formState.details.phone}
                className={` bg-white w-[400px] h-[50px] border border-gray-400 pl-3 text-[15px] ${style.input}`}
              />
              <b
                onClick={() => GetOtp()}
                className="text-theme text-lg font-semibold hover:text-[#0051ff] cursor-pointer mt-2"
              >
                Get Phone Code
              </b>
            </div>
          </div>
          <div className="flex flex-col items-start w-full">
            <div className="flex w-full j-c-b">
              <h1 className={`text-gray-500`}>Verification Code</h1>
            </div>
            <div className="flex flex-col w-full">
              <Input
                required
                type="text"
                placeholder="Enter your verification code"
                setValue={(val) =>
                  setFormState({
                    ...formState,
                    details: { ...formState.details, otp: val },
                  })
                }
                value={formState.details.otp}
                className={`bg-white ${style.input}`}
              />
              <div className="w-full flex j-c-b">
                <small>
                  Didn't get the code ?{" "}
                  <b className="text-theme" onClick={ResendOtp}>
                    Resend
                  </b>
                </small>
              </div>
              <div className="w-full flex j-c-b mt-3 text-theme cursor-pointer">
                <Link to={"/login"}>Go Back to Login</Link>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="flex flex-col items-start mt-5">
        <Button
          text={"Verify Phone"}
          onClick={() => {}}
          className={`bg-blue text-white w-[180px] h-[50px] my-5`}
        />
      </div>
    </form>
  );
};

export default PhoneForm;
