import React, { CSSProperties, ReactNode } from "react";

type buttonProp = {
  bg?: string;
  color?: string;
  text: string | ReactNode;
  icon?: string;
  style?: CSSProperties;
  onClick: any;
  className?: {};
  iconSize?: number;
  textClass?: string;
  disabled?: boolean;
};

const Button = ({
  bg,
  color,
  text,
  style,
  onClick,
  className,
  icon,
  iconSize,
  textClass,
  disabled,
}: buttonProp) => {
  const btnStyle = {
    paddingLeft: `${icon ? "1.2rem" : ""}`,
    minWidth: "130px",
    minHeight: "40px",
    borderRadius: "10px",
    background: bg,
    color,
    ...style,
  };
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    return onClick(e);
  };

  return (
    <button
      disabled={disabled ? disabled : false}
      onClick={(e) => handleClick(e)}
      style={btnStyle}
      className={`flex j-c-${icon ? "s" : "c"} items-center ${className}`}
    >
      {icon && (
        <img src={icon} width={iconSize ?? 10} alt="logo" className="mr-2" />
      )}{" "}
      <b className={textClass}>{text}</b>
    </button>
  );
};

export default Button;
