import { ReactNode, Reducer } from "react";
import axios from "axios";
import Constants from "../Utils/Constants";

export type State = {
  isLoggedIn: boolean;
  isLoading: boolean;
  modal: { status: boolean; content: ReactNode };
  userInfo: {};
  tokens: {};
};

export type Action = {
  type:
    | "LOGIN"
    | "LOGOUT"
    | "TURNON"
    | "TURNOFF"
    | "USER_INFO"
    | "TOKENS"
    | "LOAD"
    | "STOP_LOADING"
    | "RESET"
    | "RESET_USER";
  payload: any;
};

export const authReducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "RESET":
      alert("djfkd");
      return { ...state };
    case "LOGIN":
      sessionStorage.setItem("isLoggedIn", "true");
      return { ...state, isLoggedIn: true };

    case "LOGOUT":
      sessionStorage.setItem("isLoggedIn", "false");
      return { ...state, isLoggedIn: false };

    case "LOAD":
      return { ...state, isLoading: true };
    case "STOP_LOADING":
      return { ...state, isLoading: false };
    case "TURNON":
      return {
        ...state,
        modal: { ...state.modal, status: true, content: action.payload },
      };
    case "TURNOFF":
      return {
        ...state,
        modal: { ...state.modal, status: false, content: null },
      };

    case "TOKENS":
      sessionStorage.setItem("tokens", JSON.stringify(action.payload));
      return {
        ...state,
        tokens: {
          ...state.tokens,
          token: action.payload?.token,
          refreshToken: action.payload?.refreshToken,
        },
      };

    case "USER_INFO":
      sessionStorage.setItem("userInfo", JSON.stringify(action.payload));
      // console.log(action.payload)
      return { ...state, userInfo: action.payload };

    case "RESET_USER":
      let refreshToken =
        (state.tokens as { refreshToken?: string })?.refreshToken ?? "";
      let tokens = {
        token: "",
        refreshToken: "",
      };
      axios
        .post(`${Constants.API_BASE_URL}/auth/refresh/token`, {
          refreshToken: refreshToken,
        })
        .then((res) => {
          // console.log(res)

          sessionStorage.setItem(
            "tokens",
            JSON.stringify({
              token: res.data?.token,
              refreshToken: res.data?.refreshToken,
            }),
          );
          tokens = {
            token: res.data?.token,
            refreshToken: res.data?.refreshToken,
          };
          // setTimeout(()=>{
          //     window.location.reload()
          // }, 1000)
        })
        .catch((e) => {
          // console.log(e)
          if (e.message !== "Network Error" || e?.response?.status === 403) {
            window.location.href = "/login";
          }
        });

      return { ...state, tokens };

    default:
      return state;
  }
};
