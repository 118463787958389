import { createContext } from "react";

import { State, Action } from "./Reducer";

type ContextType = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

const Context = createContext<ContextType | any>({});

export default Context;
