import React, { FormEvent, useContext, useEffect, useState } from "react";
import PageWrapper from "../Components/Wrapper/Wrapper";
import Input from "../../../Common/Components/Input/Input";
import Button from "../../../Common/Components/Button/Button";
import Context from "../../../store/Contex";
import { useNavigate } from "react-router-dom";
import Constants from "../../../Utils/Constants";
import Loading from "../../../Common/Components/Loading/Loading";
import axiosInstance from "../../../setup/axios";
import Notify from "../../../Helpers/Toast";
import { useQuery } from "@tanstack/react-query";
import Referral from "../../Main/Dashboard/Components/Referral";

function Profile() {
  const { state, dispatch } = useContext(Context);
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${Constants.API_BASE_URL}/users/me`,
      );
      return response?.data;
    },
  });

  // console.log("state", state);

  const handleSubmit = () => {
    navigate("/kyc");
  };

  const Content = () => {
    const [isRequesting, setRequesting] = useState({
      status: false,
      text: "",
    });
    const [profile, setProfile] = useState({
      firstName: "",
      lastName: "",
      email: "",
      dateOfBirth: "",
      gender: "",
      accountLevel: "",
      // phone: '',
    });

    useEffect(() => {
      const userObj = state.userInfo;
      setProfile({
        ...profile,
        firstName: userObj.user.firstName,
        lastName: userObj.user.lastName,
        email: userObj.emailIdentity.email,
        dateOfBirth: userObj.user.dateOfBirth,
        gender: userObj.user.gender,

        // : userObj.phoneIdentity.phone,
      });
      // console.log("profile", userObj)

      // eslint-disable-next-line
    }, [state.userInfo]);
    const SubmitChanges = (e: FormEvent) => {
      setRequesting({
        ...isRequesting,
        status: true,
        text: "Saving your changes ...",
      });
      e.preventDefault();
      let data = {
        firstName: profile.firstName,
        lastName: profile.lastName,
      };

      axiosInstance
        .patch(`${Constants.API_BASE_URL}/users`, { ...data })
        .then(() => {
          let userInfo = state.userInfo;
          userInfo = { ...userInfo, user: { ...userInfo.user, ...data } };
          sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
          dispatch({ type: "USER_INFO", payload: userInfo });

          setRequesting({ ...isRequesting, status: false, text: "" });
          Notify("top-center", "Profile changes saved successfully");
        })
        .catch(() => {
          Notify("top-center", "Something went wrong", "error");
          setRequesting({ ...isRequesting, status: false, text: "" });
        });
    };

    return (
      <div className="w-full mx-auto min-h-[calc(100%-50px)] flex flex-col items-start mb-10">
        {isRequesting.status && (
          <Loading loadText={isRequesting.text || "Loading..."} />
        )}
        <div className="my-6 mb-10">
          <h1 className="font-semibold text-3xl">Profile Details</h1>
        </div>

        <form
          onSubmit={(e) => SubmitChanges(e)}
          className="w-full flex flex-col items-start"
        >
          <div className="grid md:gap-6 md:grid-cols-2 md:mt-6 w-full">
            <div className="flex flex-col items-start w-full">
              <p>First Name</p>
              <Input
                style={{ minWidth: "100%" }}
                type="text"
                placeholder="Enter first name"
                required
                value={profile.firstName}
                setValue={(val) => setProfile({ ...profile, firstName: val })}
              />
            </div>
            <div className="flex flex-col items-start w-full">
              <p>Last Name</p>
              <Input
                style={{ minWidth: "100%" }}
                type="text"
                placeholder="Enter last name"
                required
                value={profile.lastName}
                setValue={(val) => setProfile({ ...profile, lastName: val })}
              />
            </div>
          </div>

          <div className="grid md:gap-6 md:grid-cols-2 md:mt-6 w-full">
            <div className="flex flex-col items-start w-full">
              <p>Email</p>
              <Input
                style={{ minWidth: "100%" }}
                type="email"
                placeholder="Enter your email address"
                disabled
                value={profile.email}
                setValue={(val) => setProfile({ ...profile, email: val })}
              />
            </div>
            {/* <div className="flex flex-col items-start w-full">
                        <p>Phone Number</p>
                        <Input style={{minWidth: '100%'}} type='tel' placeholder='Enter phone number' value={profile.phone} setValue={(val)=>setProfile({...profile, phone: val})} />
                    </div> */}
            <div className="flex flex-col items-start w-full">
              <p>Date of Birth</p>
              <Input
                style={{ minWidth: "100%" }}
                type="date"
                placeholder="Enter date of birth"
                value={profile.dateOfBirth}
                setValue={(val) => setProfile({ ...profile, dateOfBirth: val })}
              />
            </div>
            <div className="flex flex-col items-start w-full">
              <p>Gender</p>
              <select
                style={{
                  minWidth: "100%",
                  border: "1px solid lightgray",
                  padding: "0 0 0 1rem",
                  margin: "0.7rem 0",
                  color: "rgb(0,0,0,.7)",
                }}
                className="w-full min-h-[45px] md:min-h-[60px] rounded-md md:rounded-[15px]"
                required
                value={profile.gender}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>

          <Button
            text={"Save Changes"}
            onClick={() => {}}
            className={"bg-blue text-white px-8 mt-5 text-md"}
          />
        </form>

        <div className="flex flex-col items-start mt-16 mb-10">
          <aside className="flex flex-col items-start">
            <h1 className="text-2xl font-semibold">
              KYC Verification Status:{" "}
              {data?.accountLevel !== "Full" ? (
                <span className="text-green-500 font-normal">
                  {" "}
                  Not Verified
                </span>
              ) : (
                <span className="text-green-500 font-normal"> Verified</span>
              )}
            </h1>
          </aside>
          {data?.accountLevel !== "Full" && (
            <div>
              <div className="text-2xl font-semibold mt-8">
                Complete your KYC Verification to increase your buy and sell
                limit{" "}
              </div>
              <Button
                text={"Upgrade your account"}
                onClick={handleSubmit}
                className={"bg-blue text-white px-8 mt-2 text-md"}
              />
            </div>
          )}
        </div>
        {/* <div className="font-bold text-[20px] text-left"> Hodlpay  Referral Program. Refer and Earn Points</div>
          <div className="text-[18px] mt-2 text-left"> Refer friends and earn 500 points whenever you place an order.<br/> You can redeem these points for mobile money cash.  <br/> We know a favor demands a favor; that's why your <br/> friends are also awarded 20 points</div> */}
        <div className="mt-4">
          <Referral />
        </div>
      </div>
    );
  };
  return <PageWrapper content={<Content />} />;
}

export default Profile;
