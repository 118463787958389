import React, { useState } from "react";
import closeIcon from "../../../../Common/Assets/Images/Close.png";
import axiosInstance from "../../../../setup/axios";
import Constants from "../../../../Utils/Constants";
import { Dialog, DialogPanel } from "@headlessui/react";
import backarrow from "../../../../Common/Assets/Images/backarrow.png";
import Notify from "../../../../Helpers/Toast";

interface MomoProps {
  showBankDetails: boolean;
  setShowBankDetails: (showBankDetails: boolean) => void;
  accountNameInput: string;
  accountNumberInput: string;
  selectedBankId: string;
  selectedBankName: string;
  setOpenBankModal: (value: boolean) => void;
}

function BankContactDetails({
  setOpenBankModal,
  showBankDetails,
  setShowBankDetails,
  accountNameInput,
  accountNumberInput,
  selectedBankId,
  selectedBankName,
}: MomoProps) {
  // const createAccount = () => {
  //   axiosInstance
  //     .post(`${Constants.API_BASE_URL}/payments/add/payment-method`, {
  //       accountNumber: accountNumberInput,
  //       accountName: accountNameInput,
  //       paymentId: selectedBankId,
  //       makePayment: true,
  //     })
  //     .catch((err) => {
  //       console.log("error", err);
  //     });
  // };
  const [error, setError] = useState("");

  const createAccount = () => {
    axiosInstance
      .post(`${Constants.API_BASE_URL}/payments/add/payment-method`, {
        accountNumber: accountNumberInput,
        accountName: accountNameInput,
        paymentId: selectedBankId,
        makePayment: true,
      })
      .then((response) => {
        Notify("top-center", "Your account has been added successfully 🙂");

        setShowBankDetails(false);
        window.location.reload();
      })
      .catch((err) => {
        // console.log("error", err.response.data.message);
        setError(err.response.data.message);
        Notify("top-center", "Sorry, something went wrong, try again", "error");
      });
  };

  const goBack = (e: any) => {
    setOpenBankModal(true);
    setShowBankDetails(false);
  };

  const canGoBack = window.history.state.idx !== 0;

  return (
    <div>
      <Dialog
        open={showBankDetails}
        onClose={() => setShowBankDetails(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 ">
          <DialogPanel className="max-w-lg space-y-4 rounded-[10px] bg-white p-6 lg:p-12 ">
            <div className="flex justify-between ">
              <div className="lg:w-full w-[350px] flex justify-between ">
                <button
                  onClick={(e) => goBack(e)}
                  className={` flex cursor-pointer  focus:outline-none ${
                    !canGoBack ? "opacity-50" : <> </>
                  }`}
                  disabled={!canGoBack}
                >
                  {" "}
                  <div>
                    <img src={backarrow} alt="" className="h-3 w-4" />
                  </div>
                  <div className="ml-3 cursor-pointer text-[12px]">Back</div>
                </button>
              </div>
              <div>
                <button onClick={() => setShowBankDetails(false)}>
                  <img src={closeIcon} alt="Close" className="w-4 h-4" />
                </button>
              </div>
            </div>
            <div className="items-center justify-center min-h-full mx-auto">
              <div className=" flex flex-col items-start">
                {/* <div className="lg:w-full w-[350px] flex justify-between  mt-6">
            <button
              onClick={(e) => goBack(e)}
              className={` flex cursor-pointer  focus:outline-none ${
                !canGoBack ? "opacity-50" : <> </>
              }`}
              disabled={!canGoBack}
            >
              {" "}
              <div>
                <img src={backarrow} alt="" className="h-3 w-4" />
              </div>
              <div className="ml-3 cursor-pointer text-[12px]">Back</div>
            </button>
          </div> */}
                <div className="text-[18px] font-semibold text-black mt-6">
                  Confirm Details
                </div>
                <div className="text-[14px]  text-black text-left mt-2 text-nowrap">
                  Please confirm that the bank account details below are
                  correct.
                </div>
              </div>

              <div className="border p-4 rounded-[10px] mt-6">
                <div className="flex justify-between w-full border-b p-4">
                  <div className="text-[16px]">Full Name</div>
                  <div className="text-[16px]">{accountNameInput}</div>
                </div>

                <div className="flex justify-between w-full border-b p-4">
                  <div className="text-[16px]">Account Number</div>
                  <div className="text-[16px]">{accountNumberInput}</div>
                </div>

                <div className="flex justify-between w-full p-4">
                  <div className="text-[16px]">Bank Name</div>
                  <div className="text-[16px]">{selectedBankName}</div>
                </div>
              </div>
              {error && (
                <div className=" text-left text-red-500 text-[12px] mt-2">
                  {error}{" "}
                </div>
              )}

              <div
                className="border bg-blue flex rounded-[10px] lg:px-4 py-4 mt-6 items-center justify-center w-full mb-4 cursor-pointer text-white"
                onClick={createAccount}
              >
                Continue
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}

export default BankContactDetails;
