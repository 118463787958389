import React from "react";
import style from "./support.module.css";
import PageWrapper from "../Components/Wrapper/Wrapper";

function Support() {
  const Content = () => (
    <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled body">
      <div
        className={`container-xxl card flex ${style.supportContainer}`}
        id="kt_content_container"
        style={{ background: "white" }}
      >
        <div className={`${style.supportMain} flex w-100 j-c-c`}>
          <section
            className={`${style.textContent} flex v-flex a-i-s text-left`}
          >
            <h1 style={{ fontSize: "3rem", fontWeight: "700" }}>CONTACT US</h1>
            {/* <h3 style={{fontSize: '2rem'}}>YOUR TEXT HERE</h3> */}

            <br />

            <p>
              We are available 24 hours to respond to all your texts. <br />
              Click on either of the media to contact our support team.
            </p>

            <br />
            <br />
            <br />

            <div className={`${style.mediaIcons} flex`}>
              <a
                href="https://wa.me/233246444248"
                target="_blank"
                rel="noreferrer"
                title="whatsapp"
                className={`flex btn mr-1 text-white whatsapp ${style.whatsapp} p-2`}
                style={{
                  background:
                    "linear-gradient(45deg,rgb(16,136,122), rgb(16,136,122,.5))",
                }}
              >
                <i
                  className="fab fa-whatsapp text-white"
                  style={{ fontSize: "1.4rem", margin: "0 0.3rem 0 0" }}
                ></i>
                <b>WhatsApp</b>
              </a>
              <a
                href="https://www.facebook.com/hodlpay?mibextid=LQQJ4d"
                target="_blank"
                rel="noreferrer"
                title="facebook"
                className={`flex btn mr-1 text-white ${style.facebook} p-2`}
                style={{
                  background:
                    "linear-gradient(45deg, rgb(23,116,235), rgb(23,116,235, .5)",
                }}
              >
                <i
                  className="fab fa-facebook text-white"
                  style={{ fontSize: "1.4rem", margin: "0 0.3rem 0 0" }}
                ></i>
                <b>Facebook</b>
              </a>
              <a
                href="https://instagram.com/hodlpay?igshid=OGQ5ZDc2ODk2ZA=="
                target="_blank"
                rel="noreferrer"
                title="instagram"
                className={`flex btn mr-1 text-white ${style.instagram} p-2`}
                style={{
                  background:
                    "linear-gradient(45deg, rgb(30,156,234), rgb(142,45,181, .8), rgb(203,39,112, .8), rgb(237,119,29, .8), rgb(240,207,111, .8))",
                }}
              >
                <i
                  className="fab fa-instagram text-white"
                  style={{ fontSize: "1.4rem", margin: "0 0.3rem 0 0" }}
                ></i>
                <b>Instagram</b>
              </a>
              <a
                href="https://twitter.com/@hodlpay"
                target="_blank"
                rel="noreferrer"
                title="twitter"
                className={`flex btn mr-1 text-white ${style.twitter} p-2`}
                style={{
                  background:
                    "linear-gradient(45deg,rgb(30,156,234), rgb(30,156,234, .5))",
                }}
              >
                <i
                  className="fab fa-twitter text-white"
                  style={{ fontSize: "1.4rem", margin: "0 0.3rem 0 0" }}
                ></i>
                <b>Twitter</b>
              </a>
            </div>
          </section>
          <section className={`${style.supportImage}`}>
            {/* <img src={images[Math.floor(Math.random() * 3)]} alt="" className='img-fit'/> */}
          </section>
        </div>
      </div>
    </div>
  );

  return <PageWrapper content={<Content />} />;
}

export default Support;
