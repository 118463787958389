import React from "react";
import close from "../../../Common/Assets/Images/Close.png";
import checked from "../../../Common/Assets/Images/checked.png";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function OtpSuccess({ isOpen, onClose }: ModalProps) {
  return (
    <div
      className={`modal fixed w-full h-full top-0 left-0 flex justify-center overflow-y-auto z-50 p-6 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="relative bg-white p-4 rounded-lg shadow-md w-[480px] sm:w-full sm:p-8 min-w-[200px] max-w-[500px] min-h-[250px] max-h-[600px] sm:min-h-[200px] sm:max-h-[6000px] md:min-h-[400px] md:max-h-[600px]">
        <button onClick={onClose} className="absolute top-0 right-0 p-4 z-50">
          <img src={close} alt="Close" className="h-3 w-3" />
        </button>
        <div className="flex flex-col items-center">
          <div className="text-[28px] font-bold">Succesful!</div>
          <div className="mt-12 p-6">
            <img src={checked} alt="" className="h-20 w-20 " />
          </div>
          <div className="mt-8">You have successfully reset your pin </div>
        </div>
      </div>
    </div>
  );
}

export default OtpSuccess;
