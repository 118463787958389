import React, { useEffect, useState } from "react";
import PageWrapper from "../Components/Wrapper/Wrapper";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import TwoFactor from "./TwoFactor";
import WalletDetails from "./WalletDetails";
import { dotSpinner } from "ldrs";

function Wallet(token: any) {
  const [walletdata, setWalletData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  dotSpinner.register();

  useEffect(() => {
    setTimeout(() => {
      getUserBitcoinWallet();
    }, 1000);
  }, []);

  const getUserBitcoinWallet = () => {
    axiosInstance
      .get(`${Constants.API_BASE_URL}/wallet/bitcoin`)
      .then((res) => {
        // console.log("bitcoin status", res.data);
        setWalletData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <PageWrapper
      content={
        <>
          {isLoading ? (
            <div className="mx-auto w-full pb-72 ">
              <div className="pt-72 ">
                <l-dot-spinner
                  size="80"
                  speed="0.9"
                  color="black"
                ></l-dot-spinner>
              </div>

              <div>
                <div className="mt-16">
                  <h2 className="mt-12">Loading...</h2>
                </div>
              </div>
            </div>
          ) : walletdata === null ? (
            // <WalletSetup  token={token} walletdata={walletdata}/>
            <TwoFactor />
          ) : (
            <WalletDetails walletdata={walletdata} />
          )}
        </>
      }
    />
  );
}

export default Wallet;
