import React, { useContext, useState } from "react";
import style from "./style.module.css";
import Context from "../../../../store/Contex";

type props = {
  content?: React.ReactNode;
  close?: () => void;
};

function Modal({ content, close }: props) {
  const { state, dispatch } = useContext(Context);
  const [reverse, setReverse] = useState(false);
  const closeModal = () => {
    dispatch({ type: "TURNOFF" });
  };

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex j-c-c bg-modalBg z-50">
      <aside
        className="w-full h-full absolute top-0 left-0 right-0 bottom-0 bg-transparent"
        onClick={() => {
          setReverse(true);
          setTimeout(() => {
            close ? close() : closeModal();
          }, 400);
        }}
      ></aside>
      <div
        className={`${style.content} ${!reverse ? "scaleanimate" : "reverseScale"} bg-white rounded-lg relative overflow-y-auto max-sm:w-full max-sm:px-0 px-10 py-5`}
        style={{ minHeight: "200px", maxHeight: "90%", minWidth: "300px" }}
      >
        <div className="w-full sticky top-0 flex justify-end">
          <i
            className="fa fa-times text-gray-600 text-lg w-[40px] h-[40px] flex j-c-c bg-white"
            onClick={() => {
              setReverse(true);
              setTimeout(() => {
                close ? close() : closeModal();
              }, 400);
            }}
          ></i>
        </div>

        {content ? content : state.modal.content}
      </div>
    </div>
  );
}

export default Modal;
