import React from "react";
import close from "../../Common/Assets/Images/Close.png";
import twofactorimg from "../../Common/Assets/Images/twofactor.png";
import OtpInput from "react-otp-input";
import Loading from "../../Common/Components/Loading/Loading";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  twofactor: string;
  otp: string;
  handleOtp: (otp: string) => void;
  completeLogin: () => void;
  loading: boolean;
  showError: any;
}

function TwoFactorModal({
  isOpen,
  onClose,
  twofactor,
  otp,
  handleOtp,
  completeLogin,
  loading,
  showError,
}: ModalProps) {
  return (
    <div
      className={`modal fixed w-full h-full top-0 left-0 flex  justify-center overflow-y-auto z-50 p-6 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="fixed inset-0 bg-black opacity-50 "></div>
      <div className="relative bg-white p-4 rounded-lg shadow-md w-[480px] sm:w-full  min-w-[200px] max-w-[500px] min-h-[350px] max-h-[700px] sm:min-h-[300px] sm:max-h-[7150px] md:min-h-[500px] md:max-h-[715px]">
        {/* Close button */}
        <button onClick={onClose} className="absolute top-0 right-0 p-4 z-50">
          <img src={close} alt="Close" className="h-3 w-3" />
        </button>
        {/* Modal content */}
        <div className="modal-content text-center">
          {/* Title */}
          <div className="modal-header pb-2 flex  flex-col items-center justify-center mt-4">
            <h3 className="modal-title text-[24px] font-bold ">
              Verification Required
            </h3>
            <div className="text-[16px] mt-3">
              Enter the code shown in your authenticator app
            </div>
          </div>
          {/* Content */}
          <div className="modal-body flex flex-col items-center p-6">
            <img src={twofactorimg} alt="" className="w-[100px] h-[100px]" />

            <div className="px-5 mt-6">
              <OtpInput
                value={otp}
                onChange={handleOtp}
                numInputs={6}
                renderSeparator={<span className="mx-2"></span>}
                renderInput={(props) => (
                  <input {...props} style={{ width: "" }} />
                )}
                inputStyle="w-[44px] lg:w-[60px] h-[45px] lg:h-[60px] border rounded-[10px] text-center  "
                containerStyle="w-[100%] flex h-[100%] "
              />
              {showError && (
                <p className="text-red-500 mt-2 text-left">{showError}</p>
              )}
            </div>

            <div
              className=" bg-blue flex rounded-[10px] px-2 py-4 mt-10 items-start justify-center w-full cursor-pointer"
              onClick={completeLogin}
            >
              <div className="text-[15px] text-white cursor-pointer  ">
                Complete Login
              </div>
            </div>

            {loading && <Loading />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwoFactorModal;
