import React from "react";

import { Dialog, DialogPanel, Field, Input, Label } from "@headlessui/react";

import clsx from "clsx";
import ghs from "../../../../Common/Assets/Images/ghs.png";
import closeIcon from "../../../../Common/Assets/Images/Close.png";
import backarrow from "../../../../Common/Assets/Images/backarrow.png";
import { zoomies } from "ldrs";

interface MomoProps {
  selectedPayment: any;
  selectedPaymentId: any;
  momoOperator: any;
  showNameDisplay: boolean;
  setShowNameDisplay: (value: boolean) => void;
  input: string;
  handleInput: (e: any) => void;
  fullname: string;
  verifyName: () => void;
  handleShowDetails: () => void;
  setOpenMomoModal: (value: boolean) => void;
  isLoading: boolean;
  verifyError: any;
}

function ValidateMomoModal({
  selectedPayment,
  selectedPaymentId,
  momoOperator,
  showNameDisplay,
  setShowNameDisplay,
  input,
  handleInput,
  fullname,
  verifyName,
  handleShowDetails,
  isLoading,
  verifyError,
  setOpenMomoModal,
}: MomoProps) {
  const hasErrors = !input;
  zoomies.register();

  const canGoBack = window.history.state.idx !== 0;

  const goBack = (e: any) => {
    setShowNameDisplay(false);
    setOpenMomoModal(true);
  };

  // const queryName = () => {
  //   verifyName();
  //   setShowValidateForm(true);
  // };

  return (
    <div>
      <Dialog
        open={showNameDisplay}
        onClose={() => setShowNameDisplay(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 ">
          <DialogPanel className="max-w-lg space-y-4 rounded-[10px] bg-white  p-6 lg:p-12">
            <div className="flex justify-between ">
              <div className="lg:w-full w-[350px] flex justify-between ">
                <button
                  onClick={(e) => goBack(e)}
                  className={` flex cursor-pointer  focus:outline-none ${
                    !canGoBack ? "opacity-50" : <> </>
                  }`}
                  disabled={!canGoBack}
                >
                  {" "}
                  <div>
                    <img src={backarrow} alt="" className="h-3 w-4" />
                  </div>
                  <div className="ml-3 cursor-pointer text-[12px]">Back</div>
                </button>
              </div>
              <div>
                <button onClick={() => setShowNameDisplay(false)}>
                  <img src={closeIcon} alt="Close" className="w-4 h-4" />
                </button>
              </div>
            </div>
            <div className="flex flex-col items-start ">
              {/* <div className="lg:w-full w-[350px] flex justify-between  mt-6">
                <button
                  onClick={(e) => goBack(e)}
                  className={` flex cursor-pointer  focus:outline-none ${
                    !canGoBack ? "opacity-50" : <> </>
                  }`}
                  disabled={!canGoBack}
                >
                  {" "}
                  <div>
                    <img src="" alt="" className="h-3 w-4" />
                  </div>
                  <div className="ml-3 cursor-pointer text-[12px]">Back</div>
                </button>
              </div> */}
              <div className="text-[18px] font-semibold text-black mt-6">
                {" "}
                Enter your Mobile Money Number
              </div>
              <div className="text-[14px]  text-black mt-2 text-left text-nowrap">
                Please enter the correct mobile money number to verify your
                registered name
              </div>
              <div className="w-full max-w-md  ">
                <Field>
                  <Label className="text-[16px] font-medium text-black text-left flex items-start mt-4">
                    Phone Number
                  </Label>
                  <div className="relative">
                    <Input
                      value={input}
                      onChange={handleInput}
                      placeholder="Enter Mobile Money Number"
                      className={clsx(
                        "mt-3 block w-full rounded-lg border border-gray-300 py-3 px-10 text-sm/6 text-black",
                        "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
                      )}
                    />

                    <div className="!absolute left-1 top-1 z-10 mt-2 ml-2">
                      <img src={ghs} alt="hi" className="h-6 w-6" />
                    </div>

                    <div
                      className={`!absolute right-1 top-1 z-10 select-none cursor-pointer rounded py-2 px-4 text-center align-middle font-sans text-xs font-bold mt-1 px-2 ${
                        hasErrors
                          ? "bg-gray-200 text-gray-300 cursor-not-allowed"
                          : "bg-blue text-white"
                      }`}
                      onClick={!hasErrors ? verifyName : undefined}
                    >
                      Verify
                    </div>
                    {isLoading && (
                      <div className="items-start">
                        <l-zoomies
                          size="150"
                          stroke="5"
                          bg-opacity="0.1"
                          speed="1.4"
                          color="black"
                        ></l-zoomies>
                      </div>
                    )}
                  </div>
                </Field>
                {verifyError && (
                  <div className="text-red-500 text-[12px] text-left mt-2">
                    {verifyError}
                  </div>
                )}
                {fullname && (
                  <Field>
                    <Label className="text-[16px] font-medium text-black text-left flex items-start mt-4">
                      Full Name
                    </Label>

                    <div
                      className={clsx(
                        "mt-3 block w-full rounded-lg border border-gray-300 py-3 px-3 text-sm/6 text-black text-left",
                        "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
                      )}
                    >
                      {fullname}
                    </div>
                  </Field>
                )}
                {fullname && (
                  <div
                    className="border bg-blue flex rounded-[10px] lg:px-4 py-4 mt-6 items-center justify-center w-full mb-4 cursor-pointer text-white"
                    onClick={handleShowDetails}
                  >
                    Continue
                  </div>
                )}
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}

export default ValidateMomoModal;
