import React, { ReactNode, useEffect, useState } from "react";
import NewOrder from "../NewOrder/NewOrder";
import style from "./style.module.css";
import Notifications from "./Notifications";
import Profile from "./Profile";
import Constants from "../../../../Utils/Constants";
import InfoOrder from "../NewOrder/InfoOrder";
import axiosInstance from "../../../../setup/axios";
import { orderInfoData } from "../NewOrder/types";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "../Modal/Modal";
import Select from "react-select";
import userprofile from "../../../../Pages/Main/Components/SideBar/Assets/Icons/UserProfile.png";
import notifs from "../../../../Pages/Main/Components/SideBar/Assets/Icons/Notifications.png";
import { useQuery } from "@tanstack/react-query";
import Referral from "../../../Main/Dashboard/Components/Referral";

type props = {
  newContent?: ReactNode;
};

//large screen

function RightSideBar({ newContent }: props) {
  const navigate = useNavigate();
  const [state, setState] = useState({
    isProfile: false,
    isNotification: false,
    orders: [],
    reverseAnimation: false,
    hasPendingOrders: false,
  });
  const [openNewOrder, setOpenNewOrder] = useState(false);
  const [showOrderInfo, setShowInfo] = useState({ status: false, data: {} });
  const [openSelect] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  const options = [
    { value: "buy", label: "Buy" },
    { value: "sell", label: "Sell" },
  ];
  const location = useLocation();

  const pages = ["/referral"];
  const referral = pages.includes(location.pathname);

  const handleChange = (selectedOption: any) => {
    // console.log("selectedOption", selectedOption);
    setSelectedOption(selectedOption);

    if (selectedOption.value === "sell") {
      navigate("/sell");
    } else if (selectedOption.value === "buy") {
      navigate("/buy");
    }
  };

  const selectStyles = {
    control: (styles: any, state: { isFocused: any }) => ({
      ...styles, // Spread existing styles
      backgroundColor: "#01847C",

      borderRadius: 10,

      // Set background color
    }),
    input: (styles: any) => ({ ...styles, color: "#FFFFFF" }),
    placeholder: (styles: any) => ({ ...styles, color: "#FFFFFF" }),
    singleValue: (styles: any, { data }: any) => ({
      ...styles,
      color: "#FFFFFF",
    }),
  };

  const customeTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "lightblue",
      primary: "#white",
    },
  });

  useEffect(() => {
    if (state.reverseAnimation) {
      setTimeout(() => {
        setState({
          ...state,
          reverseAnimation: false,
          isNotification: false,
          isProfile: false,
        });
      }, 1100);
    }
    // eslint-disable-next-line
  }, [state.reverseAnimation]);

  useEffect(() => {
    if (state.isNotification) {
      setState({ ...state, isProfile: false });
    } else {
      setState({ ...state, isNotification: false });
    }
    // eslint-disable-next-line
  }, [state.isNotification, state.isProfile]);

  useEffect(() => {
    axiosInstance
      .get(`${Constants.API_BASE_URL}/users/orders`)
      .then((res) => {
        let data = res.data.data;
        setState((prev) => {
          return {
            ...prev,
            orders: data,
          };
        });
      })
      .catch((err) => {
        // console.log(err)
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axiosInstance
      .get(`${Constants.API_BASE_URL}/orders/has/pending`)
      .then((res) => {
        if (res.data) {
          setState({ ...state, hasPendingOrders: true });
        } else {
          setState({ ...state, hasPendingOrders: false });
        }
      });
    // eslint-disable-next-line
  }, []);

  const HasPending = () => {
    return (
      <div className="mt-4 pb-5 text-center flex flex-col">
        <div className="w-7 h-7 mb-2 rounded-full flex j-c-c bg-red-600 text-white">
          <i className="fa fa-info"></i>
        </div>
        You have a pending order <br />
        Please complete pending orders <br />
        <button
          onClick={() => {
            navigate("/orders");
            setOpenNewOrder((prev) => false);
          }}
          className="mt-6 px-4 py-2 bg-theme text-white rounded-lg"
        >
          Complete or Delete
        </button>
      </div>
    );
  };
  const getNotifications = async () => {
    const response = await axiosInstance.get(
      `${Constants.API_BASE_URL}/notifications/count`,
    );
    return response.data;
  };

  const { data: notificationCount } = useQuery({
    queryKey: ["notifications"],
    queryFn: getNotifications,
  });
  
  return (
    <div
      className={`${style.rightSide} bg-white flex flex-col a-i-s pr-2 h-full overflow-y-auto`}
      style={{ minWidth: referral ? "450px" : "230px", minHeight: "100%" }}
    >
      {state.isNotification && (
        <div className="z-10 w-full h-ful fixed top-0 right-0 bottom-0 left-0  flex justify-end items-start">
          <section
            onClick={() => {
              setState({ ...state, reverseAnimation: true });
              setState({ ...state, isNotification: false });
            }}
            className="w-full h-full grow bg-modalBg"
          ></section>
          <Notifications
            isOn={state.reverseAnimation}
            hasNewNotifications={hasNewNotifications}
            setHasNewNotifications={setHasNewNotifications}
          />
        </div>
      )}
      {state.isProfile && (
        <div className="z-10 w-full h-full fixed top-0 right-0 bottom-0 left-0  flex justify-end items-start">
          <section
            onClick={() => {
              setState({ ...state, reverseAnimation: true });
              setState({ ...state, isProfile: false });
            }}
            className="w-full h-full grow bg-modalBg"
          ></section>
          <Profile isOn={state.reverseAnimation} />
        </div>
      )}
      <section className="flex j-c-end w-full mb-5 min-h-[100px] bg-white top-0 z-0">
        <div
          className="notify cursor-pointer relative mx-2"
          onClick={() => setState({ ...state, isNotification: true })}
        >
          {notificationCount > 0 ? (
            <aside className="w-3 h-3 rounded-full bg-red-500 absolute top-0 right-0 flex items-center justify-center">
              <div className="text-white text-[8px] font-bold">
                {notificationCount}
              </div>
            </aside>
          ) : null}{" "}
          <img src={notifs} alt="" width={25} />
        </div>
        <img
          className="cursor-pointer"
          src={userprofile}
          alt=""
          width={25}
          onClick={() => setState({ ...state, isProfile: true })}
        />
        {openSelect && (
          <div className="ml-4">
            {" "}
            <Select
              defaultValue={selectedOption}
              onChange={handleChange}
              options={options}
              placeholder="Buy or Sell"
              styles={selectStyles}
              theme={customeTheme}
              isSearchable={false}
              blurInputOnSelect={false}
            />
          </div>
        )}{" "}
        {/* <Button text={'Buy or Sell'} onClick={()=>setOpenNewOrder(true)} className={'bg-theme text-white ml-2 mr-6'}/> */}
      </section>

      {/* referral  component */}
      {referral ? (
        <div>
          <div className="font-bold text-[20px] text-left">
            {" "}
            Hodlpay Referral Program. Refer and Earn Points
          </div>
          <div className="text-[18px] mt-2 text-left">
            {" "}
            Refer friends and earn 500 points whenever the person you referred
            places an order.
            <br /> You can redeem these points for mobile money cash.{" "}
          </div>
          <div className="mt-4">
            {" "}
            <Referral />{" "}
          </div>
        </div>
      ) : null}

      {newContent ? (
        <>{newContent}</>
      ) : (
        <>
         
        </>
      )}

      {openNewOrder && (
        <Modal
          close={() => setOpenNewOrder(false)}
          content={
            state.hasPendingOrders ? (
              <HasPending />
            ) : (
              <NewOrder
                close={(data: orderInfoData | any) => {
                  setShowInfo({
                    status: true,
                    data,
                  });
                  setOpenNewOrder(false);
                }}
              />
            )
          }
        />
      )}

      {showOrderInfo.status && (
        <Modal
          close={() => setShowInfo({ ...showOrderInfo, status: false })}
          content={
            <InfoOrder
              close={() => setShowInfo({ status: false, data: {} })}
              orderInfo={showOrderInfo.data as orderInfoData}
            />
          }
        />
      )}
    </div>
  );
}

export default RightSideBar;
