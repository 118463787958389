import React, { useEffect, useState } from "react";
import style from "../../style.module.css";
import Button from "../../../../../Common/Components/Button/Button";
import {
  DashImg,
  newLady,
  btcBg,
  googlePlay,
  appleStore,
} from "../../../../../Common/Assets/Images";

import { useNavigate } from "react-router-dom";
// import Context from '../../../../../store/Contex'

function Banner() {
  const screenWidth = window.innerWidth;

  const navigate = useNavigate();
  const bannerBg = [btcBg];
  // const { state } = useContext(Context)
  //eslint-disable-next-line
  const [showAlert, setShowAlert] = useState(false);

  const isSmallScreen = () => {
    return window.innerWidth <= 1034;
  };
  const [smallScreen, setSmallScreen] = useState(isSmallScreen());

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(isSmallScreen());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (showAlert) {
    return (
      <div
        className={`${style.noticeContent} w-full flex j-c-c bg-prevBlue rounded-2xl mb-4`}
      >
        <aside className={`${style.noticeText} flex flex-col items-start mr-5`}>
          <h1 className={`text-white font-light text-3xl text-left`}>
            Complete your <b className="text-themeLight">KYC</b> Verification to{" "}
            <br />
            increase your buy and sell limit
          </h1>
          <h1 className={`text-white font-light text-3xl text-left`}>
            Complete your <b className="text-themeLight">KYC</b> Verification to
            increase your buy and sell limit
          </h1>
          <Button
            text={"Start Here"}
            onClick={() => navigate("/verify-account")}
            className={`bg-white font-normal`}
            style={{ fontWeight: "300", marginTop: "1rem" }}
          />
        </aside>
        <aside className={`${style.noticeImg} h-full`}>
          <img src={DashImg} alt="" className="img-fit" />
        </aside>
      </div>
    );
  }

  return (
    <div
      className={` w-full flex flex-row j-c-c justify-between bg-prevBlue rounded-2xl h-[275px] mb-4  `}
      style={{
        backgroundImage: `url(${bannerBg})`,
        backgroundSize: "cover",
      }}
    >
      <aside
        className={`flex flex-col lg:items-start min-w-[500px] pl-[3rem] relative ${style.textContent}`}
      >
        <div className={`flex items-center mr-2 sm:w-10 sm:h-5`}>
          {smallScreen ? (
            // Smaller image and Google Play icon for small screens
            <>
              <div className="flex flex-col justify-center items-center">
                <h1 className={`text-[30px]	 font-bold text-white mb-4 `}>
                  Download Our <br />
                  Mobile App Today
                </h1>
                <div className="mt-6">
                  <h1 className={`text-[13px] text-white w-[400px]  `}>
                    We have lots of exciting features
                    <br /> coming up soon!
                  </h1>
                </div>
                <div className="mt-4 flex gap-4">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.app.hodlpay&pli=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={googlePlay}
                      alt=""
                      className={`w-full max-w-[100px] h-auto mt-6 `}
                    />
                  </a>
                  <a href="https://apps.apple.com/gh/app/hodlpay/id6463806798">
                    <img
                      src={appleStore}
                      alt=""
                      className="w-full max-w-[100px] h-auto mt-6 "
                    />
                  </a>
                </div>
              </div>
            </>
          ) : (
            // Larger welcome image and Google Play icon for larger screens
            <>
              <div className="flex flex-row justify-between w-full ">
                <div className="relative  left-4 my-4  ">
                  <h1 className={`text-[30px] font-bold text-white text-left `}>
                    Download Our Mobile <br /> App Today
                  </h1>
                  <div className="mt-2">
                    <h1 className={`text-base	text-white text-left w-[400px]  `}>
                      We have lots of exciting features coming up <br />
                      <span className="block">soon!</span>
                    </h1>
                  </div>

                  <div className="mt-4 flex gap-4">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.app.hodlpay&pli=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={googlePlay}
                        alt=""
                        className={` w-[180px] h-[50px] align-left  `}
                      />
                    </a>

                    <a href="https://apps.apple.com/gh/app/hodlpay/id6463806798">
                      <img
                        src={appleStore}
                        alt=""
                        className="w-[180px] h-[50px]"
                      />
                    </a>
                  </div>
                </div>
                <div>
                  <img
                    src={newLady}
                    alt=""
                    className={`w-[250px] h-[250px] absolute -top-[102px] ${screenWidth >= 1400 ? "-right-48" : "-right-44"} ${style.img}`}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </aside>
    </div>
  );
}

export default Banner;
