import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { logo3 } from "../../../../Common/Assets/Images";
import NavItem from "./NavItem";
import {
  HomeDark,
  HomeLight,
  OrderDark,
  OrderLight,
  ProfileDark,
  ProfileLight,
  SupportLight,
  SupportDark,
  SettingsDark,
  SettingsLight,
  FaqLight,
  FaqDark,
  SwapLight,
  SwapDark,
  ReferralDark,
  ReferralLight,
  logout,
} from "./Assets/Icons";
import Context from "../../../../store/Contex";

type prop = {
  close: () => void;
  isOn: boolean;
};

function SideBarMobile({ close }: prop) {
  const location = useLocation();
  const { dispatch } = useContext(Context);

  return (
    <div
      className={`overflow-hidden w-screen h-screen flex bg-[rgb(0,0,0,.4)] fixed z-20`}
    >
      <section
        className="w-full h-screen overflow-y-auto bg-bg relative"
        style={{ minWidth: "250px", maxWidth: "250px", minHeight: "100%" }}
      >
        <div className="w-full flex flex-col justify-between h-full">
          <section className="flex flex-col w-full mt-10">
            <div className="logo">
              <img src={logo3} alt="" />
            </div>

            <div
              onClick={() => close()}
              className="navs flex flex-col mt-20 w-full"
            >
              <NavItem
                isActive={
                  location.pathname === "/dashboard" ||
                  location.pathname.includes("/dashboard/kyc")
                }
                text="Dashboard"
                activeIcon={HomeLight}
                icon={HomeDark}
                link="/dashboard"
              />
              <NavItem
                isActive={location.pathname === "/orders"}
                text="Orders"
                activeIcon={OrderLight}
                icon={OrderDark}
                link="/orders"
              />
              <NavItem
                isActive={location.pathname === "/swap"}
                text="Swap"
                activeIcon={SwapLight}
                icon={SwapDark}
                link="/swap"
              />
              {/*
              <NavItem
                isActive={location.pathname === "/wallet"}
                text="Wallet"
                activeIcon={WalletLight}
                icon={WalletDark}
                link="/wallet"
              /> */}
              {/* <NavItem
                isActive={location.pathname.includes("/news")}
                text="News/Learn"
                activeIcon={NewsActive}
                icon={NewsOff}
                link="/news"
              /> */}
              <NavItem
                isActive={location.pathname === "/profile"}
                text="Profile"
                activeIcon={ProfileLight}
                icon={ProfileDark}
                link="/profile"
              />
              <NavItem
                isActive={location.pathname.includes("/referral")}
                text="Referral"
                activeIcon={ReferralLight}
                icon={ReferralDark}
                link="/referral"
              />
              <NavItem
                isActive={location.pathname.includes("/support")}
                text="Support"
                activeIcon={SupportLight}
                icon={SupportDark}
                link="/support"
              />
              <NavItem
                isActive={
                  location.pathname === "/settings" ||
                  location.pathname.includes("/verify-account")
                }
                text="Settings"
                activeIcon={SettingsLight}
                icon={SettingsDark}
                link="/settings"
              />
              <NavItem
                isActive={location.pathname === "/faq"}
                text="FAQs"
                activeIcon={FaqLight}
                icon={FaqDark}
                link="/faq"
              />
            </div>
          </section>

          <section className="flex min-h-[4rem] w-full pl-[3.3rem]">
            <div
              className="flex group cursor-pointer justify-start "
              onClick={() => {
                dispatch({ type: "LOGOUT" });
                close();
              }}
            >
              <img src={logout} alt="" className="h-8 w-8" />

              <p className="text-red-300 group-hover:text-red-500">Logout</p>
            </div>
          </section>
        </div>
        <i
          onClick={() => close()}
          className="fa fa-times absolute right-4 top-3 cursor-pointer hover:text-theme font-semibold text-xl"
        ></i>
      </section>
      <div
        onClick={() => close()}
        className="w-full h-full grow bg-modalBg"
      ></div>
    </div>
  );
}

export default SideBarMobile;
