import React from "react";

import { Dialog, DialogPanel, Field, Input, Label } from "@headlessui/react";
import closeIcon from "../../../../Common/Assets/Images/Close.png";
import Select from "react-select";
import clsx from "clsx";
import backarrow from "../../../../Common/Assets/Images/backarrow.png";
import { zoomies } from "ldrs";

interface MomoProps {
  openBankModal: boolean;
  setOpenBankModal: (openMomoModal: boolean) => void;
  accountNameInput: string;
  accountNumberInput: string;
  selectedBankId: string;
  selectedBankName: string;
  supportedPayouts: any;
  handlePayout: (e: any) => void;
  verifyBankName: (e: any) => void;
  handleDisplayBankDets: (e: any) => void;
  setAccountNumberInput: (value: string) => void;
  setIsOpen: (value: boolean) => any;
  verifyError: string;
  isLoading: boolean;
  setVerifyError: (value: any) => any;
}
function Bank({
  openBankModal,
  setOpenBankModal,
  accountNameInput,
  selectedBankId,
  selectedBankName,
  supportedPayouts,
  handlePayout,
  verifyBankName,
  handleDisplayBankDets,
  setAccountNumberInput,
  accountNumberInput,
  setIsOpen,
  verifyError,
  isLoading,
  setVerifyError,
}: MomoProps) {
  const canGoBack = window.history.state.idx !== 0;
  const hasErrors = !accountNumberInput; // This will be true when accountNumberInput is empty

  zoomies.register();

  const goBack = (e: any) => {
    setIsOpen(true);
    setOpenBankModal(false);
  };

  const paymentStyles = {
    control: (styles: any) => ({
      ...styles,
      border: "1px solid #E0E0E0",
      height: "53px",
      borderRadius: "10px",
      width: "350px",
      "@media (min-width: 1024px)": {
        width: "450px",
      },
    }),
  };

  const customTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "lightblue",
      primary: "#E5E4E2",
      textAlign: "left",
    },
  });

  return (
    <div>
      <Dialog
        open={openBankModal}
        onClose={() => setOpenBankModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 ">
          <DialogPanel className="max-w-lg space-y-4 rounded-[10px] bg-white p-6 lg:p-12 ">
            <div className="flex justify-between ">
              <div className="lg:w-full w-[350px] flex justify-between ">
                <button
                  onClick={(e) => goBack(e)}
                  className={` flex cursor-pointer  focus:outline-none ${
                    !canGoBack ? "opacity-50" : <> </>
                  }`}
                  disabled={!canGoBack}
                >
                  {" "}
                  <div>
                    <img src={backarrow} alt="" className="h-3 w-4" />
                  </div>
                  <div className="ml-3 cursor-pointer text-[12px]">Back</div>
                </button>
              </div>
              <div>
                <button onClick={() => setOpenBankModal(false)}>
                  <img src={closeIcon} alt="Close" className="w-4 h-4" />
                </button>
              </div>
            </div>
            <div className=" py-2 px-4 overflow-hidden w-full text-left ">
              <li className="">
                Please select your bank operator from the list below
              </li>
            </div>
            <div className="w-full">
              <div className="text-[16px] text-left mt-2">Bank Name</div>
              <div className="flex flex-col items-start w-[350px] lg:w-[540px] md:w-[540px] pt-2 mt-4 lg:mt-2 md:mt-2">
                <Select
                  formatOptionLabel={(payment: any) => (
                    <div className="flex items-center">
                      {payment?.image && (
                        <img
                          src={payment.image}
                          className="w-8 h-8 rounded-full"
                          alt={payment.label}
                        />
                      )}
                      <span className="text-[14px] font-medium ml-2">
                        {payment?.label}
                      </span>
                    </div>
                  )}
                  options={supportedPayouts}
                  placeholder={
                    <div className="flex items-start">
                      <span className="text-[14px] font-medium ml-2 mt-1">
                        Select a Bank
                      </span>
                    </div>
                  }
                  styles={paymentStyles}
                  theme={customTheme}
                  isSearchable={false}
                  blurInputOnSelect={false}
                  onChange={handlePayout}
                />
              </div>
              <div className="w-full max-w-md">
                <Field>
                  <Label className="text-[16px] font-medium text-black text-left flex items-start mt-4">
                    Account Number
                  </Label>
                  <div className="relative">
                    <Input
                      value={accountNumberInput}
                      onChange={(e: any) => {
                        setVerifyError("");
                        setAccountNumberInput(e.target.value);
                      }}
                      placeholder="Enter Bank Account Number"
                      className={clsx(
                        "mt-3 block w-full rounded-lg border border-gray-300 py-3 px-3 mx-1 text-sm/6 text-black",
                        "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
                      )}
                    />
                    <div
                      className={`!absolute right-1 top-1 z-10 select-none cursor-pointer rounded py-2 px-4 text-center align-middle font-sans text-xs font-bold mt-1 px-2 ${
                        hasErrors
                          ? "bg-gray-200 text-gray-300 cursor-not-allowed"
                          : "bg-blue text-white"
                      }`}
                      onClick={verifyBankName}
                    >
                      Verify
                    </div>

                    {isLoading && (
                      <div className="items-start">
                        <l-zoomies
                          size="150"
                          stroke="5"
                          bg-opacity="0.1"
                          speed="1.4"
                          color="black"
                        ></l-zoomies>
                      </div>
                    )}
                  </div>
                </Field>
                {verifyError && (
                  <div className="text-red-500 text-[12px] text-left mt-2 ">
                    {verifyError}
                  </div>
                )}
                {accountNameInput && (
                  <Field>
                    <Label className="text-[16px] font-medium text-black text-left flex items-start mt-4">
                      Account Name
                    </Label>
                    <div
                      className={clsx(
                        "mt-3 block w-full rounded-lg border border-gray-300 py-3 px-3 text-sm/6 text-black text-left",
                        "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
                      )}
                    >
                      {accountNameInput}
                    </div>
                  </Field>
                )}
                {accountNameInput && (
                  <div
                    className="border bg-blue flex rounded-[10px] lg:px-4 py-4 mt-6 items-center justify-center w-full mb-4 cursor-pointer text-white"
                    onClick={handleDisplayBankDets}
                  >
                    Continue
                  </div>
                )}
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}

export default Bank;
