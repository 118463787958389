import React, { CSSProperties } from "react";
import customStyle from "./style.module.css";

type InputProps = {
  type: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  setValue: (value: string) => void;
  className?: {};
  style?: CSSProperties;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // Use camelCase here
};

const Input = ({
  required,
  disabled,
  type,
  value,
  placeholder,
  setValue,
  onChange,
  className,
  style,
}: InputProps) => {
  const styles = {
    border: "1px solid lightgray",
    minWidth: "500px",
    padding: "0 0 0 1rem",
    // boxShadow: '0 0 3px 1px rgb(0,0,0,.1)',
    margin: "0.7rem 0",
    color: "rgb(0,0,0,.7)",
    ...style,
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  return (
    <input
      style={styles}
      className={`${customStyle.CustomInput} ${className} min-h-[45px] md:min-h-[60px] rounded-md md:rounded-[15px]`}
      type={type}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
    />
  );
};

export default Input;
