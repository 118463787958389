import React, { useState, useEffect } from "react";
import PageWrapper from "../Wrapper/Wrapper";
import { useLocation } from "react-router-dom"; // Import useLocation
import copyIcon from "../../../../Common/Assets/Images/Copy.png";
import { QRCode } from "react-qrcode-logo";
import axiosInstance from "../../../../setup/axios";
import Constants from "../../../../Utils/Constants";
import backarrow from "../../../../Common/Assets/Images/backarrow.png";
import { useNavigate } from "react-router-dom";
import SellOrderDetails from "./SellOrderDetails";
import refresh from "../../../../Common/Assets/Images/refresh.png";
import Countdown from "react-countdown";
import dayjs from "dayjs";
import { dotSpinner } from "ldrs";

function SellDetails() {
  const location = useLocation(); // Use useLocation to access the router state
  const initialSellOrder = location.state?.sellOrder;
  const screenName = location.state?.screenName;
  const [copied, setCopied] = useState(false);
  const [copyCrypto, setCopyCrypto] = useState(false);
  const [sellOrder, setSellOrder] = useState(initialSellOrder);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  dotSpinner.register();

  const fetchDataDetails = (dataId: string) => {
    setIsLoading(true);

    axiosInstance
      .get(`${Constants.API_BASE_URL}/users/orders/${dataId}`)
      .then((res) => {
        setSellOrder(res);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // console.log("isLoading", isLoading)

  const canGoBack = window.history.state.idx !== 0;
  const goBack = (e: any) => {
    e.preventDefault();
    if (screenName === "sell") {
      navigate("/dashboard");
    }
  };

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = sellOrder?.data.transaction.walletAddress;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const copiedToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = sellOrder?.data.cryptoAmount;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setCopyCrypto(true);
    setTimeout(() => {
      setCopyCrypto(false);
    }, 2000);
  };

  // console.log("sellOrder", sellOrder);
  const walletID = sellOrder?.data?.id;
  const orderStatus = sellOrder?.data?.status;
  // const assetID = sellOrder?.data?.walletId;

  let Status: any;
  if (orderStatus === "completed") {
    Status = "Completed";
  } else if (orderStatus === "pending") {
    Status = "Pending";
  } else if (orderStatus === "canceled") {
    Status = "Canceled";
  } else if (orderStatus === "awaiting.approval") {
    Status = "Awaiting Approval";
  } else if (orderStatus === "expired") {
    Status = "Expired";
  } else {
    Status = "Processing";
  }
  // console.log("sellOrder", sellOrder);
  useEffect(() => {
    fetchDataDetails(walletID);
    //eslint-disable-next-line
  }, [orderStatus]);
  function getStatusColorClass(status: string) {
    switch (status) {
      case "Pending":
        return "bg-orange-100 rounded-[10px] p-2 border border-orange-300 text-orange-600 mt-3 w-[200px] ";
      case "Canceled":
        return "bg-red-100 rounded-[10px] p-2 border border-red-300 text-red-600 mt-3 w-[200px]";
      case "Processing":
        return "bg-cyan-100 rounded-[10px] p-2 border border-cyan-300 text-cyan-600 mt-3 w-[200px]";
      case "Completed":
        return "bg-green-100 rounded-[10px] p-2 border border-green-300 text-green-600 mt-3 w-[200px]";
      case "Awaiting Approval":
        return "bg-green-100 rounded-[10px] p-2 border border-green-300 text-green-600 mt-3 w-[200px]";
      case "Failed":
        return "bg-red-100 rounded-[10px] p-2 border border-red-300 text-red-600 mt-3 w-[200px]";
      case "Expired":
        return "bg-gray-100 rounded-[10px] p-2 border border-gray-300 text-gray-600 mt-3 w-[200px]";
      default:
        return "";
    }
  }

  if (!sellOrder) {
    return <SellOrderDetails />;
  }

  return (
    <PageWrapper
      content={
        <div className="flex flex-col  ">
          <div className="w-[380px] lg:w-[500px] flex justify-between items-center ">
            <button
              onClick={(e) => goBack(e)}
              className={` flex cursor-pointer  focus:outline-none ${
                !canGoBack ? "opacity-50" : <> </>
              }`}
              disabled={!canGoBack}
            >
              {" "}
              <div>
                <img src={backarrow} alt="Back" className="h-3 w-4" />
              </div>
              <div className="ml-3 cursor-pointer text-[12px] ">Back</div>
            </button>
            <div onClick={() => fetchDataDetails(walletID)}>
              <img src={refresh} alt="" className="cursor-pointer h-4 w-4" />
            </div>
          </div>
          {isLoading ? (
            <div className="flex flex-col justify-center items-center min-h-[450px] min-w-[390px] p-6">
              <div className="mb-4">
                <l-dot-spinner
                  size="80"
                  speed="0.9"
                  color="black"
                ></l-dot-spinner>
              </div>
              <div>
                <h2 className="p-8">Loading...</h2>
              </div>
            </div>
          ) : (
            <div>
              <div
                className=" flex flex-col  rounded-[15px] p-6 items-center w-[380px] lg:w-[500px] mt-12"
                style={{ backgroundColor: "#F5F5F5" }}
              >
                <img
                  src={sellOrder?.data?.assetEntity?.icon}
                  alt=""
                  className="w-8 h-8"
                />
                <div className="mt-3 text-[16px] font-medium">
                  Sell {sellOrder?.data.asset}
                </div>
                <div className="font-bold text-[22px] p-2 ">
                  USD {sellOrder?.data.amount}
                </div>

                <Countdown
                  date={dayjs(new Date(sellOrder?.data.createdAt))
                    .add(1, "hour")
                    .valueOf()}
                  zeroPadTime={2}
                  renderer={({ formatted: { hours, minutes, seconds } }) => {
                    // Render countdown
                    return (
                      <div className="flex flex-col w-full mb-4 mt-2 ">
                        <div className={` mt-2 ${getStatusColorClass(Status)}`}>
                          {Status}
                        </div>{" "}
                        {orderStatus === "pending" && (
                          <div className="countdown-container flex justify-end items-center border border-gray-200 rounded-[10px]  mt-4">
                            <div className="countdown-item flex flex-col px-2">
                              <span className=" font-bold text-xl">
                                {minutes}
                              </span>
                              {/* <span className=" text-sm"> Minutes</span> */}
                            </div>
                            <span className="text-xl">:</span>
                            <div className="countdown-item flex flex-col px-2">
                              <span className=" font-bold text-xl">
                                {seconds}
                              </span>
                              {/* <span className=" text-sm">Seconds</span> */}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
              <div className=" mt-2 w-[380px] lg:w-[500px]">
                <div className="text-[18px] font-bold p-2 text-left ">
                  {" "}
                  You will send
                </div>
                <div className="flex justify-between border border-gray-300 p-3 rounded-[10px] ">
                  <div className="flex">
                    {" "}
                    <div>
                      <img
                        src={sellOrder?.data.assetEntity?.icon}
                        alt=""
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="ml-3">
                      {sellOrder?.data.cryptoAmount} {sellOrder?.data.asset}
                    </div>{" "}
                  </div>

                  <div
                    className="flex justify-between items-center "
                    onClick={copiedToClipboard}
                  >
                    <button>
                      <img src={copyIcon} alt="Copy" className="w-4 h-4" />
                    </button>
                    {copyCrypto && (
                      <span className="text-xs text-blue">Copied</span>
                    )}
                  </div>
                </div>
                <div className="text-[13px]  p-2 text-left mt-1 text-gray-400 flex">
                  Order Id:{" "}
                  <div className="text-green-600 ml-1">
                    {" "}
                    {sellOrder?.data.transactionId}
                  </div>
                </div>
              </div>
              <div className=" flex flex-col items-start w-[380px] lg:w-[500px]">
                {" "}
                <div className="text-[18px] font-bold p-2 text-left ">
                  {" "}
                  Payment Details
                </div>
              </div>
              {/* <div className="border border-gray-300 p-3 rounded-[10px]">{sellOrder?.data.transaction.walletAddress}</div> */}
              <div
                className="border border-gray-300 rounded-[10px] p-3 w-[380px] lg:w-[500px]  "
                onClick={copyToClipboard}
              >
                <div className="flex justify-between items-center">
                  <div>{sellOrder?.data.transaction.walletAddress}</div>
                  <button>
                    <img src={copyIcon} alt="Copy" className="w-4 h-4" />
                  </button>
                  {copied && <span className="text-xs text-blue">Copied</span>}
                </div>
              </div>

              <div className="mt-6 border border-gray-300 rounded-[10px] p-4 flex  items-center w-[380px] lg:w-[500px]">
                <div className="text-center">
                  Or scan the Qr code to capture the wallet address
                </div>

                <div>
                  {" "}
                  <QRCode
                    value={sellOrder?.data.transaction.walletAddress}
                    size={150}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      }
    />
  );
}

export default SellDetails;
