import React, { ReactNode } from "react";
import style from "./style.module.css";
import { logo3 } from "../../../../Common/Assets/Images";
import playStore from "../../../../Common/Assets/Images/hodlpayAds3.svg";
import appStore from "../../../../Common/Assets/Images/hodlpayAds2.svg";
import backarrow from "../../../../Common/Assets/Images/backarrow.png";
import { useNavigate } from "react-router-dom";

// import appStore from '../../../../Common/Assets/Images/hodlpayAds2.svg'

type wraaperProp = {
  form: ReactNode;
  sideImage: ReactNode;
};

const Wrapper = ({ form, sideImage }: wraaperProp) => {
  const canGoBack = window.history.state.idx !== 0;
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div
      className={`flex a-i-s relative w-screen h-screen md:p-8 bg-bg overflow-hidden ${style.wrapper}`}
    >
      {sideImage}
      <section
        className={`w-full h-full overflow-y-auto relative ${style.formContent}`}
      >
        <div
          className={`w-full cus-md:px-8 pr-0 flex flex-col j-c-c a-i-s h-fit `}
        >
          <div className="w-full flex justify-between  pr-5">
            <button
              onClick={goBack}
              className={` lg:hidden  md:hidden flex cursor-pointer md:w-[9rem] mt-[3rem] focus:outline-none ${
                !canGoBack ? "opacity-50" : <> </>
              }`}
              disabled={!canGoBack}
            >
              {" "}
              <div>
                <img src={backarrow} alt="" className="h-4 w-6" />
              </div>
              <div className="ml-3 cursor-pointer">Back</div>
            </button>
            <img
              src={logo3}
              alt=""
              className={`w-28 md:w-[9rem] mt-[3rem] ${style.logo}`}
            />
          </div>
          {form}

          <div
            className={`w-full flex-col mt-[0rem] mb-[2rem] items-start hidden ${style.mobileAds}`}
          >
            <p className="text-left text-gray-500">
              Download the hodlpay mobile app from the <br />
              playstore.
            </p>
            <div className="flex items-start mt-3">
              <a href=" https://apps.apple.com/gh/app/hodlpay/id6463806798">
                <img src={appStore} alt="" className="mr-4" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.app.hodlpay&hl=en&gl=US"
                target="_blank"
                rel="noreferrer"
              >
                <img src={playStore} alt="" />
              </a>
            </div>
          </div>
          <div
            className={`w-full flex j-c-s bottom-0 my-3 ${style.authFooter}`}
          >
            <small className="text-left text-gray-400">
              © {new Date().getFullYear()} HodlPay. All Rights Reserved
            </small>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Wrapper;
