import { useState, useEffect } from "react";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import arrow from "../../../Common/Assets/Images/arrow-right.png";
import { useNavigate } from "react-router-dom";
import { dotSpinner } from "ldrs";
import refresh from "../../../Common/Assets/Images/refresh.png";
import ExchangeDetails from "../../../../src/Pages/Main/Components/ExchangeModal/ExchangeDetailsModal";
import dayjs from "dayjs";

function SwapTransactions() {
  const [swapTransactions, setSwapTransactions] = useState([]);
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [totalTransactions, setTotalTransactions] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  //eslint-disable-next-line
  const [selectedTransactionId, setSelectedTransactionId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState({});
  const [swapData, setSwapData] = useState({});
  //eslint-disable-next-line
  const [refreshData, setRefreshData] = useState(null);
  dotSpinner.register();

  useEffect(() => {
    fetchByPagination(currentPage);
    setSwapData(swapData);
  }, [currentPage, data, swapData]);

  useEffect(() => {
    if (refreshData) {
      fetchByPagination(currentPage);
    }
  }, [refreshData, currentPage, data, swapData]);

  const handleTransactionClick = (transactionId: string, responseData: any) => {
    const selectedTransaction = responseData.find(
      (transaction: any) => transaction.id === transactionId,
    );

    if (selectedTransaction) {
      setData(selectedTransaction);
      setIsModalOpen(true);
      setSelectedTransactionId(transactionId);
    } else {
      // console.log("Transaction not found");
    }
  };

  const handleViewMore = () => {
    navigate("/more-swap-transactions");
  };

  const fetchByPagination = (count: number) => {
    axiosInstance
      .get(
        `${
          Constants.API_BASE_URL
        }/swap/transactions?limit=${itemsPerPage}&skip=${
          (count - 1) * itemsPerPage
        }`,
      )
      .then((res) => {
        const swapData = res.data.data;
        setSwapTransactions(swapData);
        setTotalTransactions(res.data.total);
        setIsLoading(false);
        // console.log("hey", swapData);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  };

  function getStatusColorClass(status: string) {
    switch (status) {
      case "waiting":
        return "inline-block bg-green-200 text-green-500 px-3 rounded-full text-sm align-middle ";
      case "confirming":
      case "exchanging":
        return "bg-cyan-200 text-cyan-500 inline-block align-middle  px-3 rounded-full text-sm";
      case "sending":
        return "bg-purple-200 inline-block align-middle  text-purple-500 px-3 rounded-full text-sm";
      case "finished":
        return "bg-green-200 inline-block align-middle  text-green-500 px-3 rounded-full text-sm";
      case "failed":
        return "bg-red-200 inline-block align-middle  text-red-500 px-3 rounded-full text-sm";
      case "refunded":
        return "bg-gray-200 inline-block align-middle text-gray-500 px-3 rounded-full text-sm";
      default:
        return ""; // Default style
    }
  }

  return (
    <div className="w-full">
      <section className="w-full">
        {isModalOpen && (
          <ExchangeDetails
            isOpen={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            responseData={data} // Pass data to modal component
          />
        )}
        <div className="my-10 mb-6 flex flex-col items-start w-full ">
          <h1 className="font-semibold text-xl">Your Swap Transactions</h1>
          <div className="flex justify-between w-full">
            <div>
              <small>Table showing your swap transactions</small>{" "}
            </div>
            <div className="flex ">
              {totalTransactions > 5 && (
                <small
                  onClick={handleViewMore}
                  className="underline cursor-pointer mr-2"
                >
                  View More
                </small>
              )}
              <div
                onClick={() => {
                  setIsLoading(true);
                  fetchByPagination(currentPage);
                }}
              >
                <img src={refresh} alt="" className="cursor-pointer h-4 w-4" />
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="mx-auto w-full my-auto ">
              <div className="pt-6">
                <l-dot-spinner
                  size="80"
                  speed="0.9"
                  color="black"
                ></l-dot-spinner>
              </div>

              <div>
                <div className="mt-8">
                  <h2 className="p-8">Loading...</h2>
                </div>
              </div>
            </div>
          ) : (
            <ul className="w-full">
              {swapTransactions.length === 0 ? (
                <div className="w-full py-[5rem] flex flex-col items-center justify-center">
                  <i className="fa fa-folder-open text-4xl mb-5 text-theme"></i>
                  <p>No Swap transactions</p>
                </div>
              ) : (
                swapTransactions.map(
                  (transaction: any, index: number, responseData: any) => (
                    <li
                      key={transaction.id}
                      onClick={() =>
                        handleTransactionClick(transaction.id, responseData)
                      }
                      className="cursor-pointer"
                    >
                      <div
                        className={`flex justify-between py-2 ${
                          index !== swapTransactions.length - 1
                            ? "border-b border-gray-200"
                            : ""
                        }`}
                      >
                        {/* First main div */}
                        <div className="flex">
                          <div className="flex flex-col my-2 mr-4">
                            <div className="relative">
                              <img
                                src={transaction.fromCurrencyIconUrl}
                                alt=""
                                className="w-[40px] h-[40px] rounded-[2px] relative z-10"
                              />
                              <img
                                src={transaction.toCurrencyIconUrl}
                                alt=""
                                className="w-[40px] h-[40px] rounded-md relative z-10 absolute top-0 left-0 -mt-7 ml-4"
                              />
                            </div>
                          </div>

                          <div className="">
                            <div className="flex">
                              <p className=" font-medium">
                                {transaction.fromCurrency.toUpperCase()}
                                {transaction.fromCurrency === "usdt" && (
                                  <small className="ml-1 items-center bg-green-500 px-2 mx-2 rounded text-white">
                                    {transaction.fromNetwork.toLowerCase()}
                                  </small>
                                )}
                              </p>{" "}
                              <img
                                src={arrow}
                                alt=""
                                className="h-4 w-4 mx-2"
                              />
                              <p className="font-medium">
                                {transaction.toCurrency.toUpperCase()}
                                {transaction.toCurrency === "usdt" && (
                                  <small className="ml-1 items-center bg-green-500 px-2 mx-2 rounded text-white">
                                    {transaction.toNetwork.toLowerCase()}
                                  </small>
                                )}
                              </p>
                            </div>

                            <small className="text-gray-400 ">
                              {dayjs(new Date(transaction.createdAt)).format(
                                "MMM D, YYYY hh:mm a",
                              )}
                            </small>
                          </div>
                        </div>

                        {/* Second main div */}
                        <div className="ml-20 flex flex-col items-end">
                          <p className=" ">
                            {transaction.amount}{" "}
                            {transaction.fromCurrency.toUpperCase()}
                          </p>
                          <small
                            className={` my-2 ${getStatusColorClass(
                              transaction.status,
                            )}`}
                          >
                            {transaction.status}
                          </small>
                        </div>
                      </div>
                    </li>
                  ),
                )
              )}
            </ul>
          )}
        </div>
      </section>
    </div>
  );
}

export default SwapTransactions;
