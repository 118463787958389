import React from "react";
import Wrapper from "./Components/Wrapper/Wrapper";
import SideImage from "./Components/SideImage/SideImage";
import { authImage, logo2 } from "../../Common/Assets/Images";
import EmailForm from "./Components/ConfirmForms/EmailForm";
import PhoneForm from "./Components/ConfirmForms/PhoneForm";

type prop = {
  type: string;
};

const Confirmation = ({ type }: prop) => {
  // console.log(localStorage.getItem('tokens'))
  return (
    <Wrapper
      form={type === "phone" ? <PhoneForm /> : <EmailForm />}
      sideImage={<SideImage mainImage={authImage} logo={logo2} />}
    />
  );
};

export default Confirmation;
