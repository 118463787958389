import React, { useState } from "react";

import copyIcon from "../../../Common/Assets/Images/Copy.png";
import { QRCode } from "react-qrcode-logo";
import OtpInput from "react-otp-input";
import SuccessModal from "./SuccessModal";
import backarrow from "../../../Common/Assets/Images/backarrow.png";

interface TwoFactorAuthProps {
  url: string;
  secret: string;
  isSuccessModalOpen: boolean;
  setIsSuccessModalOpen: (value: boolean) => void;
  showError: any;
  verifyOtp: (data: any) => void;
  handleOtp: (otp: string) => void;
  twoFactorOtp: string;
  setUpTwoFactor: () => void;
  goBack: () => void;
}

function TwoFactorAuth({
  url,
  secret,
  isSuccessModalOpen,
  setIsSuccessModalOpen,
  showError,
  verifyOtp,
  handleOtp,
  twoFactorOtp,
  setUpTwoFactor,
  goBack,
}: TwoFactorAuthProps) {
  //eslint-disable-next-line

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = secret;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col items-start">
        <button onClick={goBack} className="flex  cursor-pointer mt-6">
          <div className="">
            <img src={backarrow} alt="" className="h-4 w-6" />
          </div>
          <div className="ml-3 cursor-pointer">Back</div>
        </button>
        <div className="mt-6 font-bold text-[24px] text-left">
          Enable two-factor authentication
        </div>

        <div className="flex  flex-col md:flex-row lg:flex-row w-full justify-between  mt-3 lg:mt-[-2rem] ">
          <div>
            <div className="font-medium text-[16px] text-left lg:whitespace-nowrap md:whitespace-nowrap">
              1. You need an authenticator mobile app to complete this process
              such as one of the following
            </div>
            <div className="px-5 text-left mt-2">
              <div className="mt-2 text-blue cursor-pointer">
                <a href="https://onelink.to/tdzn44">Google Authenticator</a>
              </div>
              <div className="mt-2 text-blue cursor-pointer">
                <a href="https://onelink.to/s4abj6">Microsoft Authenticator</a>
              </div>
              <div className="mt-2 text-blue cursor-pointer">
                <a
                  href="https://onelink.to/6fb65x
"
                >
                  Authy
                </a>
              </div>
            </div>
          </div>

          <div className="mt-10">
            <QRCode value={url} size={180} />
          </div>
        </div>

        <div className="mt-8 font-medium text-[16px]">
          2. Scan the QR code with your authenticator
        </div>
        <div className="px-5 text-left mt-2">
          If you can't scan the code, you can enter this key into your
          authenticator app
        </div>

        <div
          className="border border-gray-300 rounded-[10px] p-2 mt-4  w-full md:w-[450px] lg:w-[450px]  "
          onClick={copyToClipboard}
        >
          <div className="flex justify-between items-center">
            <div>{secret}</div>
            <button>
              <img src={copyIcon} alt="Copy" className="w-4 h-4" />
            </button>
            {isCopied && <span className="text-xs text-blue">Copied</span>}
          </div>
        </div>

        <div className="mt-8 font-medium text-[16px]">
          3. After scanning the QR code above, enter the six-digit code
          generated by your authenticator
        </div>

        <div className="px-5 mt-2">
          <OtpInput
            value={twoFactorOtp}
            onChange={handleOtp}
            numInputs={6}
            renderSeparator={<span className="mx-2"></span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              border: "1px solid #d1d5db",
              textAlign: "center",
              fontSize: "1.5rem",
              marginTop: "1rem",
              marginBottom: "1rem",
              borderRadius: "10px",
              boxSizing: "border-box",
              maxWidth: "60px",
              width: "100%",
              height: "60px",
              minHeight: "40px",
            }}
            containerStyle={{
              display: "flex",
              width: "100%",
              maxWidth: "100%",
              maxHeight: "100%",
              height: "100%",
            }}
          />
          {showError && (
            <p className="text-red-500 mt-2 text-left">{showError}</p>
          )}
        </div>

        {/* <div className="bg-gray-200 flex rounded-[10px] px-2 py-4 mt-10 items-start justify-center w-full md:w-[450px] lg:w-[450px]  cursor-pointer">
            <div className="text-[15px] text-gray-600 cursor-pointer">Cancel</div>
          </div> */}

        <div
          className="bg-blue flex rounded-[10px] px-2 py-4 mt-10 items-start justify-center w-full md:w-[450px] lg:w-[450px] cursor-pointer"
          onClick={verifyOtp}
        >
          <div className="text-[15px] text-white cursor-pointer">
            Verify Now
          </div>
        </div>
      </div>

      {isSuccessModalOpen && (
        <SuccessModal
          onClose={() => setIsSuccessModalOpen(false)}
          isOpen={isSuccessModalOpen}
        />
      )}
    </div>
  );
}

export default TwoFactorAuth;
