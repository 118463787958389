import React from "react";
import btcclear from "../../../Common/Assets/Images/btcClear.png";
import arrowUp from "../../../Common/Assets/Images/arrow-up.png";
import arrowDown from "../../../Common/Assets/Images/arrow-down.png";
import pattern from "../../../Common/Assets/Images/pattern.png";
// import { useNavigate } from "react-router-dom";

function Card({
  walletdata,
  handleOpenRecieveModal,
  handleOpenSendModal,
}: any) {
  // console.log("walletdata", walletdata);

  // const navigate = useNavigate();
  // const handleSendModal = () => {
  //   navigate("/send-bitcoin");
  // }
  return (
    <div className="w-full">
      <div className=" relative w-full md:w-[360px] lg:w-full lg:h-[270px] md:h-[280px] sm:h-[200px]  rounded-[17px] bg-prevBlue  ">
        <div className=" relative ">
          <img
            src={pattern}
            className="w-full md:w-[360px] lg:w-full lg:h-[270px]  md:h-[280px] sm:h-[200px]   "
            alt=""
          />
        </div>

        <div className="absolute inset-0 flex flex-col justify-between p-6 text-white text-base font-bold items-start w-full">
          <div className="flex justify-between w-full">
            <div>BITCOIN</div>
            <img src={btcclear} alt="" className="h-12 w-12" />
          </div>

          <div className="flex flex-col items-start">
            <div className="text-3xl lg:text-4xl font-bold">
              {walletdata?.walletdata?.balance}{" "}
              {walletdata?.walletdata?.walletName}
            </div>

            <div className="text-lg lg:text-xl font-medium">
              {walletdata?.walletdata?.balanceUsd} USD
            </div>
          </div>

          <div className="mt-8 flex p-2 items-start cursor-pointer">
            <div className="flex pr-6" onClick={handleOpenSendModal}>
              <div className="text-base font-bold pr-2">Send</div>
              <div>
                <img src={arrowUp} alt="" className="h-6 w-6" />
              </div>
            </div>
            <div
              className="flex items-start cursor-pointer"
              onClick={handleOpenRecieveModal}
            >
              <div className="text-base font-bold pr-2">Receive</div>
              <div>
                <img src={arrowDown} alt="" className="h-6 w-6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
