import "./App.css";
import Routers from "./setup/Routes";
import RouteMiddleware from "./setup/RouteMiddleware";
import Modal from "./Pages/Main/Components/Modal/Modal";
import { useContext } from "react";
import Context from "./store/Contex";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const { state } = useContext(Context);
  const queryClient = new QueryClient();

  // useEffect(()=>{
  //   dispatch({type: 'TURNON', payload: <ModalLoading/>})
  // }, [])

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        {state.modal.status && <Modal />}
        <Toaster />
        <RouteMiddleware />

        <Routers />
      </QueryClientProvider>
    </div>
  );
}

export default App;
