import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Modal {
  modalOn: boolean;
  content: string;
}

const initialState: Modal = {
  modalOn: false,
  content: "",
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    turnOn: (state, action: PayloadAction<string>) => {
      state.modalOn = true;
      state.content = action.payload;
      // console.log(action.payload)
    },
    turnOff: (state) => {
      state.modalOn = false;
      state.content = "";
    },
  },
});

export const { turnOn, turnOff } = modalSlice.actions;

export default modalSlice.reducer;
