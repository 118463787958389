import React, { useState } from "react";
import close from "../../../Common/Assets/Images/Close.png";
import copyIcon from "../../../Common/Assets/Images/Copy.png";
import copyWhite from "../../../Common/Assets/Images/copyWhite.png";

import { QRCode } from "react-qrcode-logo";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  walletdata: any;
}

function RecieveModal({ walletdata, isOpen, onClose }: ModalProps) {
  const [isCopied, setIsCopied] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = walletdata?.walletdata?.walletAddress;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const copyAddress = () => {
    const el = document.createElement("textarea");
    el.value = walletdata?.walletdata?.walletAddress;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 2000);
  };

  // console.log("data",walletdata?.walletdata?.walletAddress)
  return (
    <div
      className={`modal fixed w-full h-full top-0 left-0 flex  justify-center overflow-y-auto z-50 p-6 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="fixed inset-0 bg-black opacity-50 "></div>
      <div className="relative bg-white p-4 rounded-lg shadow-md w-[480px] sm:w-full  min-w-[200px] max-w-[500px] min-h-[400px] max-h-[730px] sm:min-h-[350px] sm:max-h-[7300px] md:min-h-[550px] md:max-h-[730px]">
        {/* Close button */}
        <button onClick={onClose} className="absolute top-0 right-0 p-4 z-50">
          <img src={close} alt="Close" className="h-3 w-3" />
        </button>
        {/* Modal content */}
        <div className="modal-content text-center">
          {/* Title */}
          <div className="modal-header pb-2 flex items-center justify-center">
            <div className="pr-3">
              <img
                src="https://exolix.com/icons/coins/BTC.png"
                alt=""
                className="w-10 h-10"
              />
            </div>
            <h3 className="modal-title text-[24px] font-bold ">
              Receive Bitcoin
            </h3>
          </div>
          {/* Content */}
          <div className="modal-body flex flex-col items-center p-6">
            <QRCode value={walletdata?.walletdata?.walletAddress} size={200} />
          </div>
        </div>
        <div className="mt-5 text-base font-medium text-left">
          Wallet Address
        </div>
        <div className="border border-theme shadow-theme flex justify-between rounded-[10px] px-2 py-2 mt-2 shadow-md ">
          <div className="text-[12px] lg:text-[15px] py-2 ">
            {walletdata?.walletdata?.walletAddress}
          </div>
          <div
            onClick={copyToClipboard}
            className="text-sm pl-2 py-2 flex items-center overflow-hidden cursor-pointer "
          >
            <button>
              <img src={copyIcon} alt="Copy" className="w-4 h-4" />
            </button>
            {isCopied ? (
              <span className="ml-1 text-xs text-green-500">Copied</span>
            ) : (
              <span className="ml-1"></span>
            )}
          </div>
        </div>
        <div className="mt-7">
          <div className="text-left text-base font-medium">Asset</div>
          <div className="flex justify-between bg-gray-100 rounded-[10px] w-full p-4 mt-2">
            <div>Currency</div>
            <div className="flex">
              <div>{walletdata?.walletdata?.walletName}</div>
              <div className="pl-3">
                <img
                  src="https://exolix.com/icons/coins/BTC.png"
                  alt=""
                  className="w-6 h-6"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={copyAddress}
          className="border bg-prevBlue flex rounded-[10px] px-2 py-4 mt-10 items-center justify-center w-full mx-auto cursor-pointer"
        >
          <div className="text-[15px] text-white cursor-pointer  ">
            Copy Address
          </div>
          {isCopy ? (
            <span className="text-xs text-white ml-2 ">Copied</span>
          ) : (
            <span className="text-[13px]"></span>
          )}
          <button onClick={copyAddress} className="ml-2 cursor-pointer">
            <img src={copyWhite} alt="Copy" className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default RecieveModal;
