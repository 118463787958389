import React from "react";
import transactions from "../../../Common/Assets/Images/transactions.png";
import deposit from "../../../Common/Assets/Images/deposit.png";
import withdraw from "../../../Common/Assets/Images/withdrawal.png";
import pending from "../../../Common/Assets/Images/pending.png";

function WalletStats({ walletdata }: any) {
  return (
    <div className="w-full mt-4 lg:mt-4 flex flex-col lg:flex-row ">
      <div className="flex flex-row lg:flex-col w-full">
        <div className="flex flex-col  items-start bg-white rounded-[17px] py-3 px-7 lg:px-5 border border-gray-100 shadow-sm mb-4 w-full md:w-[150px] lg:w-[220px] mr-2 lg:ml-4">
          <div className="bg-green-100 rounded-[10px] p-2 mb-2 mt-2">
            <img src={deposit} alt="" className="h-6 w-6" />
          </div>
          <h1 className="text-sm  whitespace-nowrap ">Number of deposits</h1>

          <h1 className="text-xl font-bold">
            {walletdata?.walletdata?.numberOfDeposit}{" "}
          </h1>
        </div>

        <div className="flex flex-col items-start bg-white rounded-[17px] py-3 px-7 lg:px-5 border border-gray-100 shadow-sm mb-4 w-full md:w-[150px] lg:w-[220px] ml-3 lg:ml-4">
          <div className="bg-green-100 rounded-[10px] p-2 mb-2 mt-2">
            <img src={pending} alt="" className="h-6 w-6" />
          </div>
          <h1 className="text-sm whitespace-nowrap  ">Pending Balance </h1>

          <h1 className="text-xl font-bold">
            {walletdata?.walletdata?.pendingReceived}{" "}
            {walletdata?.walletdata?.walletName.toUpperCase()}
          </h1>
        </div>
      </div>

      <div className="flex flex-row lg:flex-col w-full">
        <div className="flex flex-col items-start bg-white rounded-[17px] py-3 px-5  lg:px-5   border border-gray-100 shadow-sm mb-4  w-full md:w-[150px] lg:w-[220px] mr-2 lg:ml-4">
          <div className="bg-yellow-100 rounded-[10px] p-2 mb-2 mt-2">
            <img src={withdraw} alt="" className="h-6 w-6" />
          </div>
          <h1 className="text-sm whitespace-nowrap">Number of withdrawals</h1>

          <h1 className="text-xl font-bold">
            {walletdata?.walletdata?.numberOfWithdrawal}{" "}
          </h1>
        </div>

        <div className="flex flex-col items-start bg-white rounded-[17px] py-3 px-8  lg:px-5  border border-gray-100 shadow-sm mb-4  w-full md:w-[150px] lg:w-[220px] ml-2 lg:ml-4">
          <div className="bg-cyan-100 rounded-[10px] p-2 mb-2 mt-2">
            <img src={transactions} alt="" className="h-6 w-6" />
          </div>
          <h1 className="text-sm whitespace-nowrap ">Total transactions</h1>

          <h1 className="text-xl font-bold">
            {walletdata?.walletdata?.totalTransactions}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default WalletStats;
