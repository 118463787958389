import React, { useContext, useEffect, useState } from "react";
import style from "./style.module.css";
import Input from "../../../../Common/Components/Input/Input";
import Button from "../../../../Common/Components/Button/Button";
import Context from "../../../../store/Contex";
import axios from "axios";
import Constants from "../../../../Utils/Constants";
import AuthService from "../../../../setup/AuthServices";
import Notify from "../../../../Helpers/Toast";
import Loading from "../../../../Common/Components/Loading/Loading";
import axiosInstance from "../../../../setup/axios";

type props = {
  close: () => void;
  showBack?: boolean;
};

function PaymentMethod({ close, showBack }: props) {
  const { state, dispatch } = useContext(Context);
  const [formState, setFormState] = useState({
    type: "Select Payment Type",
    network: "",
    accountName: "",
    phoneNumber: "",
    accountType: "",
    isRequesting: {
      status: false,
      text: "",
    },
  });

  useEffect(() => {
    let userPayment = state.userInfo.paymentMethod;
    if (userPayment) {
      setFormState((prev) => {
        return {
          ...prev,
          accountName: userPayment.accountName,
          phoneNumber: userPayment.phoneNumber,
          accountType: userPayment.accountType,
          network: userPayment.network,
        };
      });
    } else {
      // Getting user payment method
      axios
        .get(
          `${Constants.API_BASE_URL}/users/payment/method`,
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          if (res.data) {
            setFormState((prev) => {
              return {
                ...prev,
                accountName: res.data?.accountName,
                phoneNumber: res.data?.phoneNumber,
                accountType: res.data?.accountType,
                network: `${res.data.network}`,
              };
            });
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  const SubmitPayoutDeatils = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formState.phoneNumber?.length > 10) {
      Notify("top-right", "Phone Number can't be more than 10", "error");
      return;
    }
    if (formState.phoneNumber?.length < 10) {
      Notify("top-right", "Phone Number is not up to 10", "error");
      return;
    }
    let data = {
      accountName: formState.accountName,
      phoneNumber: formState.phoneNumber,
      accountType: formState.accountType,
      network: formState.network,
    };
    setFormState((prev) => {
      return {
        ...prev,
        isRequesting: {
          ...prev.isRequesting,
          status: true,
          text: "Setting Payment Method",
        },
      };
    });
    axiosInstance
      .post(`${Constants.API_BASE_URL}/users/payment/method`, data)
      .then((response) => {
        setFormState({
          ...formState,
          isRequesting: { status: false, text: "" },
        });
        Notify("top-center", "Payment Method Changed successfully");
        close();
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo") ?? "{}");
        userInfo = { ...userInfo, paymentMethod: response.data };
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
        dispatch({ type: "USER_INFO", payload: userInfo });
      })
      .catch((err) => {
        Notify("top-right", "Something went wrong", "error");
        setFormState({
          ...formState,
          isRequesting: { status: false, text: "" },
        });
      });
  };
  return (
    <form
      onSubmit={(e) => SubmitPayoutDeatils(e)}
      className="w-[350px] flex flex-col mx-auto px-3 pb-8"
    >
      <div
        className="w-full flex text-theme cursor-pointer"
        onClick={() => close()}
      >
        <i className="fa fa-arrow-left mr-2"></i>
        <small>Go Back</small>
      </div>

      {/* <small>We currently do not support Bank payment method</small> */}
      {formState.isRequesting.status && (
        <Loading loadText={formState.isRequesting.text} />
      )}
      <div className="w-[250px] mx-auto flex flex-col mt-10">
        <h1>Add Payment Method</h1>
        <br />

        <div className="flex flex-col items-start w-full mb-4">
          <small>Account Type</small>
          <div className="w-full relative">
            <i className="fa fa-caret-down absolute right-5 top-[12px] text-gray-500"></i>
            <select
              required
              value={formState.accountType}
              onChange={(e) =>
                setFormState({ ...formState, accountType: e.target.value })
              }
              className={`${style.select} w-full h-[40px] rounded-[20px] flex justify-between bg-bg px-4`}
              name=""
              id=""
            >
              <option value="">Select Account type</option>
              <option value="subscriber">Subscriber</option>
              <option value="agent">Agent</option>
            </select>
          </div>
        </div>

        <div className="flex flex-col items-start w-full mb-4">
          <small>Network</small>
          <div className="w-full relative">
            <i className="fa fa-caret-down absolute right-5 top-[12px] text-gray-500"></i>
            <select
              required
              value={formState.network}
              onChange={(e) =>
                setFormState((prev) => {
                  return { ...prev, network: e.target.value };
                })
              }
              className={`${style.select} w-full h-[40px] rounded-[20px] flex justify-between bg-bg px-4`}
              name=""
              id=""
            >
              <option value="">Select Network Type</option>
              <option value="MTN">MTN Mobile Money</option>
              <option value="VODAFONE">Voda Cash </option>
              <option value="AIRTEL_TIGO">AirtelTigo Cash</option>
            </select>
          </div>
        </div>

        <div className="flex flex-col items-start w-full mb-3">
          <small>Account Number</small>
          <Input
            className={`bg-bg`}
            style={{ minWidth: "100%", minHeight: "40px", fontSize: "0.8rem" }}
            type="tel"
            placeholder="Enter mobile number"
            required
            value={formState.phoneNumber}
            setValue={(val) => setFormState({ ...formState, phoneNumber: val })}
          />
        </div>

        <div className="flex flex-col items-start w-full mb-3">
          <small>Account Name</small>
          <Input
            className={`bg-bg`}
            style={{ minWidth: "100%", minHeight: "40px", fontSize: "0.8rem" }}
            type="text"
            placeholder="Enter account name"
            required
            value={formState.accountName}
            setValue={(val) => setFormState({ ...formState, accountName: val })}
          />
        </div>
      </div>

      <Button
        text={`Add`}
        onClick={() => {}}
        className={`bg-blue text-white px-8 text-sm mt-5`}
      />
    </form>
  );
}

export default PaymentMethod;
