import React, { useContext } from "react";
import { logo3 } from "../../../../Common/Assets/Images";
import {
  HomeDark,
  HomeLight,
  OrderDark,
  OrderLight,
  ProfileDark,
  ProfileLight,
  SupportLight,
  SupportDark,
  SettingsDark,
  SettingsLight,
  FaqLight,
  FaqDark,
  logout,
  SwapLight,
  SwapDark,
  ReferralLight,
  ReferralDark,
} from "./Assets/Icons";

import NavItem from "./NavItem";
import { useLocation } from "react-router-dom";
import style from "./sidebar.module.css";
import Context from "../../../../store/Contex";

const SideBar = () => {
  const { dispatch } = useContext(Context);
  const location = useLocation();

  return (
    <div
      className={`${style.sidebar} overflow-hidden flex flex-col j-c-b bg-transparent`}
      style={{ minWidth: "250px", minHeight: "100%", maxHeight: "100%" }}
    >
      <section className="w-full h-screen overflow-y-auto">
        <div className="w-full flex flex-col justify-between h-full">
          <section className="flex flex-col w-full mt-10">
            <div className="logo w-full pl-10">
              <img src={logo3} alt="" width={110} />
            </div>

            <div className="navs flex flex-col mt-10 w-full">
              <NavItem
                isActive={
                  location.pathname === "/dashboard" ||
                  location.pathname.includes("/dashboard/kyc")
                }
                text="Dashboard"
                activeIcon={HomeLight}
                icon={HomeDark}
                link="/dashboard"
              />
              <NavItem
                isActive={location.pathname === "/orders"}
                text="Orders"
                activeIcon={OrderLight}
                icon={OrderDark}
                link="/orders"
              />
              <NavItem
                isActive={location.pathname === "/swap"}
                text="Swap"
                activeIcon={SwapLight}
                icon={SwapDark}
                link="/swap"
              />
              {/* 
              <NavItem
                isActive={location.pathname === "/wallet"}
                text="Wallet"
                activeIcon={WalletLight}
                icon={WalletDark}
                link="/wallet"
              /> */}
              {/* <NavItem
                isActive={location.pathname.includes("/news")}
                text="News/Learn"
                activeIcon={NewsActive} 
                icon={NewsOff}
                link="/news"
              /> */}
              <NavItem
                isActive={location.pathname === "/profile"}
                text="Profile"
                activeIcon={ProfileLight}
                icon={ProfileDark}
                link="/profile"
              />
              <NavItem
                isActive={location.pathname.includes("/referral")}
                text="Referral"
                activeIcon={ReferralLight}
                icon={ReferralDark}
                link="/referral"
              />
              <NavItem
                isActive={location.pathname.includes("/support")}
                text="Support"
                activeIcon={SupportLight}
                icon={SupportDark}
                link="/support"
              />
              <NavItem
                isActive={
                  location.pathname === "/settings" ||
                  location.pathname.includes("/verify-account")
                }
                text="Settings"
                activeIcon={SettingsLight}
                icon={SettingsDark}
                link="/settings"
              />
              <NavItem
                isActive={location.pathname === "/faq"}
                text="FAQs"
                activeIcon={FaqLight}
                icon={FaqDark}
                link="/faq"
              />
            </div>
          </section>

          <section className="flex h-[4rem] w-full pl-[3.3rem]">
            <div
              className="flex group cursor-pointer justify-start "
              onClick={() => dispatch({ type: "LOGOUT" })}
            >
              <img src={logout} alt="" className="h-8 w-8" />
              <p className="text-red-300 group-hover:text-red-400">Logout</p>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default SideBar;
