import React, { useState } from "react";
import backarrow from "../../../Common/Assets/Images/backarrow.png";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import Notify from "../../../Helpers/Toast";

interface ContactDetailsProps {
  selectedPaymentId: string;
  momoOperator: string;
  input: string;
  fullname: string;
  setShowValidateForm: (value: boolean) => any;
  setShowNameDisplay: (value: boolean) => any;
}
function ContactDetails({
  selectedPaymentId,
  momoOperator,
  input,
  fullname,
  setShowValidateForm,
  setShowNameDisplay,
}: ContactDetailsProps) {
  const canGoBack = window.history.state.idx !== 0;
  const [error, setError] = useState("");

  const goBack = (e: any) => {
    setShowValidateForm(false);
    setShowNameDisplay(true);
  };

  const createAccount = () => {
    axiosInstance
      .post(`${Constants.API_BASE_URL}/payments/add/payment-method`, {
        accountNumber: input,
        accountName: fullname,
        paymentId: selectedPaymentId,
        makePayment: true,
      })
      .then((response) => {
        Notify("top-center", "Your account has been added successfully 🙂");

        setShowValidateForm(false);
        window.location.reload();
      })
      .catch((err) => {
        // console.log("error", err.response.data.message);
        setError(err.response.data.message);
        Notify("top-center", "Sorry, something went wrong, try again", "error");
      });
  };

  // console.log("momoOperator", momoOperator, selectedPaymentId, input, fullname);
  return (
    <div className="items-center justify-center min-h-full mx-auto">
      <div className=" flex flex-col items-start">
        <div className="lg:w-full w-[350px] flex justify-between  mt-6">
          <button
            onClick={(e) => goBack(e)}
            className={` flex cursor-pointer  focus:outline-none ${
              !canGoBack ? "opacity-50" : <> </>
            }`}
            disabled={!canGoBack}
          >
            {" "}
            <div>
              <img src={backarrow} alt="" className="h-3 w-4" />
            </div>
            <div className="ml-3 cursor-pointer text-[12px]">Back</div>
          </button>
        </div>
        <div className="text-[18px] font-semibold text-black mt-16">
          Confirm Details
        </div>
        <div className="text-[14px]  text-black text-left mt-2 text-nowrap">
          Please confirm that the mobile money account details below are
          correct.
        </div>
      </div>

      <div className="border p-4 rounded-[10px] mt-6">
        <div className="flex justify-between w-full border-b p-4">
          <div className="text-[16px]">Full Name</div>
          <div className="text-[16px]">{fullname}</div>
        </div>

        <div className="flex justify-between w-full border-b p-4">
          <div className="text-[16px]">Mobile Money Number</div>
          <div className="text-[16px]">{input}</div>
        </div>

        <div className="flex justify-between w-full p-4">
          <div className="text-[16px]">Mobile Money Operator</div>
          <div className="text-[16px]">{momoOperator}</div>
        </div>
      </div>
      {error && (
        <div className=" text-left text-red-500 text-[12px] mt-2">{error} </div>
      )}

      <div
        className="border bg-blue flex rounded-[10px] lg:px-4 py-4 mt-6 items-center justify-center w-full mb-4 cursor-pointer text-white"
        onClick={createAccount}
      >
        Continue
      </div>
    </div>
  );
}

export default ContactDetails;
