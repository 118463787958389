export const colors = {
  lightBlack: "rgba(0,0,0,.9)",
  black: "rgb(0,0,0)",
  theme: "#01A69F",
  themeLight: "#45E2E3",
  inActive: "#B8BED9",
  prevBlue: "#1A1E4C",
  blue: "#040406",

  bg: "#F2F8FF",
  btc: "#EF8E19",
  eth: "#636890",
  usdt: "#50AF95",
};
