import React, { useEffect, useState } from "react";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import check from "../../../Common/Assets/Images/check.png";
import ValidateMomo from "./ValidateMomo";
import backarrow from "../../../Common/Assets/Images/backarrow.png";

interface MomoProps {
  setShowMomo: (value: boolean) => any;
  setShowSecurity: (value: boolean) => any;
  showSecurity: boolean;
  showMomo: boolean;
}

function Momo({
  setShowMomo,
  showMomo,
  setShowSecurity,
  showSecurity,
}: MomoProps) {
  const [supportedPayments, setSupportedPayments] = useState<any>([]);
  const [selectedPayment, setSelectedPayment] = useState<string>("");
  const [showNameDisplay, setShowNameDisplay] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState<string>("");
  const [momoOperator, setMomoOperator] = useState<string>("");
  //eslint-disable-next-line
  const [momoScreen, setMomoScreen] = useState("momo");

  const handleClick = (value: string, id: string, operator: string) => {
    setSelectedPayment(value);
    setSelectedPaymentId(id);
    setMomoOperator(operator);
  };

  useEffect(() => {
    const getPaymentMethods = async () => {
      try {
        const response = await axiosInstance.get(
          `${Constants.API_BASE_URL}/payments/supported?type=MOMO`,
        );
        setSupportedPayments(response.data);
        // console.log("supportedPayments", supportedPayments);
      } catch (error) {
        // console.error("Error loading payment methods:", error);
      }
    };

    getPaymentMethods();
  }, []);

  const handleContinue = () => {
    setShowNameDisplay(true);
    // console.log("showname", showMomo);
  };

  const canGoBack = window.history.state.idx !== 0;

  const goBack = (e: any) => {
    if (momoScreen === "momo") {
      setShowMomo(false);
      setShowSecurity(true);
    }
  };

  const hasErrors = !selectedPayment;

  return (
    <div className="flex  items-center justify-center min-h-full mx-auto">
      {!showNameDisplay ? (
        <div className="flex flex-col items-start mx-auto">
          <div className="lg:w-full w-[350px] flex justify-between  mt-6">
            <button
              onClick={(e) => goBack(e)}
              className={` flex cursor-pointer  focus:outline-none ${
                !canGoBack ? "opacity-50" : <> </>
              }`}
              disabled={!canGoBack}
            >
              {" "}
              <div>
                <img src={backarrow} alt="" className="h-3 w-4" />
              </div>
              <div className="ml-3 cursor-pointer text-[12px]">Back</div>
            </button>
          </div>
          <div className="font-semibold text-[20px] text-left mt-16  ">
            Select Mobile Money operator
          </div>

          <div className="flex flex-col items-start w-[350px] lg:w-[540px] md:w-[540px] border-l border-r border-t rounded-[10px] pt-2 overflow-hidden overflow-x-visible mt-4 lg:mt-6 md:mt-2">
            <div className="border-b py-2 px-4 overflow-hidden w-full ">
              <li className="text-left">
                Please select your mobile money operator from the list below
              </li>
            </div>
            <div className="w-full">
              <ul className="list-none">
                {supportedPayments &&
                  supportedPayments.map((payment: any) => (
                    <div
                      key={payment.id}
                      className={`py-3 px-4 border-b ${
                        selectedPayment === payment.paymentTypeCode
                          ? "border border-theme last:rounded-b-[10px]"
                          : ""
                      }`}
                      onClick={() =>
                        handleClick(
                          payment.paymentTypeCode,
                          payment.id,
                          payment.name,
                        )
                      }
                      style={{
                        background: `${
                          selectedPayment === payment.paymentTypeCode
                            ? "rgb(0,80,255, .05)"
                            : "white"
                        }`,
                      }}
                    >
                      <div className="flex w-full cursor-pointer">
                        <div>
                          <img
                            src={payment.logoUrl}
                            alt=""
                            className="w-8 h-8 rounded-full"
                          />
                        </div>
                        <div className="ml-3 items-start flex flex-col">
                          <li className="text-left text-semibold text-[16px]">
                            {payment.name}
                          </li>
                        </div>
                        {selectedPayment === payment.paymentTypeCode && (
                          <img
                            src={check}
                            alt="Selected"
                            className="ml-auto h-6 w-6"
                          />
                        )}
                      </div>
                    </div>
                  ))}
              </ul>
            </div>
          </div>
          <div
            className={`border flex rounded-[10px] lg:px-4 py-4 mt-10 items-center justify-center w-full mb-4 cursor-pointer ${
              hasErrors
                ? "bg-gray-200 text-gray-300 cursor-not-allowed"
                : "bg-blue text-white"
            }
                `}
            onClick={handleContinue}
          >
            Continue
          </div>
        </div>
      ) : (
        <ValidateMomo
          selectedPaymentId={selectedPaymentId}
          momoOperator={momoOperator}
          setShowMomo={setShowMomo}
          showMomo={showMomo}
          setShowNameDisplay={setShowNameDisplay}
        />
      )}
    </div>
  );
}

export default Momo;
