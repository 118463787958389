import React, { useEffect, useState } from "react";
import backarrow from "../../../Common/Assets/Images/backarrow.png";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import Select from "react-select";
import { Field, Input, Label } from "@headlessui/react";
import clsx from "clsx";
import BankContactDetails from "./BankContactDetails";
import { zoomies } from "ldrs";

interface BankProps {
  setShowBank: (value: boolean) => any;
  setShowSecurity: (value: boolean) => any;
  showSecurity: boolean;
  showBank: boolean;
}

function Bank({
  showSecurity,
  setShowBank,
  setShowSecurity,
  showBank,
}: BankProps) {
  const [supportedPayments, setSupportedPayments] = useState<any>([]);
  const [accountNumberInput, setAccountNumberInput] = useState("");
  const [accountNameInput, setAccountNameInput] = useState("");
  //eslint-disable-next-line
  const [selectedPaymentId, setSelectedPaymentId] = useState<any>("");
  const [selectedBankId, setSelectedBankId] = useState<any>("");
  const [showValidateForm, setShowValidateForm] = useState(false);
  const [selectBankName, setSelectBankName] = useState("");
  const [bankScreen, setBankScreen] = useState("bank");
  const [verifyError, setVerifyError] = useState("");
  zoomies.register();

  const [isLoading, setIsLoading] = useState(false);

  const hasErrors = !accountNumberInput; // This will be true when accountNumberInput is empty

  // console.log(hasErrors);
  const paymentStyles = {
    control: (styles: any) => ({
      ...styles,
      border: "1px solid #E0E0E0",
      height: "53px",
      borderRadius: "10px",
      width: "350px",
      "@media (min-width: 1024px)": {
        width: "450px",
      },
    }),
  };

  const customTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "lightblue",
      primary: "#E5E4E2",
      textAlign: "left",
    },
  });

  const canGoBack = window.history.state.idx !== 0;

  const goBack = (e: any) => {
    if (bankScreen === "bank") {
      setBankScreen("security");
      setShowBank(false);
      setShowSecurity(true);
    }
  };

  useEffect(() => {
    const getPaymentMethods = async () => {
      try {
        const response = await axiosInstance.get(
          `${Constants.API_BASE_URL}/payments/supported?type=BANK`,
        );
        setSupportedPayments(
          response.data.map((payment: any) => ({
            value: payment.id,
            label: payment.name,
            image: payment.logoUrl,
          })),
        );
      } catch (error) {
        // console.error("Error loading payment methods:", error);
      }
    };

    getPaymentMethods();
  }, []);

  const verifyName = async () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${Constants.API_BASE_URL}/payments/account/query?accountNumber=${accountNumberInput}&paymentId=${selectedBankId}`,
      )
      .then((res) => {
        // console.log("response", res.data);
        setAccountNameInput(res.data.accountName);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log("error", err);
        setVerifyError("There is something wrong with the information entered");
        setIsLoading(false);
      });
  };

  const handlePayment = (e: any) => {
    const selectedPaymentId = e.value;
    setSelectedPaymentId(selectedPaymentId);
    const selectedPayment = supportedPayments.find(
      (payment: any) => payment.value === selectedPaymentId,
    );

    if (selectedPayment) {
      setSelectedBankId(selectedPayment.value);
      setSelectBankName(selectedPayment.label);
    }
  };

  const handleContinue = () => {
    verifyName();
    setShowValidateForm(true);
  };

  return (
    <>
      <div className="flex items-center justify-center min-h-full mx-auto">
        {!showValidateForm ? (
          <div className="flex flex-col items-start mx-auto">
            <div className="lg:w-full w-[350px] flex justify-between mt-6">
              <button
                onClick={(e) => goBack(e)}
                className={`flex cursor-pointer focus:outline-none ${
                  !canGoBack ? "opacity-50" : ""
                }`}
                disabled={!canGoBack}
              >
                <div>
                  <img src={backarrow} alt="Back" className="h-3 w-4" />
                </div>
                <div className="ml-3 cursor-pointer text-[12px]">Back</div>
              </button>
            </div>

            <div className="text-[16px] text-left mt-16">Bank Name</div>
            <div className="flex flex-col items-start w-[350px] lg:w-[540px] md:w-[540px] pt-2 mt-4 lg:mt-6 md:mt-2">
              <Select
                formatOptionLabel={(payment: any) => (
                  <div className="flex items-center">
                    {payment?.image && (
                      <img
                        src={payment.image}
                        className="w-8 h-8 rounded-full"
                        alt={payment.label}
                      />
                    )}
                    <span className="text-[14px] font-medium ml-2">
                      {payment?.label}
                    </span>
                  </div>
                )}
                options={supportedPayments}
                placeholder={
                  <div className="flex items-start">
                    <span className="text-[14px] font-medium ml-2 mt-1">
                      Select a Bank
                    </span>
                  </div>
                }
                styles={paymentStyles}
                theme={customTheme}
                isSearchable={false}
                blurInputOnSelect={false}
                onChange={handlePayment}
              />
            </div>

            <div className="w-full max-w-md">
              <Field>
                <Label className="text-[16px] font-medium text-black text-left flex items-start mt-4">
                  Account Number
                </Label>
                <div className="relative">
                  <Input
                    value={accountNumberInput}
                    onChange={(e: any) => {
                      setVerifyError("");
                      setAccountNumberInput(e.target.value);
                    }}
                    placeholder="Enter Bank Account Number"
                    className={clsx(
                      "mt-3 block w-full rounded-lg border border-gray-300 py-3 px-3 mx-1 text-sm/6 text-black",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
                    )}
                  />
                  <div
                    className={`!absolute right-1 top-1 z-10 select-none cursor-pointer rounded py-2 px-4 text-center align-middle font-sans text-xs font-bold mt-1 px-2 ${
                      hasErrors
                        ? "bg-gray-200 text-gray-300 cursor-not-allowed"
                        : "bg-blue text-white"
                    }`}
                    onClick={verifyName}
                  >
                    Verify
                  </div>

                  {isLoading && (
                    <div className="items-start">
                      <l-zoomies
                        size="150"
                        stroke="5"
                        bg-opacity="0.1"
                        speed="1.4"
                        color="black"
                      ></l-zoomies>
                    </div>
                  )}
                </div>
              </Field>
              {verifyError && (
                <div className="text-red-500 text-[12px] text-left mt-2 ">
                  {verifyError}
                </div>
              )}
              {accountNameInput && (
                <Field>
                  <Label className="text-[16px] font-medium text-black text-left flex items-start mt-4">
                    Account Name
                  </Label>
                  <div
                    className={clsx(
                      "mt-3 block w-full rounded-lg border border-gray-300 py-3 px-3 text-sm/6 text-black text-left",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
                    )}
                  >
                    {accountNameInput}
                  </div>
                </Field>
              )}
              {accountNameInput && (
                <div
                  className="border bg-blue flex rounded-[10px] lg:px-4 py-4 mt-6 items-center justify-center w-full mb-4 cursor-pointer text-white"
                  onClick={handleContinue}
                >
                  Continue
                </div>
              )}
            </div>
          </div>
        ) : (
          <BankContactDetails
            accountNameInput={accountNameInput}
            accountNumberInput={accountNumberInput}
            selectedBankId={selectedBankId}
            selectedBankName={selectBankName}
            setShowValidateForm={setShowValidateForm}
            setShowBank={setShowBank}
            showSecurity={showSecurity}
            setShowSecurity={setShowSecurity}
          />
        )}
      </div>
    </>
  );
}

export default Bank;
