import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import { dotSpinner } from "ldrs";
import {
  NoUsers,
  NoPoints,
} from "../../../Pages/Main/Components/SideBar/Assets/Icons";
import dayjs from "dayjs";

function ReferralTabs() {
  dotSpinner.register();

  const limit = 10;
  const [page, setPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [redeemedPage, setRedeemedPage] = useState(0);
  const [redeemedPageNumber, setRedeemedPageNumber] = useState(0);
  const categories = [{ name: "Referred Users" }, { name: "Redeemed Points" }];
  const fetchUserReferrals = async () => {
    const response = await axiosInstance.get(
      `${Constants.API_BASE_URL}/users/referrals?limit=${limit}&skip=${page}`,
    );
    return response.data;
  };
  const { data: referrals, isLoading: isLoadingReferrals } = useQuery({
    queryKey: ["referrals", page],
    queryFn: fetchUserReferrals,
  });
  const handlePrevPage = () => {
    setPage(page - limit);
    setPageNumber(pageNumber - 1);
  };

  const handleNextPage = () => {
    if (referrals?.hasMore) {
      setPage(page + limit);
      setPageNumber(pageNumber + 1);
    }
  };
  const handleRedeemedPrevPage = () => {
    setRedeemedPage(redeemedPage - limit);
    setRedeemedPageNumber(redeemedPageNumber - 1);
  };

  const handleRedeemedNextPage = () => {
    if (redeemedTransactions?.hasMore) {
      setRedeemedPage(redeemedPage + limit);
      setRedeemedPageNumber(redeemedPageNumber + 1);
    }
  };

  function getStatusColorClass(status: string) {
    switch (status) {
      case "waiting":
        return "inline-block bg-green-200 text-green-500 px-3 rounded-full text-sm align-middle ";
      case "confirming":
      case "pending":
        return "bg-cyan-200 text-cyan-500 inline-block align-middle  px-3 rounded-full text-sm";
      case "sending":
        return "bg-purple-200 inline-block align-middle  text-purple-500 px-3 rounded-full text-sm";
      case "completed":
        return "bg-green-200 inline-block align-middle  text-green-500 px-3 rounded-full text-sm";
      case "failed":
        return "bg-red-200 inline-block align-middle  text-red-500 px-3 rounded-full text-sm";
      case "refunded":
        return "bg-gray-200 inline-block align-middle text-gray-500 px-3 rounded-full text-sm";
      default:
        return ""; // Default style
    }
  }
  const fetchRedeemedTransactions = async () => {
    const response = await axiosInstance.get(
      `${Constants.API_BASE_URL}/users/redeem/transactions?limit=${limit}&skip=${redeemedPage}`,
    );
    return response.data;
  };
  const {
    data: redeemedTransactions,
    isLoading: isLoadingRedeemedTransactions,
  } = useQuery({
    queryKey: ["redeemedTransactions", redeemedPage],
    queryFn: fetchRedeemedTransactions,
  });

  return (
    <div className="w-full">
      <div className="flex w-full items-start pt-6">
        <div className="w-full ">
          <TabGroup>
            <TabList className="flex gap-4">
              {categories.map(({ name }) => (
                <Tab
                  key={name}
                  className="rounded-full py-1 px-3 text-sm/6 font-semibold text-black focus:outline-none data-[selected]:bg-black/10 data-[hover]:bg-black/5 data-[selected]:data-[hover]:bg-black/10 data-[focus]:outline-1 data-[focus]:outline-black"
                >
                  {name}
                </Tab>
              ))}
            </TabList>
            <TabPanels className="mt-3">
              {/* Referred Users */}
              <TabPanel>
                <div className="flex flex-col mt-6 items-start w-full">
                  {/* referral table */}
                  <div className="mt-8 w-full flex flex-col items-start relative h-[750px]">
                    <h2 className="text-lg font-semibold mb-4">
                      Referred Users
                    </h2>
                    <div className="overflow-x-auto mt-6 flex flex-col items-start w-full border border-gray-200 rounded-[10px] ">
                      <table className="w-full bg-white ">
                        <thead>
                          <tr className="bg-gray-100">
                            <th className="py-5 px-4 border-b text-left text-gray-500">
                              FULL NAME
                            </th>
                            <th className="py-5 px-4 border-b text-left text-gray-500">
                              REGISTRATION DATE
                            </th>
                            <th className="py-5 px-4 border-b text-left text-gray-500">
                              POINTS EARNED
                            </th>
                          </tr>
                        </thead>
                        {isLoadingReferrals ? (
                          <tbody className=" w-full ">
                            <td colSpan={3}>
                              <div className="mx-auto text-center my-auto">
                                <div className="pt-6">
                                  <l-dot-spinner
                                    size="80"
                                    speed="0.9"
                                    color="black"
                                  ></l-dot-spinner>
                                </div>
                                <div>
                                  <div className="mt-8">
                                    <h2 className="p-8">Loading...</h2>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tbody>
                        ) : referrals?.data?.length > 0 ? (
                          <tbody className="">
                            {referrals?.data?.map(
                              (referral: any, index: any) => (
                                <tr key={index}>
                                  <td className="py-4 px-4 border-b text-left">
                                    {referral.firstName} {referral.lastName}
                                  </td>
                                  <td className="py-4 px-4 border-b text-left">
                                    {dayjs(referral.createdAt).format(
                                      "MMM D, YYYY h:mm A",
                                    )}
                                  </td>
                                  <td className="py-4 px-4 border-b text-left">
                                    {referral.firstOrder === true
                                      ? "500 "
                                      : "0 "}
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        ) : (
                          <tbody className=" h-[550px]">
                            <tr>
                              <td colSpan={3} className=" ">
                                <div className="flex flex-col items-center justify-center text-center">
                                  <div>
                                    {" "}
                                    <img
                                      src={NoUsers}
                                      className="w-[70px] h-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="mt-4 text-gray-600">
                                    {" "}
                                    You have no referrals yet
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div className="flex justify-between w-full p-4 bg-white absolute bottom-0 inset-x-0">
                      <button
                        onClick={handlePrevPage}
                        disabled={pageNumber === 0}
                        className="flex min-w-[90px] cursor-pointer focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
                      >
                        <i className="fa fa-arrow-left mr-2"></i>
                        <p>Previous</p>
                      </button>
                      <span className="mx-4 text-medium font-medium">
                        Page {pageNumber + 1}
                      </span>
                      <button
                        onClick={handleNextPage}
                        disabled={!referrals?.hasMore}
                        className="flex cursor-pointer focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
                      >
                        <p>Next</p>
                        <i className="fa fa-arrow-right ml-2"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </TabPanel>

              {/* Redeemed Points */}
              <TabPanel>
                <div className="flex flex-col mt-6 items-start w-full">
                  <div className="mt-8 w-full flex flex-col items-start relative h-[750px]">
                    {/* Add content for Redeemed Points here */}
                    <h2 className="text-lg font-semibold mb-4">
                      Redeemend Points
                    </h2>
                    <div className="overflow-x-auto mt-6 flex flex-col items-start w-full border border-gray-200 rounded-[10px] ">
                      <table className="w-full bg-white ">
                        <thead>
                          <tr className="bg-gray-100">
                            <th className="py-5 px-4 border-b text-left text-gray-500">
                              AMOUNT
                            </th>
                            <th className="py-5 px-4 border-b text-left text-gray-500">
                              STATUS
                            </th>
                          </tr>
                        </thead>
                        {isLoadingRedeemedTransactions ? (
                          <tbody className=" w-full ">
                            <td colSpan={3}>
                              <div className="mx-auto text-center my-auto">
                                <div className="pt-6">
                                  <l-dot-spinner
                                    size="80"
                                    speed="0.9"
                                    color="black"
                                  ></l-dot-spinner>
                                </div>
                                <div>
                                  <div className="mt-8">
                                    <h2 className="p-8">Loading...</h2>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tbody>
                        ) : redeemedTransactions?.data?.length > 0 ? (
                          <tbody className="">
                            {redeemedTransactions?.data?.map(
                              (redeemedTransaction: any, index: any) => (
                                <tr key={index}>
                                  <td className="py-4 px-4 border-b text-left">
                                    {redeemedTransaction.amount}
                                  </td>
                                  <td className="py-4 px-4 border-b text-left">
                                    <span
                                      className={getStatusColorClass(
                                        redeemedTransaction.transactionStatus,
                                      )}
                                    >
                                      {redeemedTransaction.transactionStatus}
                                    </span>
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        ) : (
                          <tbody className=" h-[550px]">
                            <tr>
                              <td colSpan={3} className=" ">
                                <div className="flex flex-col items-center justify-center text-center">
                                  <div>
                                    {" "}
                                    <img
                                      src={NoPoints}
                                      className="w-[70px] h-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="mt-4 text-gray-600">
                                    {" "}
                                    No redeemed points yet
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>

                    <div className="flex justify-between w-full p-4 bg-white absolute bottom-0 inset-x-0">
                      <button
                        onClick={handleRedeemedPrevPage}
                        disabled={redeemedPageNumber === 0}
                        className="flex min-w-[90px] cursor-pointer focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
                      >
                        <i className="fa fa-arrow-left mr-2"></i>
                        <p>Previous</p>
                      </button>
                      <span className="mx-4 text-medium font-medium">
                        Page {redeemedPageNumber + 1}
                      </span>
                      <button
                        onClick={handleRedeemedNextPage}
                        disabled={!redeemedTransactions?.hasMore}
                        className="flex cursor-pointer focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
                      >
                        <p>Next</p>
                        <i className="fa fa-arrow-right ml-2"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </div>
    </div>
  );
}

export default ReferralTabs;
