import React, { useEffect, useState } from "react";
import Button from "../../../../../Common/Components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../../setup/axios";
import Constants from "../../../../../Utils/Constants";
import KycOne from "./components/KycOne";
import PageWrapper from "../../../Components/Wrapper/Wrapper";
import KycThree from "./components/KycThree";

function KycPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    name: "",
    step: 1,
    userInfo: {
      firstName: "",
      lastName: "",
      phone: "",
      gender: "",
      dob: "",
      occupation: "",
      state: "",
      city: "",
    },
  });

  useEffect(() => {
    switch (params.step) {
      case "1":
        setState({ ...state, step: 1 });
        break;
      case "2":
        setState({ ...state, step: 2 });
        break;
      case "3":
        setState({ ...state, step: 3 });
        break;
      case "4":
        setState({ ...state, step: 4 });
        break;
      default:
        setState({ ...state, step: 1 });
        break;
    }
    // eslint-disable-next-line
  }, [params.step]);
  useEffect(() => {
    return () => {
      axiosInstance
        .get(`${Constants.API_BASE_URL}/users/me`)
        .then((res) => {
          let userInfo = {
            firstName: res.data.user.firstName,
            lastName: res.data.user.lastName,
            gender: res.data.user.gender,
            dob: res.data.user.dateOfBirth,
            phone: res.data.phoneIdentity.phone,
            city: res.data.user.city,
            state: res.data.user.state,
            address: res.data.user.address,
          };
          try {
            setState((prev) => {
              return { ...prev, userInfo: { ...prev.userInfo, ...userInfo } };
            });
          } catch (e) {
            return;
          }
        })
        .catch(() => false);
    };
  }, []);

  const Content = () => {
    return (
      <div className="mt-10 w-full px-[1rem]">
        <h1 className="font-semibold text-2xl md:text-2xl text-left">
          KYC Verification
        </h1>

        <div className="flex mt-5 mb-[6rem] w-[90%] md:w-[700px] m-auto justify-between">
          <aside
            onClick={() => {}}
            className={`relative rounded-full min-w-[30px] min-h-[30px] flex j-c-c a-i-c ${state.step > 1 ? "bg-theme" : "border-2"} ${state.step === 1 && "border-theme text-theme font-semibold"}`}
          >
            {state.step > 1 ? <i className="fa fa-check text-white"></i> : 1}
            <small
              className={`absolute -bottom-[43px] md:-bottom-[35px] min-w-[100px] md:min-w-[150px] ${state.step > 1 && "text-theme"}`}
            >
              Personal Details
            </small>
          </aside>
          <hr className={`w-full p-[1px] ${state.step > 1 && "bg-theme"}`} />

          <aside
            onClick={() => {}}
            className={`relative rounded-full min-w-[30px] min-h-[30px] flex j-c-c a-i-c ${state.step > 2 ? "bg-theme" : "border-2"} ${state.step === 2 && "border-theme text-theme font-semibold"}`}
          >
            {state.step > 2 ? <i className="fa fa-check text-white"></i> : 2}
            <small
              className={`absolute -bottom-[33px] md:-bottom-[35px] min-w-[100px] md:min-w-[150px] ${state.step > 2 && "text-theme"}`}
            >
              Documents Upload
            </small>
          </aside>
          <hr className={`w-full p-[1px] ${state.step > 3 && "bg-theme"}`} />

          <aside
            onClick={() => {}}
            className={`relative rounded-full min-w-[30px] min-h-[30px] flex j-c-c a-i-c ${state.step === 3 ? "bg-theme" : "border-2"} ${state.step === 3 && "border-theme text-theme font-semibold"}`}
          >
            <small
              className={`${state.step === 3 ? "text-white" : "text-black"}`}
            >
              3
            </small>
            <small
              className={`absolute -bottom-[43px] md:-bottom-[35px] min-w-[100px] md:min-w-[150px] ${state.step === 3 && "text-theme"}`}
            >
              DONE
            </small>
          </aside>
        </div>

        {state.step === 1 && (
          <KycOne
            userInfo={state.userInfo}
            setStep={(step: number, data: any) =>
              setState({
                ...state,
                step,
                userInfo: { ...state.userInfo, ...data },
              })
            }
          />
        )}
        {state.step === 2 && <KycThree />}
        {state.step > 2 && (
          <div className="w-full md:w-[700px] mt-5">
            <h1 className="font-semibold text-xl text-left">Done</h1>

            <div className="flex flex-col items-start w-full mt-8">
              <p className="text-left">
                Your KYC is pending approval. This can take up to a minimum of
                24 hours. Check back later for your status in{" "}
                <b className="text-theme">Profile</b> tab.
              </p>
            </div>
            <div className="w-full mt-8">
              <Button
                text={"Finish"}
                onClick={() => {
                  navigate("/dashboard", { replace: true });
                }}
                className={`bg-blue text-white`}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  return <PageWrapper content={<Content />} />;
}
export default KycPage;
