import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import axios from "axios";
import Constants from "../../../../Utils/Constants";
import AuthService from "../../../../setup/AuthServices";

import style from "./style.module.css";
import { bitcoin, teather } from "../../../../Common/Assets/Icons";
import ModalLoading from "../../../../Common/Components/Modal/ModalLoading";
import axiosInstance from "../../../../setup/axios";
import Notify from "../../../../Helpers/Toast";
import { orderInfoData } from "./types";
import { copyText } from "../../../../setup/Validate";
import Context from "../../../../store/Contex";

type props = {
  orderInfo: orderInfoData;
  close?: () => void;
};

function InfoOrder({ orderInfo, close }: props) {
  const [state, setState] = useState({
    hasPaymentMethod: false,
    isLoading: false,
    isRequesting: false,
    orderInfo: {
      id: "",
      type: "",
      asset: "",
      currency: "",
      amount: 0,
      status: "",
      fee: 0,
      feeType: "",
      rate: 0,
      payment_type: "",
      cryptoAmount: "",
      receiving_address: "",
      payout_type: "",
      payout_account_type: "",
      payout_account_number: "",
      payout_account_name: "",
      payout_account_branch: "",
      payment_transaction_number: "",
      transactionId: "",
      transaction: {
        walletAddress: "",
      },
      paymentMethod: {
        network: "",
        branch: "",
        accountNumber: "",
        accountName: "",
        type: "",
        payout_type_name: "",
      },
    },
    transactionNumber: "",
    errorMessage: "",
    successMessage: "",
    copied: false,
    orderUpdated: false,
    paymentMethod: {
      network: "",
      branch: "",
      accountNumber: "",
      accountName: "",
      type: "",
      payout_type_name: "",
    },
    showNewPayout: false,
    buyOrderConfirm: {
      accountName: "",
      accountNumber: "",
      orderId: "",
      networkTransactionId: "",
    },
    HodlpayAccount: {
      AccountNumber: "",
      AccountName: "",
    },
    error: {
      type: "",
      text: "",
    },
  });
  const dispatch = useContext(Context).dispatch;
  const paymentMethod = useContext(Context).state.userInfo.paymentMethod;

  useEffect(() => {
    return () => {
      if (state.copied) {
        setTimeout(() => {
          setState({
            ...state,
            copied: false,
          });
        }, 5000);
      }
    };
    // eslint-disable-next-line
  }, [state.copied]);
  function updateForm(event: ChangeEvent<HTMLInputElement>, name: string) {
    let value = event.target.value;
    if (name === "accountName") {
      setState({
        ...state,
        buyOrderConfirm: { ...state.buyOrderConfirm, accountName: value },
      });
    } else if (name === "accountNumber") {
      setState({
        ...state,
        buyOrderConfirm: { ...state.buyOrderConfirm, accountNumber: value },
      });
    } else if (name === "networkTransactionId") {
      setState({
        ...state,
        transactionNumber: value,
        buyOrderConfirm: {
          ...state.buyOrderConfirm,
          networkTransactionId: value,
        },
      });
    }
  }

  function submitBuyOrderForm() {
    setState({
      ...state,
      isRequesting: true,
      errorMessage: "",
      successMessage: "",
    });
    const data = {
      ...state.buyOrderConfirm,
      accountName: state.HodlpayAccount.AccountName,
      accountNumber: state.HodlpayAccount.AccountNumber,
      orderId: orderInfo.id,
    };
    axiosInstance
      .post(Constants.API_BASE_URL + "/users/orders/payment/confirm", data)
      .then((res) => {
        setState({
          ...state,
          isRequesting: false,
          orderUpdated: true,
          orderInfo: orderInfo,
        });
        Notify("top-center", "Transaction Confirmed", "success");
        // dispatch({type: 'TURNOFF'})
        close && close();
      })
      .catch((e) => {
        Notify("top-center", "Confirmation Failed", "error");
        try {
          setState({
            ...state,
            isRequesting: false,
            errorMessage: e.response.data.message[0],
          });
        } catch (e) {}
      });
  }

  function getOrderInfo() {
    let data = { ...orderInfo } || { id: "" };
    setState((prev) => {
      return {
        ...prev,
        orderInfo: { ...prev.orderInfo, ...data },
        buyOrderConfirm: { ...prev.buyOrderConfirm, orderId: data.id ?? "" },
        isLoading: true,
      };
    });
  }

  function getUserPaymentMethod() {
    setState({
      ...state,
      isLoading: true,
      isRequesting: true,
    });
    axiosInstance
      .get(`${Constants.API_BASE_URL}/users/payment/method`)
      .then((res) => {
        if (res.data) {
          setState((prev) => {
            return {
              ...prev,
              isLoading: false,
              isRequesting: false,
              hasPaymentMethod: true,
              paymentMethod: {
                ...res.data,
                accountName: res.data?.accountName,
                network: res.data?.network,
                accountNumber: res.data?.phoneNumber,
              },
              buyOrderConfirm: {
                ...state.buyOrderConfirm,
                accountNumber: res.data?.phoneNumber,
                accountName: res.data?.accountName,
              },
            };
          });

          axiosInstance
            .get(`${Constants.API_BASE_URL}/company/payment/method`)
            .then((res) => {
              const hodlpayAccount = res.data[0];
              setState((prev) => {
                return {
                  ...prev,
                  HodlpayAccount: {
                    ...state.HodlpayAccount,
                    AccountName: hodlpayAccount?.accountName,
                    AccountNumber: hodlpayAccount?.phoneNumber,
                  },
                };
              });
            })
            .catch((e) => {
              setState({ ...state, showNewPayout: false });
            });
        } else {
          setState({
            ...state,
            isLoading: false,
            hasPaymentMethod: false,
          });
        }
      })
      .catch((err) => {
        setState({
          ...state,
          isLoading: false,
        });
        if (err.response.status === 401) {
        }
      });
  }

  useEffect(() => {
    getUserPaymentMethod();

    return () => {
      getUserPaymentMethod();

      getOrderInfo();
    };
    // eslint-disable-next-line
  }, []);

  function cancelOrder(id: string) {
    if (window.confirm("Are you sure you want to cancel this order")) {
      let data = {
        orderId: id,
      };
      // console.log("headers:", AuthService.getAxiosHeaders());
      setState({ ...state, isRequesting: true });
      axios
        .post(
          `${Constants.API_BASE_URL}/orders/cancel`,
          data,
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          setState({ ...state, isRequesting: false });
          window.location.reload();
        })
        .catch((err) => {
          setState({ ...state, isRequesting: false });
        });
    }
  }

  return (
    <div className={`${style.order} max-sm:px-0`} style={{ minWidth: "400px" }}>
      <div className="modal-body flex flex-col scroll-y mx-5 pt-0 pb-14">
        {state.isLoading || state.isRequesting ? (
          <ModalLoading />
        ) : (
          <>
            <div className="text-center mb-13">
              <h1 className="mb-3 text-2xl text-theme font-semibold">
                {`${orderInfo?.type
                  ?.slice(0, 1)
                  ?.toUpperCase()}${orderInfo?.type?.slice(1)}`}{" "}
                Order Details
              </h1>
            </div>

            <div
              id="kt_modal_users_search_handler"
              data-kt-search-keypress="true"
              data-kt-search-min-length="2"
              data-kt-search-enter="enter"
              data-kt-search-layout="inline"
              data-kt-search="true"
            >
              <div className="py-5">
                {orderInfo.type === "buy" ? (
                  <div className={`${style.order}`}>
                    <div className="flex flex-col my-9">
                      {orderInfo.asset === "BTC" ? (
                        <div className="w-12 h-12 flex j-c-c rounded-full bg-btc">
                          <img src={bitcoin} className="" alt="" />
                        </div>
                      ) : (
                        <div className="w-12 h-12 flex j-c-c rounded-full bg-usdt">
                          <img src={teather} className="" alt="" />
                        </div>
                      )}
                      <div>
                        <div className="my-3 text-2xl font-bold">
                          ${orderInfo?.amount}
                        </div>
                        <div className="fs-6 fw-bold">
                          Wallet Address:{" "}
                          <b className="text-theme">
                            {orderInfo?.transaction?.walletAddress ?? ""}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex flex-col ">
                      <div
                        className={`flex j-c-c w-7 h-7 rounded-full bg-[#ffdd8d]`}
                      >
                        <i className="fa fa-info text-sm text-orange-600"></i>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="my-2">{`${orderInfo.status}`}</span>
                        <div className="fs-7 text-muted">
                          Order ID: <b>{orderInfo.id}</b>
                        </div>
                      </div>
                    </div>
                    {state.errorMessage.length > 0 && (
                      <div className="alert alert-danger flex flex-col items-center p-5 mb-10 w-100">
                        <span className="svg-icon svg-icon-2hx svg-icon-danger">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle
                                fill="#000000"
                                opacity="0.3"
                                cx="12"
                                cy="12"
                                r="10"
                              />
                              <rect
                                fill="#000000"
                                x="11"
                                y="10"
                                width="2"
                                height="7"
                                rx="1"
                              />
                              <rect
                                fill="#000000"
                                x="11"
                                y="7"
                                width="2"
                                height="2"
                                rx="1"
                              />
                            </g>
                          </svg>
                        </span>
                        <div className="d-flex flex-column text-red-600">
                          <h4 className="mb-1 text-danger">Error</h4>{" "}
                          <span>{state.errorMessage}</span>{" "}
                        </div>
                      </div>
                    )}
                    <div className="fv-row mt-8">
                      <div className="summary mt-10 p-7">
                        <div className="w-full">
                          <div className="border-b border-dashed w-full flex justify-between py-2 ">
                            <p>Buy Amount</p>
                            <p>${orderInfo.amount}</p>
                          </div>
                          <div className="border-b border-dashed w-full flex justify-between py-2 ">
                            <div>
                              Fees{" "}
                              <small className="text-muted">
                                ({orderInfo.feeType} priority)
                              </small>
                            </div>
                            <p>${orderInfo.fee}</p>
                          </div>
                          <div className="border-b border-dashed w-full flex justify-between py-2 ">
                            <p>Rate</p>
                            <p>GHS {orderInfo.rate}</p>
                          </div>
                          <div className="border-b border-dashed w-full flex justify-between py-2 ">
                            <p>Crypto Amount</p>
                            <p>
                              {orderInfo.cryptoAmount} {orderInfo.asset}
                            </p>
                          </div>
                          <div className="border-0 border-dashed w-full flex justify-between py-2 ">
                            <p>Order ID</p>
                            <p>{orderInfo.id}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(orderInfo.status === "pending_payment" ||
                      orderInfo?.status === "pending") && (
                      <>
                        <div
                          className="w-full rounded-lg bg-[#3eecff1d] p-3 flex flex-col items-start"
                          style={{ borderRadius: "15px" }}
                        >
                          <div className="form-label-group">
                            <label className="form-label">
                              Send payment to:
                            </label>
                          </div>
                          <label className="d-flex flex-stack mb-5 cursor-pointer">
                            <span className="flex my-2">
                              <span className="symbol symbol-20px me-6">
                                <i className="fa fa-mobile text-xl text-theme"></i>
                              </span>
                              <span className="flex flex-col items-start">
                                <span className="fw-bolder fs-6">
                                  {state.paymentMethod.network}
                                </span>
                                <span className="fs-7 text-gray-400">
                                  Account Number:{" "}
                                  <b className="text-gray-800">0245542760</b>
                                </span>
                                <span className="fs-7 text-gray-400">
                                  Account Name:{" "}
                                  <b className="text-gray-800">
                                    Hodlpay Limited
                                  </b>
                                </span>
                              </span>
                            </span>
                            <small>
                              Send the sum of{" "}
                              <b
                                className="text-theme"
                                style={{ fontSize: "1.2rem" }}
                              >
                                GHS{" "}
                                {(
                                  orderInfo.fee * orderInfo.rate +
                                  orderInfo.amount * orderInfo.rate
                                ).toFixed(2)}
                              </b>{" "}
                              to the account details above
                            </small>
                          </label>
                          <div className="my-4 flex flex-col items-start w-full">
                            <label className="form-label">
                              Network Transaction Id:
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2 w-full h-[50px] px-3 rounded-lg border-theme border"
                              value={
                                state?.buyOrderConfirm.networkTransactionId
                              }
                              onChange={(e) => {
                                updateForm(e, "networkTransactionId");
                              }}
                              placeholder="Enter your transaction id"
                            />
                            <br />
                          </div>
                        </div>
                        <div className="">
                          {state.isRequesting ? (
                            <div className="w-full flex max-sm:flex-col j-c-c mt-3">
                              <button
                                type="button"
                                className="btn bg-[#1a72a148] text-white w-[150px] h-[50px] me-2"
                                disabled
                              >
                                <span className="">
                                  Please wait...
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              </button>
                              <button
                                className="btn bg-red-100 text-white w-[150px] h-[50px] me-2"
                                disabled
                              >
                                <span className="indicator-label">Cancel</span>
                              </button>
                              <button
                                className="btn bg-gray-100 text-gray-400 w-[150px] h-[50px]"
                                disabled
                              >
                                <span className="indicator-label">Close</span>
                              </button>
                            </div>
                          ) : (
                            <div className="w-full flex max-sm:flex-col j-c-c mt-3">
                              <button
                                onClick={() => {
                                  submitBuyOrderForm();
                                }}
                                className="btn bg-theme text-white max-sm:w-[100%] w-[150px] h-[50px] max-sm:my-2 max-sm:mx-0 me-2"
                              >
                                <span className="indicator-label">Submit</span>
                              </button>
                              <button
                                onClick={() => {
                                  cancelOrder(orderInfo.id);
                                }}
                                className="btn bg-red-400 text-white max-sm:w-[100%] w-[150px] h-[50px] max-sm:my-2 max-sm:mx-0 me-2"
                              >
                                <span className="indicator-label">Cancel</span>
                              </button>
                              <button
                                className="btn bg-gray-300 text-gray-800 max-sm:w-[100%] w-[150px] h-[50px] max-sm:my-2 max-sm:mx-0"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  close
                                    ? close()
                                    : dispatch({ type: "TURNOFF" });
                                }}
                              >
                                <span className="indicator-label">Close</span>
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    {(orderInfo?.status === "approved" ||
                      orderInfo?.status === "cancelled" ||
                      orderInfo?.status === "refunded") && (
                      <>
                        <div
                          className="pm_box mb-10 bg-[#3eecff1d] flex flex-col items-start p-10"
                          style={{ borderRadius: "15px" }}
                        >
                          <div className="form-label-group">
                            <label className="form-label">
                              Payment sent to:
                            </label>
                          </div>
                          <label className="d-flex flex-stack mb-5 cursor-pointer">
                            <span className="flex ">
                              <span className="symbol symbol-20px me-6">
                                <i className="fa fa-mobile text-xl text-theme"></i>
                              </span>
                              <span className="flex flex-col items-start">
                                <span className="fw-bolder fs-6">
                                  {orderInfo.payment_type}
                                </span>
                                <span className="fs-7 text-muted">
                                  Account Number:{" "}
                                  <b>{state.HodlpayAccount.AccountNumber}</b>
                                </span>
                                <span className="fs-7 text-muted">
                                  Account Name:{" "}
                                  <b>{state.HodlpayAccount.AccountName}</b>
                                </span>
                              </span>
                            </span>
                          </label>
                          <div className="w-full flex">
                            <label className="form-label">
                              Payment Transaction Number:
                            </label>
                            <div className="fs-7 text-muted">
                              <b>
                                adfasdfa {orderInfo.payment_transaction_number}
                              </b>
                            </div>
                          </div>
                          <br />
                          <div className="w-full flex">
                            <label className="form-label">
                              Transaction Hash:
                            </label>
                            <div className="fs-7 text-muted">
                              <b> {orderInfo?.transactionId}</b>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-center">
                          <button
                            className="w-[200px] h-[50px] text-center bg-gray-300 text-gray-800 text-lg rounded-md"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              close ? close() : dispatch({ type: "TURNOFF" });
                            }}
                          >
                            <span className="indicator-label">Close</span>
                          </button>
                        </div>
                      </>
                    )}

                    <div className="my-10 text-center">
                      <span className="fs-6 fw-bold text-gray-400">Total</span>
                      <div className="d-flex align-items-center mb-2 justify-content-center">
                        <span className="fs-4 fw-bold text-gray-400 align-self-start me-2 pr-5>">
                          GHS
                        </span>
                        <span className="fs-2hx text-3xl font-semibold fw-bolder text-gray-800 me-2 lh-1">
                          {(
                            orderInfo.fee * orderInfo.rate +
                            orderInfo.amount * orderInfo.rate
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  // Sell Starts Here ....
                  <div className="sell_order_info order">
                    <div className="flex flex-col my-9 w-fit mx-auto">
                      {orderInfo?.asset === "BTC" ? (
                        <div className="w-12 h-12 flex j-c-c rounded-full bg-btc">
                          <img src={bitcoin} className="" alt="" />
                        </div>
                      ) : (
                        <div className="w-12 h-12 flex j-c-c rounded-full bg-usdt">
                          <img src={teather} className="" alt="" />
                        </div>
                      )}
                      <div>
                        <div className="my-3 text-2xl font-bold">
                          ${orderInfo?.amount}
                        </div>
                      </div>
                    </div>

                    <div className="w-fit mx-auto flex flex-col">
                      <div
                        className={`flex j-c-c w-7 h-7 rounded-full bg-[#ffdd8d]`}
                      >
                        <i className="fa fa-info text-sm text-orange-600"></i>
                      </div>
                      <div className="flex flex-col">
                        <span className="my-2">{`${orderInfo.status}`}</span>
                        <div className="text-gray-500">
                          Order ID: <br />
                          <b className="text-theme">{orderInfo.id}</b>
                        </div>
                      </div>
                    </div>

                    {state.errorMessage.length > 0 && (
                      <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100">
                        <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle
                                fill="#000000"
                                opacity="0.3"
                                cx="12"
                                cy="12"
                                r="10"
                              />
                              <rect
                                fill="#000000"
                                x="11"
                                y="10"
                                width="2"
                                height="7"
                                rx="1"
                              />
                              <rect
                                fill="#000000"
                                x="11"
                                y="7"
                                width="2"
                                height="2"
                                rx="1"
                              />
                            </g>
                          </svg>
                        </span>
                        <div className="d-flex flex-column">
                          <h4 className="mb-1 text-danger">Error</h4>{" "}
                          <span>{state.errorMessage}</span>{" "}
                        </div>
                      </div>
                    )}

                    <div className="mt-8">
                      <div className="mt-10 px-1">
                        <div className="w-full flex flex-col">
                          <div className="border-b border-dashed w-full flex justify-between py-2 ">
                            <p>
                              {`${orderInfo.type
                                ?.slice(0, 1)
                                .toUpperCase()}${orderInfo.type?.slice(
                                1,
                              )}`}{" "}
                              Amount
                            </p>
                            <p>${orderInfo.amount}</p>
                          </div>
                          <div className="border-b border-dashed w-full flex justify-between py-2 ">
                            <div>
                              Fees{" "}
                              <small className="text-muted">
                                ({orderInfo.feeType} priority)
                              </small>
                            </div>
                            <p>${orderInfo.fee}</p>
                          </div>
                          <div className="border-b border-dashed w-full flex justify-between py-2 ">
                            <p>Rate</p>
                            <p>GHS {orderInfo.rate}</p>
                          </div>
                          <div className="border-b border-dashed w-full flex justify-between py-2 ">
                            <p>Crypto Amount</p>
                            <p>
                              {orderInfo.cryptoAmount} {orderInfo.asset}
                            </p>
                          </div>
                          <div className="border-0 border-dashed w-full flex justify-between py-2 ">
                            <p>Order ID</p>
                            <p>{orderInfo.id}</p>
                          </div>
                        </div>

                        <div className="my-10 text-center">
                          <span className="text-gray-400">Payout Amount</span>
                          <div className="d-flex align-items-center mb-2 justify-content-center">
                            <span className="fs-4 fw-bold text-gray-400 align-self-start me-2 pr-5>">
                              GHS
                            </span>
                            <span className="fs-2hx text-3xl font-semibold fw-bolder text-gray-800 me-2 lh-1">
                              {(orderInfo.amount * orderInfo.rate)?.toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {orderInfo?.status?.includes("waiting") && (
                      <>
                        <div
                          className="w-full rounded-lg bg-[#3eecff1d] p-4 flex flex-col items-start"
                          style={{ borderRadius: "15px" }}
                        >
                          <div className="form-label-group">
                            <label className="form-label">
                              Send Transfer to:
                            </label>
                          </div>
                          <div className="my-4">
                            <span className="flex flex-col items-start me-2">
                              <span className="flex">
                                {orderInfo?.asset === "BTC" ? (
                                  <div className="rounded-lg w-7 h-7 flex j-c-c bg-btc">
                                    <img src={bitcoin} alt="" />
                                  </div>
                                ) : (
                                  <div className="rounded-lg w-7 h-7 flex j-c-c bg-usdt">
                                    <img src={teather} alt="" />
                                  </div>
                                )}
                                <span className=" ml-2">
                                  {orderInfo?.asset} Wallet
                                </span>
                              </span>
                              <span className="flex flex-col items-start">
                                <div className="flex flex-col items-start">
                                  <br />
                                  <p>Transaction Address</p>
                                  <div
                                    id="wallet_address"
                                    className="text-gray-400 text-[12px]"
                                  >
                                    {orderInfo?.transaction.walletAddress}
                                  </div>

                                  <div
                                    className="flex mt-3 cursor-pointer border border-theme text-theme px-4 py-1 rounded-md"
                                    onClick={() => {
                                      copyText(
                                        orderInfo?.transaction.walletAddress,
                                      );
                                      setState({ ...state, copied: true });
                                    }}
                                  >
                                    {state.copied ? `Copied` : `Copy Address`}
                                  </div>
                                </div>
                              </span>
                            </span>
                          </div>

                          <br />
                          {JSON.stringify(orderInfo.paymentMethod)}
                          <div className="flex flex-col items-start w-full">
                            <label className="form-label w-full text-center">
                              Payout Account:
                            </label>
                            <span className="flex flex-col items-start w-full">
                              <div className="w-full flex j-c-b py-2 border-b border-dashed">
                                <p>Account Type</p>
                                <p>
                                  {paymentMethod?.network} -{" "}
                                  <b>
                                    {paymentMethod?.accountType
                                      ?.slice(0, 1)
                                      ?.toUpperCase()}
                                    {paymentMethod?.accountType?.slice(1)}
                                  </b>
                                </p>
                              </div>
                              <div className="w-full flex j-c-b py-2 border-b border-dashed">
                                <p>Account Number</p>
                                <p>
                                  {paymentMethod.accountNumber ||
                                    paymentMethod.phoneNumber}
                                </p>
                              </div>
                              <div className="w-full flex j-c-b py-2 ">
                                <p>Account Name</p>
                                <p>{paymentMethod.accountName}</p>
                              </div>
                              {paymentMethod?.branch && (
                                <span className="fs-7 text-muted">
                                  Account Branch: <b>{paymentMethod?.branch}</b>
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="flex j-c-c mt-4">
                          <button
                            className="btn bg-gray-300 text-gray-800 text-lg w-[150px] h-[50px] rounded-lg"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              close ? close() : dispatch({ type: "TURNOFF" });
                            }}
                          >
                            <span className="indicator-label">Close</span>
                          </button>
                        </div>
                      </>
                    )}

                    {(orderInfo?.status === "pending_verification" ||
                      orderInfo?.status === "pending_payout" ||
                      orderInfo?.status === "pending") && (
                      <div
                        className="w-full rounded-lg bg-[#3eecff1d] p-4 flex flex-col items-start"
                        style={{ borderRadius: "15px" }}
                      >
                        <div className="form-label-group">
                          <label className="form-label">
                            Transfer sent to:
                          </label>
                        </div>
                        <div className="my-4">
                          <span className="flex flex-col items-start me-2">
                            <span className="flex">
                              {orderInfo?.asset === "BTC" ? (
                                <div className="rounded-lg w-7 h-7 flex j-c-c bg-btc">
                                  <img src={bitcoin} alt="" />
                                </div>
                              ) : (
                                <div className="rounded-lg w-7 h-7 flex j-c-c bg-usdt">
                                  <img src={teather} alt="" />
                                </div>
                              )}
                              <span className=" ml-2">
                                {orderInfo?.asset} Wallet
                              </span>
                            </span>
                            <span className="flex flex-col items-start">
                              <div className="flex flex-col items-start">
                                <br />
                                <p>Transaction Address</p>
                                <div
                                  id="wallet_address"
                                  className="text-gray-400 text-[12px]"
                                >
                                  {orderInfo?.transaction.walletAddress}
                                </div>

                                <div
                                  className="flex mt-3 cursor-pointer border border-theme text-theme px-4 py-1 rounded-md"
                                  onClick={() => {
                                    copyText(
                                      orderInfo?.transaction.walletAddress,
                                    );
                                    setState({ ...state, copied: true });
                                  }}
                                >
                                  {state.copied ? `Copied` : `Copy Address`}
                                </div>
                              </div>
                            </span>
                          </span>
                        </div>
                        <div className="w-full flex flex-col items-start mt-8">
                          <label className="form-label">
                            Transaction Hash:
                          </label>
                          <div className="fs-7 text-muted">
                            <b> {orderInfo?.transactionId}</b>
                          </div>
                        </div>

                        <br />
                        <div className="w-full">
                          <div className="form-label-group">
                            <label className="form-label">
                              Payout Account:
                            </label>
                          </div>
                          <span className="flex flex-col items-start w-full">
                            <div className="w-full flex j-c-b py-2 border-b border-dashed">
                              <p>Account Type</p>
                              <p>
                                {orderInfo?.payout_type} -{" "}
                                <b>{orderInfo?.payout_account_type}</b>
                              </p>
                            </div>
                            <div className="w-full flex j-c-b py-2 border-b border-dashed">
                              <p>Account Number</p>
                              <p>{orderInfo?.payout_account_number}</p>
                            </div>
                            <div className="w-full flex j-c-b py-2 border-b border-dashed">
                              <p>Account Name</p>
                              <p>{orderInfo?.payout_account_name}</p>
                            </div>
                            <div className="w-full flex j-c-b py-2">
                              <p>Account Branch</p>
                              <p>{orderInfo?.payout_account_branch}</p>
                            </div>
                          </span>
                        </div>
                      </div>
                    )}

                    {(orderInfo?.status === "approved" ||
                      orderInfo?.status === "cancelled" ||
                      orderInfo?.status === "refunded") && (
                      <div
                        className="w-full rounded-lg bg-[#3eecff1d] p-4 flex flex-col items-start"
                        style={{ borderRadius: "15px" }}
                      >
                        <div className="form-label-group">
                          <label className="form-label">
                            Transfer sent to:
                          </label>
                        </div>
                        <div className="my-4">
                          <span className="flex flex-col items-start me-2">
                            <span className="flex">
                              {orderInfo?.asset === "BTC" ? (
                                <div className="rounded-lg w-7 h-7 flex j-c-c bg-btc">
                                  <img src={bitcoin} alt="" />
                                </div>
                              ) : (
                                <div className="rounded-lg w-7 h-7 flex j-c-c bg-usdt">
                                  <img src={teather} alt="" />
                                </div>
                              )}
                              <span className=" ml-2">
                                {orderInfo?.asset} Wallet
                              </span>
                            </span>
                            <span className="flex flex-col items-start">
                              <div className="flex flex-col items-start">
                                <br />
                                <p>Transaction Address</p>
                                <div
                                  id="wallet_address"
                                  className="text-gray-400 text-[12px]"
                                >
                                  {orderInfo?.transaction.walletAddress}
                                </div>

                                <div
                                  className="flex mt-3 cursor-pointer border border-theme text-theme px-4 py-1 rounded-md"
                                  onClick={() => {
                                    copyText(
                                      orderInfo?.transaction.walletAddress,
                                    );
                                    setState({ ...state, copied: true });
                                  }}
                                >
                                  {state.copied ? `Copied` : `Copy Address`}
                                </div>
                              </div>
                            </span>
                          </span>
                        </div>
                        <div className="w-full flex flex-col items-start mt-8">
                          <label className="form-label">
                            Transaction Hash:
                          </label>
                          <div className="fs-7 text-muted">
                            <b> {orderInfo?.transactionId}</b>
                          </div>
                        </div>

                        <br />
                        <br />
                        <div className="w-full">
                          <div className="form-label-group">
                            <label className="form-label">
                              Payout Account:
                            </label>
                          </div>
                          <span className="flex flex-col items-start w-full">
                            <div className="w-full flex j-c-b py-2 border-b border-dashed">
                              <p>Account Type</p>
                              <p>
                                {orderInfo?.paymentMethod?.branch
                                  ? state.paymentMethod?.type
                                  : "Mobile Money"}{" "}
                                -{" "}
                                <b>
                                  {orderInfo?.paymentMethod?.branch
                                    ? state.paymentMethod?.payout_type_name
                                    : state.paymentMethod?.network}
                                </b>
                              </p>
                            </div>
                            <div className="w-full flex j-c-b py-2 border-b border-dashed">
                              <p>Account Number</p>
                              <p>{state.paymentMethod.accountNumber}</p>
                            </div>
                            <div className="w-full flex j-c-b py-2 border-b border-dashed">
                              <p>Account Name</p>
                              <p>{state.paymentMethod.accountName}</p>
                            </div>
                            {state.paymentMethod?.branch && (
                              <div className="w-full flex j-c-b py-2 border-b border-dashed">
                                <p>Account Branch</p>
                                <p>{orderInfo?.paymentMethod?.branch}</p>
                              </div>
                            )}
                          </span>
                        </div>
                        <br />
                        <div className="w-full flex flex-col items-start">
                          <label className="form-label">
                            Payout Transaction Number:
                          </label>
                          <div className="fs-7 text-muted">
                            <b> {state.paymentMethod.accountNumber}</b>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default InfoOrder;
