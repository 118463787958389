import React, { CSSProperties } from "react";
import customStyle from "./style.module.css";

type InputProps = {
  value: string;
  required?: boolean;
  setValue: (value: string) => void;
  className?: {};
  style?: CSSProperties;
  options: { id: string; name: string }[];
};

const Select = ({
  required,
  options,
  value,
  setValue,
  className,
  style,
}: InputProps) => {
  const styles = {};

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(event.target.value);
  };
  return (
    <select
      style={styles}
      className={`${customStyle.CustomInput} ${className}`}
      onChange={(e) => handleChange(e)}
      required={required}
      value={value}
    >
      {options.map((val, idx) => (
        <option key={idx} value={val.id}>
          {val.name}
        </option>
      ))}
    </select>
  );
};

export default Select;
