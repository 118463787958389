import React, { useEffect, useState } from "react";
import Input from "../../Common/Components/Input/Input";
import Button from "../../Common/Components/Button/Button";
import { authImage, logo2 } from "../../Common/Assets/Images";
import SideImage from "./Components/SideImage/SideImage";
import Wrapper from "./Components/Wrapper/Wrapper";
import { useNavigate, useParams } from "react-router-dom";
import Constants from "../../Utils/Constants";
import Loading from "../../Common/Components/Loading/Loading";
import axiosInstance from "../../setup/axios";
import style from "./style.module.css";
import Notify from "../../Helpers/Toast";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const params = useParams();

  const Form = () => {
    const [formState, setFormState] = useState({
      email: "",
      isRequesting: {
        status: false,
        text: "",
      },
      resetData: {
        token: "",
        password: "",
      },
    });

    const Submit = (e: React.FormEvent) => {
      setFormState({
        ...formState,
        isRequesting: {
          ...formState.isRequesting,
          status: true,
          text: "Loading...",
        },
      });
      e.preventDefault();
      let data = { email: formState.email };
      axiosInstance
        .post(`${Constants.API_BASE_URL}/auth/user/forgot/password`, data)
        .then((res) => {
          setFormState({
            ...formState,
            isRequesting: {
              ...formState.isRequesting,
              text: "Passord verification code sent to your email",
            },
          });
          Notify("top-center", "Passord verification code sent to your email");

          setTimeout(() => {
            setFormState({
              ...formState,
              isRequesting: {
                ...formState.isRequesting,
                status: false,
                text: "",
              },
            });
          }, 2000);
        })
        .catch((err) => {
          setFormState({
            ...formState,
            isRequesting: {
              ...formState.isRequesting,
              text: "Something went wrong",
            },
          });
          Notify(
            "top-center",
            "Sorry, something went wrong, try again",
            "error",
          );

          setTimeout(() => {
            setFormState({
              ...formState,
              isRequesting: {
                ...formState.isRequesting,
                status: false,
                text: "",
              },
            });
          }, 2000);
        });
    };

    const ResetPassword = (e: React.FormEvent) => {
      setFormState({
        ...formState,
        isRequesting: {
          ...formState.isRequesting,
          status: true,
          text: "Loading...",
        },
      });
      e.preventDefault();

      axiosInstance
        .post(
          `${Constants.API_BASE_URL}/auth/user/reset/password`,
          formState.resetData,
        )
        .then((res) => {
          setFormState({
            ...formState,
            isRequesting: {
              ...formState.isRequesting,
              text: "Reseting password",
            },
          });

          setTimeout(() => {
            setFormState({
              ...formState,
              isRequesting: {
                ...formState.isRequesting,
                status: false,
                text: "",
              },
            });
          }, 2000);

          navigate("/login");
        })
        .catch((err) => {
          setFormState({
            ...formState,
            isRequesting: {
              ...formState.isRequesting,
              text: "Something went wrong",
            },
          });
          setTimeout(() => {
            setFormState({
              ...formState,
              isRequesting: {
                ...formState.isRequesting,
                status: false,
                text: "",
              },
            });
          }, 2000);
        });
    };

    useEffect(() => {
      if (params.token) {
        setFormState({
          ...formState,
          resetData: { ...formState.resetData, token: params.token },
        });
      }
      // eslint-disable-next-line
    }, []);

    return (
      <form
        onSubmit={(e) => (params.token ? ResetPassword(e) : Submit(e))}
        action=""
        className={`w-full h-full overflow-y-auto mt-[6rem] ${style.loginForm}`}
      >
        {formState.isRequesting.status && <Loading />}
        <div className="flex flex-col a-i-s mb-14">
          <h1 className={`text-3xl font-bold text-left`}>Forgot Password ?</h1>
          <b
            className={`font-normal mt-3 text-[12px] md:text-[15px] text-left`}
          >
            Expect an email with a link to reset your password and regain
            account access.
          </b>
        </div>

        <div className="flex flex-col a-i-s">
          <section className="flex a-i-s my-0">
            {params.token ? (
              <div className="flex flex-col items-start md:mr-6 w-full px-1">
                <h1 className={`text-gray-800`}>New Password</h1>
                <Input
                  type="password"
                  placeholder="enter new password"
                  value={formState.resetData.password}
                  setValue={(val) =>
                    setFormState({
                      ...formState,
                      resetData: { ...formState.resetData, password: val },
                    })
                  }
                  className={`bg-white`}
                />
              </div>
            ) : (
              <div className="flex flex-col items-start md:mr-6 w-full px-1">
                <h1 className={`text-gray-800`}>Email</h1>
                <Input
                  type="text"
                  placeholder="enter email address"
                  value={formState.email}
                  setValue={(val) => setFormState({ ...formState, email: val })}
                  className={`bg-white ${style.input}`}
                />
              </div>
            )}
          </section>
        </div>

        <div className="flex flex-col items-start mt-1">
          <Button
            text={params.token ? "Change Password" : "Send Verification Mail"}
            onClick={() => {}}
            style={{ fontWeight: "300" }}
            className={`bg-blue font-medium text-white w-[190px] md:w-[230px] h-[45px] md:h-[50px] text-[14px] my-5`}
          />

          <small className="text-gray-500">
            Changed your mind?{" "}
            <b
              className="text-blue cursor-pointer font-[500]"
              onClick={() => navigate("/login")}
            >
              Login
            </b>
          </small>
        </div>
      </form>
    );
  };

  return (
    <Wrapper
      form={<Form />}
      sideImage={<SideImage mainImage={authImage} logo={logo2} />}
    />
  );
};

export default ForgotPassword;
