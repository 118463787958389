const TokenStore = sessionStorage.getItem("tokens");
const AccessToken = TokenStore ? JSON.parse(TokenStore) : {};

const UserStore = sessionStorage.getItem("userInfo");
let user = UserStore ? JSON.parse(UserStore) : {};

const AuthService = {
  getAuth: () => user,

  getAxiosHeaders: () => {
    return {
      // timeout: 60000,

      headers: { Authorization: `Bearer ${AccessToken.token}` },
    };
  },
};

export default AuthService;
