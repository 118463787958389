import React from "react";

type props = {
  isOn: boolean;
};

function LineLoading({ isOn }: props) {
  if (isOn) {
    return (
      <section className="w-full relative border border-blue h-2 rounded-md overflow-hidden">
        <div className="loadanimate h-full w-[30%] bg-blue rounded-md absolute left-0 top-0 bottom-0"></div>
      </section>
    );
  }
  return <></>;
}

export default LineLoading;
