import React from "react";

import { Dialog, DialogPanel } from "@headlessui/react";
import closeIcon from "../../../../Common/Assets/Images/Close.png";
import check from "../../../../Common/Assets/Images/check.png";
import backarrow from "../../../../Common/Assets/Images/backarrow.png";

interface MomoProps {
  openMomoModal: boolean;
  setOpenMomoModal: (openMomoModal: boolean) => void;
  handleClickMomo: (value: any, id: string, operator: string) => void;
  handleContinue: () => void;
  supportedPayments: any;
  selectedPayment: {
    image: string;
    label: string;
    value: string;
  };
  setIsOpen: (value: boolean) => any;
}
function Momo({
  openMomoModal,
  setOpenMomoModal,
  handleClickMomo,
  handleContinue,
  supportedPayments,
  selectedPayment,
  setIsOpen,
}: MomoProps) {
  const canGoBack = window.history.state.idx !== 0;

  const goBack = (e: any) => {
    setIsOpen(true);
    setOpenMomoModal(false);
  };

  const hasErrors = !selectedPayment;
  return (
    <div>
      <Dialog
        open={openMomoModal}
        onClose={() => setOpenMomoModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 ">
          <DialogPanel className="max-w-lg space-y-4 bg-white p-6 lg:p-12 rounded-[10px]">
            <div className="flex justify-between ">
              <div className="lg:w-full w-[350px] flex justify-between ">
                <button
                  onClick={(e) => goBack(e)}
                  className={` flex cursor-pointer  focus:outline-none ${
                    !canGoBack ? "opacity-50" : <> </>
                  }`}
                  disabled={!canGoBack}
                >
                  {" "}
                  <div>
                    <img src={backarrow} alt="" className="h-3 w-4" />
                  </div>
                  <div className="ml-3 cursor-pointer text-[12px]">Back</div>
                </button>
              </div>
              <div>
                <button onClick={() => setOpenMomoModal(false)}>
                  <img src={closeIcon} alt="Close" className="w-4 h-4" />
                </button>
              </div>
            </div>
            <div className="flex flex-col items-start pt-2 overflow-hidden border-l border-r border-t  rounded-[10px] overflow-x-visible mt-4 lg:mt-6 md:mt-2">
              <div className="border-b py-2 px-4 overflow-hidden w-full ">
                <li className="text-left">
                  Please select your mobile money operator from the list below
                </li>
              </div>
              <div className="w-full">
                <ul className="list-none">
                  {supportedPayments &&
                    supportedPayments.map((payment: any) => (
                      <div
                        key={payment.id}
                        className={`py-3 px-4 border-b ${
                          selectedPayment === payment.paymentTypeCode
                            ? "border border-theme last:rounded-b-[10px]"
                            : ""
                        }`}
                        onClick={() =>
                          handleClickMomo(
                            payment.paymentTypeCode,
                            payment.id,
                            payment.name,
                          )
                        }
                        style={{
                          background: `${
                            selectedPayment === payment.paymentTypeCode
                              ? "white"
                              : "white"
                          }`,
                        }}
                      >
                        <div className="flex w-full cursor-pointer">
                          <div>
                            <img
                              src={payment.logoUrl}
                              alt=""
                              className="w-8 h-8 rounded-full"
                            />
                          </div>
                          <div className="ml-3 items-start flex flex-col">
                            <li className="text-left text-semibold text-[16px]">
                              {payment.name}
                            </li>
                          </div>
                          {selectedPayment === payment.paymentTypeCode && (
                            <img
                              src={check}
                              alt="Selected"
                              className="ml-auto h-6 w-6"
                            />
                          )}
                        </div>
                      </div>
                    ))}
                </ul>
              </div>
            </div>

            <div
              className={`border flex rounded-[10px] lg:px-4 py-4 mt-10 items-center justify-center w-full mb-4 cursor-pointer ${
                hasErrors
                  ? "bg-gray-200 text-gray-300 cursor-not-allowed"
                  : "bg-blue text-white"
              }
                `}
              onClick={handleContinue}
            >
              Continue
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}

export default Momo;
