import React, { useState, useEffect } from "react";
import PageWrapper from "../Wrapper/Wrapper";
import { useLocation, useNavigate } from "react-router-dom"; // Combined imports
import backarrow from "../../../../Common/Assets/Images/backarrow.png";
import axiosInstance from "../../../../setup/axios";
import Constants from "../../../../Utils/Constants";
import ghs from "../../../../Common/Assets/Images/ghana.png";
import AuthService from "../../../../setup/AuthServices";
import Notify from "../../../../Helpers/Toast";
import BuyOrderDetails from "./BuyOrderDetails";
import Countdown from "react-countdown";
import dayjs from "dayjs";
import { dotSpinner } from "ldrs";
import refresh from "../../../../Common/Assets/Images/refresh.png";

function BuyDetails() {
  const location = useLocation();
  const initialBuyOrder = location.state?.buyOrder;
  const screenName = location.state?.screenName;
  const [image, setImage] = useState("");
  //eslint-disable-next-line
  const [companyDetails, setCompanyDetails] = useState({});
  const [buyOrder, setBuyOrder] = useState(initialBuyOrder);
  const [isLoading, setIsLoading] = useState(false);
  dotSpinner.register();

  const canGoBack = window.history.state.idx !== 0;
  const navigate = useNavigate();

  const goBack = (e: any) => {
    e.preventDefault();
    if (screenName === "buy") {
      navigate("/dashboard");
    }
  };

  const fetchDataDetails = (dataId: string) => {
    setIsLoading(true);

    axiosInstance
      .get(`${Constants.API_BASE_URL}/users/orders/${dataId}`)
      .then((res) => {
        setBuyOrder(res);
        // console.log("res", res);
      })
      .catch((err) => {
        // console.error("Error fetching data details:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const walletID = buyOrder?.data?.id;
  const orderStatus = buyOrder?.data?.status;
  const assetID = buyOrder?.data?.walletId;
  // console.log(buyOrder);

  // console.log("wallet id ", walletID)
  let Status: string;
  if (orderStatus === "completed") {
    Status = "Completed";
  } else if (orderStatus === "pending") {
    Status = "Pending";
  } else if (orderStatus === "canceled") {
    Status = "Canceled";
  } else if (orderStatus === "awaiting.approval") {
    Status = "Awaiting Approval";
  } else if (orderStatus === "expired") {
    Status = "Expired";
  } else {
    Status = "Processing";
  }

  const getStatusColorClass = (status: any) => {
    switch (status) {
      case "Pending":
        return "bg-orange-100 rounded-[10px] p-2 border border-orange-300 text-orange-600 mt-3 w-[200px]";
      case "Canceled":
        return "bg-red-100 rounded-[10px] p-2 border border-red-300 text-red-600 mt-3 w-[200px]";
      case "Processing":
        return "bg-cyan-100 rounded-[10px] p-2 border border-cyan-300 text-cyan-600 mt-3 w-[200px]";
      case "Completed":
        return "bg-green-100 rounded-[10px] p-2 border border-green-300 text-green-600 mt-3 w-[200px]";
      case "Failed":
        return "bg-red-100 rounded-[10px] p-2 border border-red-300 text-red-600 mt-3 w-[200px]";
      case "Expired":
        return "bg-gray-100 rounded-[10px] p-2 border border-gray-600 text-gray-600 mt-3 w-[200px]";
      default:
        return "";
    }
  };

  useEffect(() => {
    fetchDataDetails(walletID);
    //eslint-disable-next-line
  }, [orderStatus]);

  // useEffect(() => {
  //   fetchDataDetails(walletID);
  // },[])
  // const submitBuyOrderForm = (e) => {
  //   const data = {
  //     accountName: companyDetails.accountName,
  //     accountNumber: companyDetails.accountNumber,
  //     networkTransactionId: String(transactionId),
  //     orderId: buyOrder.data.id,
  //   };

  //   axiosInstance
  //     .post(`${Constants.API_BASE_URL}/users/orders/payment/confirm`, data)
  //     .then((res) => {
  //       Notify("top-center", "Transaction Confirmed", "success");
  //       e.preventDefault();
  //       navigate("/orders");
  //     })
  //     .catch((err) => {
  //       Notify("top-center", "Confirmation Failed", "error");
  //       e.preventDefault();
  //       console.error("Error confirming transaction:", err);
  //     });
  // };

  const cancelOrder = (id: any) => {
    if (window.confirm("Are you sure you want to cancel this order")) {
      let data = {
        orderId: buyOrder.data.id,
      };
      axiosInstance
        .post(
          `${Constants.API_BASE_URL}/orders/cancel`,
          data,
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          Notify("top-center", "Transaction Canceled", "success");
          navigate("/orders");
        })
        .catch((err) => {
          Notify("top-center", "Cancelation Failed", "error");
          // console.error("Error canceling transaction:", err);
        });
    }
  };

  const getIconByAsset = (assetID: string) => {
    axiosInstance
      .get(`${Constants.API_BASE_URL}/assets/${assetID}`)
      .then((response) => {
        setImage(response.data.icon);
      })
      .catch((error) => {
        // console.error("Error getting asset icon:", error);
      });
  };

  const getCompanyAccount = () => {
    axiosInstance
      .get(`${Constants.API_BASE_URL}/company/payment/method/primary`)
      .then((res) => {
        setCompanyDetails(res.data);
      })
      .catch((err) => {
        // console.error("Error getting company account:", err);
      });
  };
  // console.log("buyOrder", buyOrder);

  useEffect(() => {
    getIconByAsset(assetID);
    getCompanyAccount();
    //eslint-disable-next-line
  }, []);

  if (!buyOrder) {
    return <BuyOrderDetails />;
  }

  return (
    <PageWrapper
      content={
        <div className="flex flex-col">
          <div className="w-[380px] lg:w-[500px] flex justify-between items-center">
            <div>
              <div className="flex justify-between w-[380px] lg:w-[500px] ">
                <button
                  onClick={(e) => goBack(e)}
                  className={`flex cursor-pointer focus:outline-none ${
                    !canGoBack ? "opacity-50" : ""
                  }`}
                  disabled={!canGoBack}
                >
                  <div>
                    <img src={backarrow} alt="Back" className="h-3 w-4" />
                  </div>
                  <div className="ml-3 cursor-pointer text-[12px] ">Back</div>
                </button>
                <div
                  className="cursor-pointer text-[18px] font-medium text-red-700"
                  onClick={(e) => {
                    e.preventDefault();
                    cancelOrder(buyOrder.data.id);
                  }}
                >
                  Cancel
                </div>
              </div>
              <div className="flex justify-between w-[380px] lg:w-[500px] mt-10">
                <div></div>
                <div onClick={() => fetchDataDetails(walletID)}>
                  <img
                    src={refresh}
                    alt="Refresh"
                    className="cursor-pointer h-4 w-4"
                  />
                </div>
              </div>
            </div>
          </div>

          {isLoading ? (
            <div className="flex flex-col justify-center items-center min-h-[450px] min-w-[390px] p-6">
              <div className="mb-4">
                <l-dot-spinner
                  size="80"
                  speed="0.9"
                  color="black"
                ></l-dot-spinner>
              </div>
              <div>
                <h2 className="p-8">Loading...</h2>
              </div>
            </div>
          ) : (
            <div>
              <div
                className="flex flex-col rounded-[15px] p-6 items-center w-[380px] lg:w-[500px] mt-4"
                style={{ backgroundColor: "#F5F5F5" }}
              >
                <img
                  src={buyOrder?.data?.assetEntity?.icon}
                  alt="Asset Icon"
                  className="w-8 h-8"
                />
                <div className="mt-3 text-[16px] font-medium">
                  Buy {buyOrder?.data?.asset}
                </div>
                <div className="font-bold text-[22px] p-2">
                  USD {buyOrder?.data?.amount}
                </div>

                <Countdown
                  date={dayjs(new Date(buyOrder?.data?.createdAt))
                    .add(10, "minute")
                    .valueOf()}
                  zeroPadTime={2}
                  renderer={({ formatted: { hours, minutes, seconds } }) => {
                    return (
                      <div className="flex flex-col w-full mb-4 mt-2">
                        <div className={`mt-2 ${getStatusColorClass(Status)}`}>
                          {Status}
                        </div>
                        {orderStatus === "pending" && (
                          <div className="countdown-container flex justify-end items-center border border-gray-200 rounded-[10px] mt-4">
                            <div className="countdown-item flex flex-col px-2">
                              <span className="font-bold text-xl">
                                {minutes}
                              </span>
                            </div>
                            <span className="text-xl">:</span>
                            <div className="countdown-item flex flex-col px-2">
                              <span className="font-bold text-xl">
                                {seconds}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
              <div className="mt-2 w-[380px] lg:w-[500px]">
                <div className="text-[18px] font-bold p-2 text-left">
                  You will send
                </div>
                <div className="flex justify-between border border-gray-300 p-3 rounded-[10px]">
                  <div className="flex">
                    <div>
                      <img
                        src={ghs}
                        alt="GHS"
                        className="w-8 h-8 rounded-full"
                      />
                    </div>
                    <div className="ml-3">
                      {(buyOrder?.data?.amount * buyOrder?.data?.rate).toFixed(
                        2,
                      )}
                    </div>
                  </div>
                  <div>GHS</div>
                </div>
                <div className="text-[13px] p-2 text-left text-gray-400 flex">
                  Order Id:{" "}
                  <div className="text-green-600 ml-1">
                    {buyOrder?.data?.transactionId}
                  </div>
                </div>
                {orderStatus === "pending" && (
                  <div className="flex flex-col items-start w-[380px] lg:w-[500px]">
                    <div className="text-[18px] font-bold p-2 text-left mt-2">
                      Payment Instructions
                    </div>
                    <div className="p-4 mb-4 text-sm text-cyan-800 rounded-lg bg-cyan-100">
                      <div className="font-medium text-left">
                        Haven't Received a Mobile Payment Prompt?
                        <br />
                      </div>
                      <div className="text-left">
                        1. Dial *170# select Option 6, My Wallet. <br />
                        2. Select Option 3 for My Approvals. <br />
                        3. Enter your PIN to get your Pending Approval List.{" "}
                        <br />
                        4. Select pending transaction to approve.
                        <br />
                        5. Select Option 1 YES to approve the transaction or
                        Option 2 NO to reject the transaction. <br />
                      </div>
                    </div>
                  </div>
                )}
                <div className="text-[18px] font-bold p-2 text-left mt-2">
                  You will receive
                </div>
                <div className="flex justify-between border border-gray-300 p-3 rounded-[10px]">
                  <div className="flex">
                    <div>
                      <img
                        src={image}
                        alt="Asset Icon"
                        className="w-8 h-8 rounded-full"
                      />
                    </div>
                    <div className="ml-3">{buyOrder?.data?.amount}</div>
                  </div>
                  <div>{buyOrder?.data?.asset}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    />
  );
}

export default BuyDetails;
