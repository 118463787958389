import React, { useState, useContext } from "react";
import close from "../../../Common/Assets/Images/Close.png";
import wallet from "../../../Common/Assets/Images/amountwallet.png";
import coin from "../../../Common/Assets/Images/coins.png";
import backarrow from "../../../Common/Assets/Images/backarrow.png";
import OtpInput from "react-otp-input";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import {
  AmountContext,
  AddressContext,
  PriorityContext,
} from "./SendModalContext";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleOpenSuccessModal: () => void;
  handleCloseConfirmModal: () => void;
  walletdata: any;
  checked: boolean;
  fiatamount: string;
  btcamount: string;
  handleForgotPin: () => void;
  goBack: () => void;
}

function ConfirmModal({
  isOpen,
  onClose,
  handleOpenSuccessModal,
  handleCloseConfirmModal,
  walletdata,
  checked,
  fiatamount,
  btcamount,
  handleForgotPin,
  goBack,
}: ModalProps) {
  const [transactionPin, setTransactionPin] = useState("");
  const { amount } = useContext(AmountContext);
  const { address } = useContext(AddressContext);

  const { transferPriority } = useContext(PriorityContext);

  const [errormessage, setErrormessage] = useState("");

  const handlePin = (transactionPin: string) => {
    setTransactionPin(transactionPin);
  };

  const handleConfirm = async () => {
    const walletData = {
      receipientAddress: address,
      amount: checked ? String(btcamount) : String(amount),
      walletName: "BTC",
      feeType: transferPriority,
      transactionPin: transactionPin,
    };
    try {
      setErrormessage("");
      // console.log("walletdata", walletData);

      const res = await axiosInstance.post(
        `${Constants.API_BASE_URL}/wallet/bitcoin/withdraw`,
        walletData,
      );
      // console.log("response", res);

      if (res.data.status === "success") {
        // console.log("success");
        handleOpenSuccessModal();
        handleCloseConfirmModal();
      } //else
    } catch (err: any) {
      setErrormessage(err.response.data.message);
    }
  };

  // console.log("money", checked);
  // console.log("fiatamount", fiatamount);
  // console.log("btcamount", btcamount);

  return (
    <>
      <div
        className={`modal fixed w-full h-full top-0 left-0 flex justify-center overflow-y-auto z-50  p-3 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div className="relative bg-white p-4 rounded-lg shadow-md w-[480px] sm:w-full sm:p-8 min-w-[200px] max-w-[500px] min-h-[450px] max-h-[800px] sm:min-h-[400px] sm:max-h-[8000px] md:min-h-[600px] md:max-h-[800px]">
          <button
            onClick={goBack}
            className="flex  cursor-pointer items-center absolute top-0 left-0 p-4 z-50"
          >
            <div>
              <img src={backarrow} alt="" className="h-4 w-6" />
            </div>
            <div className="ml-3 cursor-pointer">Back</div>
          </button>
          <button onClick={onClose} className="absolute top-0 right-0 p-4 z-50">
            <img src={close} alt="Close" className="h-3 w-3" />
          </button>

          <>
            <div className="modal-content text-center mt-8">
              <div className="modal-header pb-2 flex items-center justify-center">
                <div className="pr-3">
                  <img
                    src="https://exolix.com/icons/coins/BTC.png"
                    alt=""
                    className="w-10 h-10"
                  />
                </div>
                <h3 className="modal-title text-[24px] font-bold ">
                  Confirm and send{" "}
                </h3>
              </div>

              <div className="flex items-start mt-12">
                <div className=" p-2 mr-4">
                  <img src={wallet} alt="" className="h-6 w-6" />
                </div>
                <div className="flex flex-col items-start w-full">
                  <div className=" font-bold text-[14px]">Amount</div>
                  <div className="w-full">
                    {" "}
                    {checked ? (
                      <div className="flex flex-row justify-between w-full">
                        <div>{Number(amount).toFixed(2)} USD</div>
                        <div>{btcamount} BTC</div>
                      </div>
                    ) : (
                      <>
                        <div className="flex flex-row justify-between w-full ">
                          <div>
                            {amount} {walletdata?.walletdata?.walletName}{" "}
                          </div>
                          <div> {Number(fiatamount).toFixed(2)} USD</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex items-start mt-4">
                <div className="  lg:p-2 lg:mr-4 mr-2 p-1">
                  <img src={coin} alt="" className="h-6 w-6 " />
                </div>
                <div className="flex flex-col items-start mt-2 ">
                  <div className="w-full  text-[12px] lg:text-[16px]">
                    {" "}
                    {address}
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-start mt-8">
                <div className=" font-bold text-[16px]">Transaction PIN</div>

                <div className="w-full lg:w-full  ">
                  <OtpInput
                    value={transactionPin}
                    onChange={handlePin}
                    numInputs={6}
                    renderSeparator={<span className="mx-2"></span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      border: "1px solid #d1d5db",
                      textAlign: "center",
                      fontSize: "1.5rem",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      borderRadius: "10px",
                      boxSizing: "border-box",
                      maxWidth: "100%",
                      width: "100%",
                      height: "60px",
                      maxHeight: "60px",
                    }}
                    inputType="password"
                    containerStyle={{
                      display: "flex",
                      width: "100%",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </div>

              <div
                onClick={handleForgotPin}
                className="text-sm underline text-left mt-2  cursor-pointer"
              >
                Forgot your PIN?
              </div>
              {errormessage && (
                <div className="text-red-500 text-left text-[13px] mt-2">
                  {errormessage}
                </div>
              )}
              <div className="mt-20">
                <button
                  onClick={handleConfirm}
                  className={`bg-prevBlue text-white rounded-[10px] px-4 py-4 w-full `}
                >
                  Confirm
                </button>

                <button
                  onClick={onClose}
                  className="bg-gray-200 text-gray-500 rounded-[10px] px-4 py-4 w-full mt-4"
                >
                  Cancel
                </button>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default ConfirmModal;
