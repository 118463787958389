import React, { useState, useEffect } from "react";
import PageWrapper from "../Wrapper/Wrapper";
import { useLocation, useNavigate } from "react-router-dom";
import copyIcon from "../../../../Common/Assets/Images/Copy.png";
import { QRCode } from "react-qrcode-logo";
import axiosInstance from "../../../../setup/axios";
import Constants from "../../../../Utils/Constants";
import backarrow from "../../../../Common/Assets/Images/backarrow.png";
import refresh from "../../../../Common/Assets/Images/refresh.png";
import { dotSpinner } from "ldrs";
import Countdown from "react-countdown";
import dayjs from "dayjs";

interface Order {
  id: string;
  amount: number;
  asset: string;
  cryptoAmount: string;
  status: string;
  transaction: {
    walletAddress: string;
  };
  walletId: string;
  transactionId: string;
  createdAt: string;
  assetEntity: {
    icon: string;
  };
}

function SellOrderDetails() {
  const location = useLocation();
  const initialOrder: Order = location.state?.order;
  const [order, setOrder] = useState<Order>(initialOrder);
  const [copied, setCopied] = useState(false);
  const [copyCrypto, setCopyCrypto] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const canGoBack = window.history.state.idx !== 0;
  const goBack = () => {
    navigate(-1);
  };
  dotSpinner.register();

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = order.transaction.walletAddress;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const copiedToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = order.cryptoAmount;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setCopyCrypto(true);
    setTimeout(() => {
      setCopyCrypto(false);
    }, 2000);
  };

  const fetchDataDetails = (walletID: string) => {
    setIsLoading(true);
    axiosInstance
      .get(`${Constants.API_BASE_URL}/users/orders/${walletID}`)
      .then((res) => {
        setOrder(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.error("Error fetching data details:", err);
        setIsLoading(false);
      });
  };

  const walletID = order?.id;
  const orderStatus = order?.status;
  // const assetID = order?.walletId;

  // console.log("order", order?.walletId);

  let Status: string;
  switch (orderStatus) {
    case "completed":
      Status = "Completed";
      break;
    case "pending":
      Status = "Pending";
      break;
    case "canceled":
      Status = "Canceled";
      break;
    case "awaiting.approval":
      Status = "Awaiting Approval";
      break;
    case "expired":
      Status = "Expired";
      break;
    default:
      Status = "Processing";
  }

  function getStatusColorClass(status: string) {
    switch (status) {
      case "Pending":
        return "bg-orange-100 rounded-[10px] p-2 border border-orange-300 text-orange-600 mt-3 w-[200px]";
      case "Canceled":
        return "bg-red-100 rounded-[10px] p-2 border border-red-300 text-red-600 mt-3 w-[200px]";
      case "Processing":
        return "bg-cyan-100 rounded-[10px] p-2 border border-cyan-300 text-cyan-600 mt-3 w-[200px]";
      case "Completed":
      case "Awaiting Approval":
        return "bg-green-100 rounded-[10px] p-2 border border-green-300 text-green-600 mt-3 w-[200px]";
      case "Failed":
        return "bg-red-100 rounded-[10px] p-2 border border-red-300 text-red-600 mt-3 w-[200px]";
      case "Expired":
        return "bg-gray-100 rounded-[10px] p-2 border border-gray-300 text-gray-600 mt-3 w-[200px]";
      default:
        return "";
    }
  }
  useEffect(() => {
    fetchDataDetails(walletID);
    //eslint-disable-next-line
  }, [orderStatus]);

  return (
    <PageWrapper
      content={
        <div className="flex flex-col">
          <div className="w-[380px] lg:w-[500px] flex justify-between">
            <div className="flex justify-between w-[380px] lg:w-[500px]">
              <button
                onClick={goBack}
                className={`flex cursor-pointer focus:outline-none ${
                  !canGoBack ? "opacity-50" : ""
                }`}
                disabled={!canGoBack}
              >
                <div>
                  <img src={backarrow} alt="Back" className="h-3 w-4" />
                </div>
                <div className="ml-3 cursor-pointer text-[12px] ">Back</div>
              </button>
              <div onClick={() => fetchDataDetails(walletID)}>
                <img
                  src={refresh}
                  alt="Refresh"
                  className="cursor-pointer h-4 w-4"
                />
              </div>
            </div>
          </div>

          {isLoading ? (
            <div className="flex flex-col justify-center items-center min-h-[450px] min-w-[390px] p-6">
              <div className="mb-4">
                <l-dot-spinner
                  size="80"
                  speed="0.9"
                  color="black"
                ></l-dot-spinner>
              </div>
              <div>
                <h2 className="p-8">Loading...</h2>
              </div>
            </div>
          ) : (
            <>
              <div
                className="flex flex-col rounded-[15px] p-6 items-center w-[380px] lg:w-[500px] mt-12"
                style={{ backgroundColor: "#F5F5F5" }}
              >
                <img
                  src={order?.assetEntity?.icon}
                  alt="Asset Icon"
                  className="w-8 h-8"
                />
                <div className="mt-3 text-[16px] font-medium">
                  Sell {order.asset}
                </div>
                <div className="font-bold text-[22px] p-2">
                  USD {order.amount}
                </div>
                <Countdown
                  date={dayjs(new Date(order?.createdAt))
                    .add(1, "hour")
                    .valueOf()}
                  zeroPadTime={2}
                  renderer={({ formatted: { minutes, seconds } }) => {
                    return (
                      <div className="flex flex-col w-full mb-4 mt-2 ">
                        <div className={` mt-2 ${getStatusColorClass(Status)}`}>
                          {Status}
                        </div>
                        {orderStatus === "pending" && (
                          <div className="countdown-container flex justify-end items-center border border-gray-200 rounded-[10px] mt-4">
                            <div className="countdown-item flex flex-col px-2">
                              <span className=" font-bold text-xl">
                                {minutes}
                              </span>
                            </div>
                            <span className="text-xl">:</span>
                            <div className="countdown-item flex flex-col px-2">
                              <span className=" font-bold text-xl">
                                {seconds}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
              <div className="mt-2 w-[380px] lg:w-[500px]">
                <div className="text-[18px] font-bold p-2 text-left">
                  You will send
                </div>
                <div className="flex justify-between border border-gray-300 p-3 rounded-[10px]">
                  <div className="flex">
                    <div>
                      <img
                        src={order?.assetEntity?.icon}
                        alt="Asset Icon"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="ml-3">
                      {order?.cryptoAmount} {order?.asset}
                    </div>
                  </div>
                  <div
                    className="flex justify-between items-center"
                    onClick={copiedToClipboard}
                  >
                    <button>
                      <img src={copyIcon} alt="Copy" className="w-4 h-4" />
                    </button>
                    {copyCrypto && (
                      <span className="text-xs text-blue">Copied</span>
                    )}
                  </div>
                </div>
                <div className="text-[13px] p-2 text-left mt-1 text-gray-400 flex">
                  Order Id:{" "}
                  <div className="text-green-600 ml-1">
                    {order?.transactionId}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start w-[380px] lg:w-[500px]">
                <div className="text-[18px] font-bold p-2 text-left">
                  Payment Details
                </div>
              </div>
              <div
                className="border border-gray-300 rounded-[10px] p-3 w-[380px] lg:w-[500px]"
                onClick={copyToClipboard}
              >
                <div className="flex justify-between items-center">
                  <div>{order?.transaction?.walletAddress}</div>
                  <button>
                    <img src={copyIcon} alt="Copy" className="w-4 h-4" />
                  </button>
                  {copied && <span className="text-xs text-blue">Copied</span>}
                </div>
              </div>
              <div className="mt-6 border border-gray-300 rounded-[10px] p-4 flex items-center w-[380px] lg:w-[500px]">
                <div className="text-center">
                  Or scan the QR code to capture the wallet address
                </div>
                <QRCode value={order.transaction.walletAddress} size={150} />
              </div>
            </>
          )}
        </div>
      }
    />
  );
}

export default SellOrderDetails;
