import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../setup/axios";
import Constants from "../../../../Utils/Constants";
import close from "../../../../Common/Assets/Images/Close.png";

interface ModalProps {
  isOpen: boolean;
  closeExolixModal: () => void;
  fromCurrency: { network: string; code: string; icon: string };
  toCurrency: { network: string; code: string; icon: string };
  rates: any;
  fromValue: string;
  xchanger: string;
  handleOpenExchangeDetails: (data: any) => void;
  closeModal: () => void;
  rateType: string;
}

const ExchangeModal: React.FC<ModalProps> = ({
  isOpen,
  closeExolixModal,
  fromCurrency,
  toCurrency,
  rates,
  fromValue,
  xchanger,
  handleOpenExchangeDetails,
  rateType,
}) => {
  const modalStyles = isOpen ? "block" : "hidden";
  const [showRefundInput, setShowRefundInput] = useState(false);
  const [refundAddressText, setRefundAddressText] =
    useState("Add Refund Address");
  const [withdrawalErrorMessage, setWithdrawalErrorMessage] = useState("");
  const [refundErrorMessage, setRefundErrorMessage] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [withdrawalAddress, setWithdrawalAddress] = useState("");
  const [refundAddress, setRefundAddress] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState<JSX.Element | string>("");

  const handleSwap = async () => {
    const swapData = {
      fromCurrency: fromCurrency.code,
      toCurrency: toCurrency.code,
      fromNetwork: fromCurrency.network,
      toNetwork: toCurrency.network,
      exchanger: xchanger,
      amount: fromValue,
      refundAddress: refundAddress,
      withdrawalAddress: withdrawalAddress,
      rateType: rateType,
    };

    try {
      const response = await axiosInstance.post(
        `${Constants.API_BASE_URL}/swap/transaction`,
        swapData,
      );
      setSuccessMessage("Swap successful!");
      // console.log("Swap response:", response.data);
      // console.log("Exchanger:", rates[0]?.exchanger);
      // console.log("Exchanger:", rates[1]?.exchanger);

      closeExolixModal();
      handleOpenExchangeDetails(response.data);
    } catch (error) {
      setErrorMessage(
        <span className="text-red-500">
          Try again with a different amount.
        </span>,
      );
      // console.error("Error performing swap:", error);
      // console.log("Exchanger:", swapData);
    }
  };

  const handleWithdrawalAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setWithdrawalAddress(value);
    validateWithdrawalAddress(value);
  };

  const handleRefundAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setRefundAddress(value);
    validateRefundAddress(value);
  };

  const handleToggleRefundAddress = () => {
    if (showRefundInput) {
      // If refund input is shown, hide it and change text to "Add Refund Address"
      setShowRefundInput(false);
      setRefundAddressText("Add Refund Address");
    } else {
      // If refund input is hidden, show it and change text to "Remove Refund Address"
      setShowRefundInput(true);
      setRefundAddressText("Remove Refund Address");
    }
  };

  const validateWithdrawalAddress = async (address: string) => {
    if (address.trim() === "") {
      setDisplayError(!displayError); // Hide the error message if the address is empty
      return;
    }
    try {
      const response = await axiosInstance.get(
        `${Constants.API_BASE_URL}/assets/validate/address?address=${address}&currency=${toCurrency?.network}`,
      );
      // console.log("response:", response)
      // console.log("response:", order.currency)
      // setIsValidAddress(response.data);

      if (response.data === false) {
        setWithdrawalErrorMessage(
          "Invalid address. Please enter a valid address.",
        );
        setIsButtonDisabled(true);
      } else {
        setWithdrawalErrorMessage("");
        setIsButtonDisabled(false);
      }
    } catch (error) {
      // console.error("Error validating address:", error);
    }
  };

  const validateRefundAddress = async (address: string) => {
    if (address.trim() === "") {
      setDisplayError(false); // Hide the error message if the address is empty
      return;
    }
    try {
      const response = await axiosInstance.get(
        `${Constants.API_BASE_URL}/assets/validate/address?address=${address}&currency=${fromCurrency?.network}`,
      );
      // console.log("response:", response)
      // console.log("response:", order.currency)
      // setIsValidAddress(response.data);

      if (response.data === false) {
        setRefundErrorMessage("Invalid address. Please enter a valid address.");
        setIsButtonDisabled(true);
      } else {
        setRefundErrorMessage("");
        setIsButtonDisabled(false);
      }
    } catch (error) {
      // console.error("Error validating address:", error);
    }
  };

  useEffect(() => {
    // Validation for withdrawal address
    validateWithdrawalAddress(withdrawalAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawalAddress, toCurrency.network]);

  useEffect(() => {
    // Validation for refund address
    validateRefundAddress(refundAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundAddress, fromCurrency.network]);

  return (
    <div className={`fixed inset-0 ${modalStyles} overflow-y-auto z-50`}>
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div className="relative bg-white p-8 rounded-lg shadow-md w-[480px] sm:w-full sm:p-8 min-w-[200px] max-w-[480px] min-h-[200px] max-h-[480px] sm:min-h-[200px] sm:max-h-[480px] md:min-h-[300px] md:max-h-[480px]">
          <div className="text-2xl font-bold mb-4">Swap Transaction</div>
          <div className="flex justify-center items-center">
            <img
              className="rounded-xl"
              style={{ width: "30px", height: "30px" }}
              src={fromCurrency?.icon}
              alt={fromCurrency?.code}
            />
            {fromCurrency && (
              <span className="text-gray-300">
                {fromCurrency.code.toUpperCase()}
                {fromCurrency.code === "usdt" && (
                  <span
                    style={{ background: "#01A69F", fontSize: "0.875rem" }}
                    className="ml-1 text-white rounded-[10px] px-2"
                  >
                    {fromCurrency.network}
                  </span>
                )}
              </span>
            )}
            <span className="mx-2">-</span>
            <img
              className="rounded-xl"
              style={{ width: "30px", height: "30px" }}
              src={toCurrency?.icon}
              alt={toCurrency?.code}
            />
            <span className="text-gray-300 ">
              {toCurrency?.code.toUpperCase()}
            </span>

            {toCurrency?.code === "usdt" && (
              <span
                style={{ background: "#01A69F", fontSize: "0.875rem" }}
                className="ml-1 text-white rounded-[10px] px-2 "
              >
                {toCurrency?.network}
              </span>
            )}
          </div>

          <div className="flex flex-col justify-start items-start">
            <div className="mt-8 text-sm text-left">
              Withdrawal Address {toCurrency?.code.toUpperCase()}
              {toCurrency?.code === "usdt" && (
                <span className="ml-1">
                  ({toCurrency?.network.toUpperCase()})
                </span>
              )}
            </div>
            <input
              style={{ backgroundColor: "#F2F8FF" }}
              className="bg-blue rounded-[27px] h-[50px] w-full px-4"
              placeholder="Enter Withdrawal Address"
              value={withdrawalAddress}
              onChange={handleWithdrawalAddressChange}
            />
            {withdrawalErrorMessage && (
              <small style={{ color: "red" }}>{withdrawalErrorMessage}</small>
            )}
            <div className=" flex flex-col justify-start items-start w-full">
              <div
                className="text-blue text-xs underline cursor-pointer"
                onClick={handleToggleRefundAddress}
              >
                {refundAddressText}
              </div>
              {showRefundInput && (
                <div className="mt-4 w-full">
                  <div className=" text-sm text-left">
                    Refund Address {fromCurrency?.code.toUpperCase()}
                    {fromCurrency?.code === "usdt" && (
                      <span className="ml-1">
                        ({fromCurrency?.network.toUpperCase()})
                      </span>
                    )}
                  </div>{" "}
                  <input
                    type="text"
                    style={{ backgroundColor: "#F2F8FF" }}
                    className="bg-blue rounded-[27px] h-[50px] w-full px-4 "
                    placeholder="Enter Refund Address"
                    value={refundAddress}
                    onChange={handleRefundAddressChange}
                  />
                  {refundErrorMessage && (
                    <small style={{ color: "red" }}>{refundErrorMessage}</small>
                  )}
                </div>
              )}
            </div>
          </div>
          <button
            onClick={closeExolixModal}
            className="absolute top-0 right-0 p-4"
          >
            <img src={close} alt="Close" className="h-3 w-3" />
          </button>
          {successMessage && <div>{successMessage}</div>}
          {errorMessage && <div>{errorMessage}</div>}
          <button
            onClick={handleSwap}
            className={`w-[160px] h-[50px] px-4 rounded-md mt-6 
        ${isButtonDisabled ? "bg-gray-400 text-gray-300 cursor-not-allowed" : "bg-blue hover:bg-blue-600 text-white"}`}
            disabled={isButtonDisabled}
          >
            Swap Crypto
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExchangeModal;
