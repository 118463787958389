import React from "react";
import { loader } from "../../Assets/Icons";

function ModalLoading() {
  return (
    <div className="w-[250px] h-[100px] flex flex-col j-c-c">
      <img src={loader} alt="" width={20} />
    </div>
  );
}

export default ModalLoading;
