import React, { useState } from "react";
import { Field, Input, Label } from "@headlessui/react";
import clsx from "clsx";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import ContactDetails from "./ContactDetails";
import backarrow from "../../../Common/Assets/Images/backarrow.png";
import ghs from "../../../Common/Assets/Images/ghs.png";
import { zoomies } from "ldrs";

interface ValidateMomoProps {
  selectedPaymentId: string;
  momoOperator: string;
  setShowMomo: (value: boolean) => any;
  showMomo: boolean;
  setShowNameDisplay: (value: boolean) => any;
}

const ValidateMomo: React.FC<ValidateMomoProps> = ({
  selectedPaymentId,
  momoOperator,
  setShowMomo,
  showMomo,
  setShowNameDisplay,
}) => {
  const [input, setInput] = useState<string>("");
  const [fullname, setFullName] = useState<string>("");
  const [showValidateForm, setShowValidateForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verifyError, setVerifyError] = useState<string>("");
  const [screenName, setScreenName] = useState<string>("validate");

  zoomies.register();

  const hasErrors = !input;

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerifyError("");
    setInput(e.target.value);
  };

  const verifyName = async () => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.get(
        `${Constants.API_BASE_URL}/payments/account/query?accountNumber=${input}&paymentId=${selectedPaymentId}`,
      );
      setFullName(res.data.accountName);
    } catch (err) {
      // console.error("error", err);
      setVerifyError("There is something wrong with the information entered");
    } finally {
      setIsLoading(false);
    }
  };

  const canGoBack = window.history.state.idx !== 0;

  const goBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (screenName === "validate") {
      setScreenName("momo");
      setShowNameDisplay(false);
      setShowMomo(true);
    }
  };

  const queryName = () => {
    verifyName();
    setShowValidateForm(true);
  };

  return (
    <div className="items-center justify-center min-h-full mx-auto">
      {!showValidateForm ? (
        <div className="flex flex-col items-start ">
          <div className="lg:w-full w-[350px] flex justify-between mt-6">
            <button
              onClick={goBack}
              className={`flex cursor-pointer focus:outline-none ${
                !canGoBack ? "opacity-50" : ""
              }`}
              disabled={!canGoBack}
            >
              <div>
                <img src={backarrow} alt="" className="h-3 w-4" />
              </div>
              <div className="ml-3 cursor-pointer text-[12px]">Back</div>
            </button>
          </div>
          <div className="text-[18px] font-semibold text-black mt-16">
            Enter your Mobile Money Number
          </div>
          <div className="text-[14px] text-black mt-2 text-left text-nowrap">
            Please enter the correct mobile money number to verify your
            registered name
          </div>
          <div className="w-full max-w-md">
            <Field>
              <Label className="text-[16px] font-medium text-black text-left flex items-start mt-4">
                Phone Number
              </Label>
              <div className="relative">
                <Input
                  value={input}
                  onChange={handleInput}
                  placeholder="Enter Mobile Money Number"
                  className={clsx(
                    "mt-3 block w-full rounded-lg border border-gray-300 py-3 px-10 text-sm/6 text-black",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
                  )}
                />
                <div className="!absolute left-1 top-1 z-10 mt-2 ml-2">
                  <img src={ghs} alt="GHS" className="h-6 w-6" />
                </div>
                <div
                  className={`!absolute right-1 top-1 z-10 select-none cursor-pointer rounded py-2 px-4 text-center align-middle font-sans text-xs font-bold mt-1 px-2 ${
                    hasErrors
                      ? "bg-gray-200 text-gray-300 cursor-not-allowed"
                      : "bg-blue text-white"
                  }`}
                  onClick={!hasErrors ? verifyName : undefined}
                >
                  Verify
                </div>
                {isLoading && (
                  <div className="items-start">
                    <l-zoomies
                      size="150"
                      stroke="5"
                      bg-opacity="0.1"
                      speed="1.4"
                      color="black"
                    ></l-zoomies>
                  </div>
                )}
              </div>
            </Field>
            {verifyError && (
              <div className="text-red-500 text-[12px] text-left mt-2">
                {verifyError}
              </div>
            )}
            {fullname && (
              <Field>
                <Label className="text-[16px] font-medium text-black text-left flex items-start mt-4">
                  Full Name
                </Label>
                <div
                  className={clsx(
                    "mt-3 block w-full rounded-lg border border-gray-300 py-3 px-3 text-sm/6 text-black text-left",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
                  )}
                >
                  {fullname}
                </div>
              </Field>
            )}
            {fullname && (
              <div
                className="border bg-blue flex rounded-[10px] lg:px-4 py-4 mt-6 items-center justify-center w-full mb-4 cursor-pointer text-white"
                onClick={queryName}
              >
                Continue
              </div>
            )}
          </div>
        </div>
      ) : (
        <ContactDetails
          momoOperator={momoOperator}
          selectedPaymentId={selectedPaymentId}
          input={input}
          fullname={fullname}
          setShowValidateForm={setShowValidateForm}
          setShowNameDisplay={setShowNameDisplay}
        />
      )}
    </div>
  );
};

export default ValidateMomo;
