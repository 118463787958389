import React from "react";
import PageWrapper from "../Components/Wrapper/Wrapper";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import dayjs from "dayjs";
import backarrow from "../../../Common/Assets/Images/backarrow.png";
import { useNavigate } from "react-router-dom";

import calendar from "../../../Common/Assets/Images/calendar.png";

function Challenges() {
  const limit = 10;
  const navigate = useNavigate();
  const fetchChallenges = async () => {
    const response = await axiosInstance.get(
      `${Constants.API_BASE_URL}/users/monthly/challenges?limit=${limit}`,
    );
    return response.data;
  };

  const { data } = useQuery({
    queryKey: ["points"],
    queryFn: fetchChallenges,
  });


  return (
    <PageWrapper
      content={
        <div>
          <div className="flex flex-col items-start w-full   gap-10">
            <button
              onClick={() => navigate("/dashboard")}
              className={`flex cursor-pointer focus:outline-none `}
            >
              <div>
                <img src={backarrow} alt="Back" className="h-3 w-4" />
              </div>
              <div className="ml-3 cursor-pointer text-[12px] ">Back</div>
            </button>
            <h1 className="font-semibold text-3xl">Details</h1>

            <div className="font-semibold text-[20px]">
              {data?.data?.[0]?.title}{" "}
            </div>
            <div className=" text-[16px]">{data?.data?.[0]?.description} </div>
            <img src={data?.data?.[0]?.image} alt="" />

            <div className=" text-[16px] text-start">
              {data?.data?.[0]?.body}{" "}
            </div>

            <hr className="w-full border-t border-gray-300 " />

            <div className="flex justify-between w-full ">
              <div className="flex gap-2">
                <div>
                  {" "}
                  <img src={calendar} alt="" className="h-4 w-4" />{" "}
                </div>
                <div>Deadline</div>
              </div>
              <div>
                {dayjs(new Date(data?.data?.[0]?.deadline)).format(
                  "MMMM DD, YYYY",
                )}
              </div>
            </div>

            <hr className="w-full border-t border-gray-300 " />
          </div>
        </div>
      }
    />
  );
}

export default Challenges;
