import toast, { ToastPosition } from "react-hot-toast";

const Notify = (
  position: ToastPosition,
  message: string,
  type: string = "success",
) => {
  const toastOptions = {
    position: position,
    duration: 4000,
    icon:
      type === "success"
        ? "👍"
        : type === "error"
          ? "🛑"
          : type === "warning"
            ? ""
            : "ℹ️",
  };

  return toast(message, toastOptions);
};

export default Notify;
