import React, { useContext, useEffect, useState } from "react";
import Wrapper from "./Components/Wrapper/Wrapper";
import SideImage from "./Components/SideImage/SideImage";
import { authImage, logo2 } from "../../Common/Assets/Images";
import Button from "../../Common/Components/Button/Button";
import Input from "../../Common/Components/Input/Input";
import { useNavigate } from "react-router-dom";
import Constants from "../../Utils/Constants";
import Context from "../../store/Contex";
import Loading from "../../Common/Components/Loading/Loading";
import style from "./style.module.css";
import Cookies from "js-cookie";
import { isEmailValid } from "../../setup/Validate";
import axios from "axios";
import TwoFactorModal from "../../Pages/Auth/TwoFactorModal";
import axiosInstance from "../../setup/axios";

function Login() {
  const [twofactor, setTwofactor] = useState("");

  const [showModal, setShowModal] = useState(false);

  const Form = () => {
    const [showPass, setShowPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showError, setError] = useState("");
    const { dispatch } = useContext(Context);
    const navigate = useNavigate();
    const [formState, setFormState] = useState({
      details: {
        email: "",
        password: "",
      },
      isRequesting: false,
      errors: {
        email: "",
        password: "",
        general: "",
      },
    });

    const [otp, setOtp] = useState("");

    const handleOtp = (otp: string) => {
      setOtp(otp);
    };

    const completeLogin = () => {
      setLoading(true);
      axiosInstance
        .post(`${Constants.API_BASE_URL}/auth/user/verify/two-factor`, {
          token: otp,
          accessToken: twofactor,
        })
        .then((res) => {
          dispatch({
            type: "TOKENS",
            payload: {
              token: res.data?.token,
              refreshToken: res.data?.refreshToken,
            },
          });
          localStorage.setItem(
            "tokens",
            JSON.stringify({
              token: res.data?.token,
              refreshToken: res.data?.refreshToken,
            }),
          );

          dispatch({ type: "USER_INFO", payload: res.data });
          sessionStorage.setItem("isLoggedIn", "true");
          dispatch({ type: "LOGIN" }); // Login action
          setLoading(false);
          setShowModal(false);

          // Check email verification status before redirection
          if (res.data.emailIdentity.emailVerified) {
            setTimeout(() => {
              navigate("/");
            }, 100);
          } else {
            // Redirect to email verification page if not verified
            navigate(`/email-verification/check/${formState.details.email}`);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err.response.data.message);
        });
    };

    const Login = (
      e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
    ) => {
      e.preventDefault();
      if (isFull()) {
        setFormState({ ...formState, isRequesting: true });
        let data = {
          ...formState.details,
          email: formState.details.email.toLowerCase().replace(/\s/g, ""),
        };

        axios
          .post(`${Constants.API_BASE_URL}/auth/user/login`, data)
          .then((res) => {
            setFormState({ ...formState, isRequesting: false });

            let data = res.data;
            let email = res.data.emailIdentity;

            // let phone = res.data.phoneIdentity;

            if (res.data.twoFactorToken) {
              // console.log('Setting showModal to true because twofactor is true.');

              setTwofactor(res.data?.twoFactorToken);
              // console.log("token" , res.data?.twoFactorToken)
              // console.log("token" , twofactor)

              setShowModal(true);
            } else {
              dispatch({
                type: "TOKENS",
                payload: {
                  token: res.data?.token,
                  refreshToken: res.data?.refreshToken,
                },
              });

              dispatch({
                type: "USER_INFO",
                payload: {
                  emailIdentity: {
                    email: email?.email,
                    emailVerified: email?.emailVerified,
                  },
                  // phoneIdentity: {
                  //     phone: phone?.phone,
                  //     phoneVerified: phone?.phoneVerified
                  // },
                  user: {
                    id: "",
                    updatedAt: "",
                    paymentMethod: "",
                    paymentMethodId: "",
                    ...res.data.user,
                  },
                  paymentMethod: {
                    ...res.data.user.paymentMethod,
                    updatedAt: "",
                    id: "",
                  },
                  roles: res.data.roles,
                },
              });
              try {
                Cookies.set("level", res.data?.accountLevel ?? "");
              } catch (e) {
                return false;
              }

              localStorage.setItem(
                "tokens",
                JSON.stringify({
                  token: res.data?.token,
                  refreshToken: res.data?.refreshToken,
                }),
              );

              if (!data.emailIdentity.emailVerified) {
                navigate(
                  `/email-verification/check/${formState.details.email}`,
                );
              }
              // else if(data.phoneIdentity === null){
              //     navigate(`/phone-verification`)
              // }else if(!data.phoneIdentity?.phoneVerified){
              //     navigate(`/phone-verification`)
              // }
              else {
                // sessionStorage.setItem("isLoggedIn", "true");
                dispatch({ type: "LOGIN" });
                setTimeout(() => {
                  if (
                    localStorage.getItem("dontShowMobileUpdateModal") !== "true"
                  ) {
                    localStorage.removeItem("seenMobileUpdateModal");
                  } else {
                    localStorage.setItem("seenMobileUpdateModal", "true");
                  }
                  window.location.href = "/dashboard";
                }, 100);
              }
            }
          })
          .catch((e) => {
            // console.log(e);
            setFormState({ ...formState, isRequesting: false });
            try {
              if (e.response?.status === 404) {
                setFormState((prev) => {
                  return {
                    ...prev,
                    errors: {
                      ...prev.errors,
                      general: "Oops! User account does not exist",
                    },
                  };
                });
              } else if (e.response?.status === 401) {
                setFormState((prev) => {
                  return {
                    ...prev,
                    errors: { ...prev.errors, password: "Wrong Password" },
                  };
                });
              } else {
                setFormState((prev) => {
                  return {
                    ...prev,
                    errors: {
                      ...prev.errors,
                      general: "Sorry! Something went wrong",
                    },
                  };
                });
              }
            } catch (e) {
              // console.log(e);
              return false;
            }
          });
      }
    };

    const isFull = () => {
      if (
        formState.details.email.length > 3 &&
        !isEmailValid(formState.details.email)
      ) {
        setFormState((prev) => {
          return {
            ...prev,
            errors: { ...prev.errors, email: "Email is not valid" },
          };
        });
      }
      if (formState.details.password.length < 8) {
        setFormState((prev) => {
          return {
            ...prev,
            errors: {
              ...prev.errors,
              password: "Password is not up to 8 characters",
            },
          };
        });
      }

      if (
        formState.details.password.length < 8 ||
        !isEmailValid(formState.details.email)
      ) {
        return false;
      }
      return true;
    };

    useEffect(() => {
      return () => {
        sessionStorage.setItem("curTime", "2000");
      };
    }, []);

    return (
      <form
        action=""
        onSubmit={(e) => Login(e)}
        className={`w-full h-fit cus-lg:pt-[4rem] px-1 ${style.loginForm}`}
      >
        {formState.isRequesting && <Loading />}

        {showModal && (
          <TwoFactorModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            twofactor={twofactor}
            handleOtp={handleOtp}
            otp={otp}
            completeLogin={completeLogin}
            loading={loading}
            showError={showError}
          />
        )}

        <div className="flex flex-col cus-lg:items-start items-center mb-10">
          <h1 className={`text-4xl font-bold ${style.headen}`}>Login</h1>
          <b className={`font-normal mt-3`}>Welcome Back !</b>
          {formState.errors.general.length > 0 && (
            <div className="animatefromleft text-[1rem] text-red-600">
              {formState.errors.general}
            </div>
          )}
        </div>

        <div className={`flex flex-col a-i-s ${style.formDetails}`}>
          <section className="flex a-i-s md:my-1">
            <div className="flex flex-col items-start w-full">
              <h1 className={`text-gray-800`}>
                Email{" "}
                {formState.errors.email.length > 0 && (
                  <small className="text-red-500">
                    - {formState.errors.email}
                  </small>
                )}
              </h1>
              <Input
                required
                type="text"
                placeholder="enter email address"
                setValue={(val) =>
                  setFormState({
                    ...formState,
                    details: { ...formState.details, email: val },
                  })
                }
                value={formState.details.email}
                className={`bg-white ${style.input}`}
              />
            </div>
          </section>
          <section className="flex a-i-s md:my-1">
            <div className="flex flex-col items-start w-full">
              <div className="flex">
                <i
                  onClick={() => setShowPass(!showPass)}
                  className={`fa fa-eye${
                    showPass ? "" : "-slash"
                  } cursor-pointer mr-1`}
                ></i>
                <h1 className={`text-gray-800`}>
                  Password{" "}
                  {formState.errors.password.length > 0 && (
                    <small className="text-red-500">
                      - {formState.errors.password}
                    </small>
                  )}
                </h1>
              </div>
              <Input
                required
                type={showPass ? "text" : "password"}
                placeholder="enter your password"
                setValue={(val) =>
                  setFormState({
                    ...formState,
                    details: { ...formState.details, password: val },
                  })
                }
                value={formState.details.password}
                className={`bg-white ${style.input}`}
              />
              <div className="w-full flex j-c-end">
                <small
                  className="text-blue cursor-pointer"
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot Password?
                </small>
              </div>
            </div>
          </section>
          <Button
            text={"Sign in"}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              Login(e);
            }}
            className={`bg-blue text-white w-[180px] md:w-[500px] lg:w-[500px] h-[43px] md:h-[50px] my-5`}
          />

          <small className="text-gray-500">
            Don't have an Account?{" "}
            <b
              className="text-blue cursor-pointer"
              onClick={() => navigate("/register")}
            >
              Create an Account
            </b>
          </small>
        </div>
      </form>
    );
  };

  return (
    <>
      <Wrapper
        form={<Form />}
        sideImage={
          <SideImage
            mainImage={authImage}
            logo={logo2}
            notice={"Login to start buying and selling."}
          />
        }
      />
    </>
  );
}

export default Login;
