import React from "react";
import { Route, Routes } from "react-router-dom";
import Register from "../Pages/Auth/Register";
import Confirmation from "../Pages/Auth/Confirmations";
import Login from "../Pages/Auth/Login";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import Dashboard from "../Pages/Main/Dashboard/Dashboard";
import Wallet from "../Pages/Main/Wallet/Wallet";
// import WalletDetails from '../Pages/Main/Wallet/WalletDetails';
import Swap from "../Pages/Main/Swap/Swap";
import NewsLearn from "../Pages/Main/NewsLearn/NewsLearn";
import Profile from "../Pages/Main/Profile/Profile";
import Settings from "../Pages/Main/Security/Settings";
import Faq from "../Pages/Main/FAQs/Faq";
import Orders from "../Pages/Main/Orders/Orders";
import KycPage from "../Pages/Main/Dashboard/Components/Kyc/KycPage";
import Support from "../Pages/Main/ContactSupport/Support";
import MoreSwapTransactions from "../Pages/Main/MoreSwapTransactions";
import MoreWalletTransactions from "../Pages/Main/Wallet/MoreWalletTransactions";
// import Buy from '../Pages/Main/Components/NewOrder/Buy';
import Buy from "../Pages/Main/Components/NewOrder/Buy";
import Sell from "../Pages/Main/Components/NewOrder/Sell";
import BuyDetails from "../Pages/Main/Components/NewOrder/BuyDetails";
import SellDetails from "../Pages/Main/Components/NewOrder/SellDetails";
import SellOrderDetails from "../Pages/Main/Components/NewOrder/SellOrderDetails";
import BuyOrderDetails from "../Pages/Main/Components/NewOrder/BuyOrderDetails";
import Kycmain from "../Pages/Main/Dashboard/Components/Kyc/components/Kycmain";
import ReferralPage from "../Pages/Main/Dashboard/ReferralPage";
import ChallengesPage from "../Pages/Main/Dashboard/ChallengePage";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/verify-account/" element={<KycPage />} />
      <Route path="/verify-account/:step" element={<KycPage />} />
      <Route path="/kyc" element={<Kycmain />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/orders/:type/:page" element={<Orders />} />
      <Route path="/swap" element={<Swap />} />
      <Route path="/wallet" element={<Wallet />} />
      <Route path="/news" element={<NewsLearn />} />
      <Route path="/news/:id" element={<NewsLearn />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/support" element={<Support />} />
      <Route path="/faq" element={<Faq />} />
      <Route
        path="/more-swap-transactions"
        element={<MoreSwapTransactions />}
      />
      <Route
        path="/more-wallet-transactions"
        element={<MoreWalletTransactions />}
      />
      <Route path="/buy" element={<Buy />} />
      <Route path="/sell" element={<Sell />} />
      <Route path="/buy-details" element={<BuyDetails />} />
      <Route path="/sell-details" element={<SellDetails />} />
      <Route path="/buy-order-details" element={<BuyOrderDetails />} />
      <Route path="/sell-order-details" element={<SellOrderDetails />} />
      <Route path="/referral" element={<ReferralPage />} />
      <Route path="/challenges" element={<ChallengesPage />} />
      {/* <Route path="/send-bitcoin" element={<Send />}  /> */}
      {/* <Route path='/wallet/details' element={<WalletDetails/>} /> */}

      {/* Authentications .... */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/email-verification/check/:email"
        element={<Confirmation type={"email"} />}
      />
      <Route
        path="/email-verification/code/:token"
        element={<Confirmation type={"email"} />}
      />
      <Route
        path="/phone-verification"
        element={<Confirmation type={"phone"} />}
      />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/forgot-password/code/:token" element={<ForgotPassword />} />
    </Routes>
  );
};

export default Routers;
