import React, { useContext, useState } from "react";
import Input from "../../../../Common/Components/Input/Input";
import Button from "../../../../Common/Components/Button/Button";
import axiosInstance from "../../../../setup/axios";
import Constants from "../../../../Utils/Constants";
import { loader } from "../../../../Common/Assets/Icons";
import Context from "../../../../store/Contex";
import Notify from "../../../../Helpers/Toast";

type Props = {
  otpNumber: string;
};

function ChangePasswordForm({ otpNumber }: Props) {
  const { dispatch } = useContext(Context);
  const [showPass, setShow] = useState(false);
  const [isRequesting, setRequesting] = useState(false);
  const [passState, setPassState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const ChangePassword = (e: React.FormEvent) => {
    e.preventDefault();
    if (passState.confirmPassword === passState.newPassword) {
      let data = {
        ...passState,
        otpNumber,
      };
      setRequesting(true);
      axiosInstance
        .post(`${Constants.API_BASE_URL}/auth/user/change/password`, data)
        .then((res) => {
          Notify("top-center", "Password changed", "success");
          setRequesting(false);
          dispatch({ type: "TURNOFF" });
        })
        .catch(() => {
          Notify("top-center", "Error, Something went wrong", "error");
          setRequesting(false);
        });
    } else {
      alert("Passwords do not match");
    }
  };
  return (
    <form
      onSubmit={(e) => ChangePassword(e)}
      className={`flex flex-col mb-10 w-[400px] relative`}
    >
      {isRequesting && (
        <div className="absolute w-full h-full bg-[white] flex j-c-c">
          <img src={loader} alt="" width={50} />
        </div>
      )}
      <div className="flex w-full my-3">
        <small>
          <i className="fa fa-arrow-left mr-1"></i>
        </small>
        <small>Back</small>
      </div>

      <div className="flex flex-col items-start w-full mt-4">
        <div className="flex justify-between space-between w-full">
          <small>Old Password *</small>
          <i
            onClick={() => setShow(!showPass)}
            className={`cursor-pointer fa fa-eye${!showPass ? "-slash" : ""} mr-1`}
          ></i>
        </div>
        <Input
          style={{ minWidth: "100%" }}
          type={showPass ? "text" : "password"}
          placeholder="Enter phone number "
          required
          value={passState.oldPassword}
          setValue={(val) => setPassState({ ...passState, oldPassword: val })}
        />
      </div>
      <div className="flex flex-col items-start w-full mt-4">
        <div className="flex justify-between space-between w-full">
          <small>New Password *</small>
          <i
            onClick={() => setShow(!showPass)}
            className={`cursor-pointer fa fa-eye${!showPass ? "-slash" : ""} mr-1`}
          ></i>
        </div>
        <Input
          style={{ minWidth: "100%" }}
          type={showPass ? "text" : "password"}
          placeholder="Enter phone number "
          required
          value={passState.newPassword}
          setValue={(val) => setPassState({ ...passState, newPassword: val })}
        />
      </div>
      <div className="flex flex-col items-start w-full mt-4">
        <div className="flex justify-between space-between w-full">
          <small>Confirm New Password *</small>
          <i
            onClick={() => setShow(!showPass)}
            className={`cursor-pointer fa fa-eye${!showPass ? "-slash" : ""} mr-1`}
          ></i>
        </div>

        <Input
          style={{ minWidth: "100%" }}
          type={showPass ? "text" : "password"}
          placeholder="Enter phone number"
          required
          value={passState.confirmPassword}
          setValue={(val) =>
            setPassState({ ...passState, confirmPassword: val })
          }
        />
      </div>

      <div className="flex w-full j-c-c mt-12">
        <Button
          text={"Save Changes"}
          onClick={() => {}}
          className={
            "bg-blue text-white px-8 w-full  h-[60px] rounded-lg flex j-c-c font-[700] text-md "
          }
        />
      </div>

      <div className="flex  w-full j-c-c mt-4 cursor-pointer">
        <div
          onClick={() => {
            dispatch({ type: "TURNOFF" });
          }}
          className={
            "bg-gray-300 text-gray-700 px-8 w-full h-[60px] rounded-lg flex j-c-c font-[700] text-md "
          }
        >
          Cancel
        </div>
      </div>

      <div></div>
    </form>
  );
}

export default ChangePasswordForm;
