import React, { useEffect, useState } from "react";
import PageWrapper from "../Components/Wrapper/Wrapper";
import style from "./style.module.css";
// import { data } from './newsData';
// import { useParams } from 'react-router-dom';
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import NewsLearnShema from "./NewsLearnShema";
import format from "../../../setup/formatDate";

interface NewsItem {
  author: string;
  content: string;
  description: string;
  url: string;
  title: string;
  urlToImage: string;
  id?: number;
  updatedAt: string;
  createdAt: string;
}

function NewsLearn() {
  // const navigate = useNavigate();
  // const params = useParams();
  const [isRequesting, setRequesting] = useState(false);
  const [data, setData] = useState<NewsItem[] | []>([]);

  useEffect(() => {
    setRequesting(true);
    axiosInstance
      .get(`${Constants.API_BASE_URL}/users/crypto/news`)
      .then((res) => {
        setData(res.data?.data);
        setRequesting(false);
      })
      .catch(() => {
        setRequesting(false);
      });
  }, []);

  // useEffect(()=>{
  //     if(params.id){
  //         let currentNews = data?.filter(news=>params?.id === `${news.id}`)[0]
  //         setNewsData(currentNews)
  //     }else{
  //         setNewsData(null)
  //     }
  //     // eslint-disable-next-line
  // }, [params.id])

  const Content = () => {
    return (
      <div className="w-[100%] mx-auto min-h-[calc(100%-50px)] flex flex-col items-start ">
        <div className="my-6 mb-10 flex flex-col items-start">
          <h1 className={`${style.headen} font-semibold text-3xl`}>News</h1>

          <section className="flex mt-3">
            <small className="mr-5 text-gray-400">All</small>
            <small className="mr-5 text-gray-400">Trending</small>
          </section>
        </div>

        {isRequesting ? (
          <NewsLearnShema />
        ) : (
          <>
            {data?.map((news, idx) => {
              return (
                <a
                  href={news.url}
                  target="_blank"
                  rel="noreferrer"
                  key={idx}
                  className={`${style.newsCard} w-full rounded-xl flex mb-5 cursor-pointer hover:bg-gray-100`}
                  style={{ boxShadow: "0 0 20px 1px rgba(242, 248, 255, 1)" }}
                >
                  <div
                    className={`${style.img} min-w-[250px] max-w-[250px] h-[150px] mr-2`}
                  >
                    <img
                      src={news.urlToImage}
                      alt=""
                      className="object-cover w-full h-full"
                    />
                  </div>
                  <h1
                    className={`font-semibold text-xl ${style.MobTitle} text-left`}
                  >
                    {news.title}
                  </h1>
                  <small className={` text- ${style.MobSubDesc} text-left`}>
                    {news.description?.slice(0, 100)}
                  </small>
                  <div
                    className={`w-full flex mt-3 ${style.MobDate} justify-end `}
                  >
                    <div
                      className={`text-gray-500 text-sm text-right w-full flex`}
                    >
                      <small className="mr-2">
                        {format(news.updatedAt)[0]}
                      </small>
                      <small>{format(news.updatedAt)[1]}</small>
                    </div>
                  </div>

                  <div
                    className={`flex flex-col items-start text-left ${style.subDes}`}
                  >
                    <h1 className="font-semibold text-xl">{news.title}</h1>
                    <small className="mt-1">
                      {news.description.slice(0, 50)}
                    </small>

                    <div className="mt-5 text-gray-400 flex ">
                      <small className="mr-2">
                        {format(news.updatedAt)[0]}
                      </small>
                      <small className="mr-2">
                        {format(news.updatedAt)[1]}
                      </small>
                    </div>
                  </div>
                </a>
              );
            })}
          </>
        )}
      </div>
    );
  };

  return <PageWrapper content={<Content />} />;
}

export default NewsLearn;
