import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import OtpInput from "react-otp-input";
import Button from "../../../Common/Components/Button/Button";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import WalletDetails from "./WalletDetails";
import { dotSpinner } from "ldrs";

function WalletSetup(walletdata: any) {
  const [transactionPin, setTransactionPin] = useState("");
  const [confirmTransactionPin, setConfirmTransactionPin] = useState("");
  const [createWallet, setCreateWallet] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  dotSpinner.register();

  useEffect(() => {
    // Simulate API call delay
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay time as needed
  }, []);

  const handleOtpChange = (transactionPin: string) => {
    setTransactionPin(transactionPin);
  };

  const handleConfirmOtpChange = (confirmTransactionPin: string) => {
    setConfirmTransactionPin(confirmTransactionPin);
  };

  const createBitcoinWallet = () => {
    const walletpin = {
      transactionPin: transactionPin,
      confirmTransactionPin: confirmTransactionPin,
    };
    setLoading(true);

    axiosInstance
      .post(`${Constants.API_BASE_URL}/wallet/bitcoin`, walletpin)
      .then((res) => {
        setCreateWallet(true);
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        setCreateWallet(false);

        console.error("Error creating wallet:", err);
      });
  };

  return (
    <div className="w-full min-h-[calc(100%-50px)] flex flex-col items-start">
      {loading ? (
        <div className="mx-auto w-full pb-72 ">
          <div className="pt-72 ">
            <l-dot-spinner size="80" speed="0.9" color="black"></l-dot-spinner>
          </div>

          <div>
            <div className="mt-16">
              <h2 className="mt-12">Loading...</h2>
            </div>
          </div>
        </div>
      ) : createWallet ? (
        <WalletDetails />
      ) : (
        <>
          <div className="mt-6 items-start">
            <h1 className={`${style.header} font-semibold text-3xl`}>
              Set up your wallet
            </h1>
          </div>
          <div className="font-bold text-2xl mt-16">
            <h2> Create transaction pin</h2>
          </div>

          <div className="mt-4">
            <h3 className="text-left">
              Set up a transaction pin to authenticate your withdrawal
              transaction
            </h3>
          </div>
          <div className="mt-8">Enter a PIN</div>
          <div className="w-full ">
            <OtpInput
              value={transactionPin}
              onChange={handleOtpChange}
              numInputs={6}
              renderSeparator={<span className="mx-2"></span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                border: "1px solid #d1d5db",
                textAlign: "center",
                fontSize: "1.5rem",
                marginTop: "1rem",
                marginBottom: "1rem",
                borderRadius: "10px",
                maxWidth: "50%",
                width: "10%",
                height: "60px",
                maxHeight: "60px",
                boxSizing: "border-box",
              }}
              containerStyle={{
                display: "flex",
                width: "100%",
                maxWidth: "100%",
                maxHeight: "100%",
                height: "100%",
              }}
              inputType="password"
            />
          </div>

          <div className="mt-4">Verify your PIN</div>

          <OtpInput
            value={confirmTransactionPin}
            onChange={handleConfirmOtpChange}
            numInputs={6}
            renderSeparator={<span className="mx-2"></span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              border: "1px solid #d1d5db",
              textAlign: "center",
              fontSize: "1.5rem",
              marginTop: "1rem",
              marginBottom: "1rem",
              borderRadius: "10px",
              width: "10%",
              height: "4rem",
              maxWidth: "50%",
              boxSizing: "border-box",
            }}
            containerStyle={{
              display: "flex",
              width: "100%",
              maxWidth: "100%",
              maxHeight: "100%",
              height: "100%",
            }}
            inputType="password"
          />
          <Button
            text={"Create Wallet"}
            onClick={createBitcoinWallet}
            className={`bg-prevBlue text-white w-[70%] h-[50px] my-5`}
          />
        </>
      )}
    </div>
  );
}

export default WalletSetup;
