import React, { useContext, useEffect, useReducer, useState } from "react";
import { bitcoin, teather } from "../../../../Common/Assets/Icons";
import Input from "../../../../Common/Components/Input/Input";
import Button from "../../../../Common/Components/Button/Button";
import Select from "../../../../Common/Components/Input/Select";
import style from "./style.module.css";
import Constants from "../../../../Utils/Constants";
import axiosInstance from "../../../../setup/axios";
import Cookies from "js-cookie";
import Loading from "../../../../Common/Components/Loading/Loading";
import PaymentMethod from "../../Security/components/PaymentMethod";
import Notify from "../../../../Helpers/Toast";
import Context from "../../../../store/Contex";
import alert from "../../../../Common/Assets/Images/exclamation-circle-solid.svg";

type actionType = {
  type:
    | "ORDER_TYPE"
    | "CURRENCY"
    | "WALLET"
    | "AMOUNT"
    | "PRIORITY"
    | "PAYMENT"
    | "RECEIVE_AMOUNT"
    | "ORDERINFO"
    | "CEDI_AMOUNT";
  payload: any;
};
const initial = {
  orderType: "sell",
  currency: "btc",
  wallet: "",
  amount: "0",
  orderPriority: "normal",
  paymentType: "",
  receiveAmount: 0,
  cediAmount: 0,
};

const reducer = (state = initial, action: actionType) => {
  switch (action.type) {
    case "ORDER_TYPE":
      return { ...state, orderType: action.payload };
    case "CURRENCY":
      return { ...state, currency: action.payload };
    case "AMOUNT":
      return { ...state, amount: action.payload };
    case "WALLET":
      return { ...state, wallet: action.payload };
    case "PRIORITY":
      return { ...state, orderPriority: action.payload };
    case "PAYMENT":
      return { ...state, paymentType: action.payload };
    case "RECEIVE_AMOUNT":
      return { ...state, receiveAmount: action.payload };
    case "CEDI_AMOUNT":
      return { ...state, cediAmount: action.payload };
    default:
      return state;
  }
};

type props = {
  close?: (data: any) => void;
};

function NewOrder({ close }: props) {
  const paymentMethod = useContext(Context).state?.userInfo?.paymentMethod;
  const userLevel = Cookies.get("level") ?? "Limited";
  const level = {
    cap: 1000,
    Limited: 999,
    Intermediate: 5000,
    Full: 10000,
  };

  const [order, setOrder] = useReducer(reducer, initial);
  const [width, setWidth] = useState(0);
  // const [isValidAddress, setIsValidAddress] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [amountError, setAmountError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [limitErrors, setLimitErrors] = useState("");
  const [state, setState] = useState({
    isRequesting: false,
    loadText: "",
    selectedCurrency: {
      code: "",
      data: {
        buyRate: 0,
        sellRate: 0,
        normalProcessingFee: 0,
        priorityProcessingFee: 0,
        instock: false,
        minimumAmount: 0,
      },
    },
    assets: [
      {
        code: "",
        data: {
          buyRate: 0,
          sellRate: 0,
          normalProcessingFee: 0,
          priorityProcessingFee: 0,
          instock: false,
          minimumAmount: 0,
        },
      },
    ],
    fees: 1,
    buyRate: 1,
    sellRate: 1,
    amountInGHS: 0,
    amountInUSD: 0,
    hasPendingOrders: false,
    showPaymentMethod: false,
  });

  const hasErrors = !!amountError || !!errorMessage;

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;

    try {
      setOrder({ ...order, type: "WALLET", payload: value });
    } catch (error) {
      // console.error("Address validation failed:", error);
    }
  };
  useEffect(() => {
    loadCurrencies();
    // eslint-disable-next-line
  }, [order.orderType]);

  function loadCurrencies() {
    setState({ ...state, isRequesting: true });

    axiosInstance
      .get(`${Constants.API_BASE_URL}/assets`)
      .then((res) => {
        // console.log("res", res.data);
        let assets = res.data;
        setState((prev) => {
          // console.log("assets", assets);
          return {
            ...prev,
            isRequesting: false,
            assets,
            selectedCurrency:
              assets[0].walletId === "BTC" ? assets[0] : assets[2],
            fees:
              assets[0].walletId === "BTC"
                ? parseFloat(assets[0]?.data?.normalProcessingFee)
                : assets[2]?.data?.normalProcessingFee,
            buyRate:
              assets[0]?.walletId === "BTC"
                ? assets[0]?.data?.buyRate
                : assets[2]?.data?.buyRate,
            sellRate:
              assets[0]?.walletId === "BTC"
                ? assets[0]?.data?.sellRate
                : assets[2]?.data?.sellRate,
            instock:
              assets[0].walletId === "BTC"
                ? assets[0]?.data?.instock
                : assets[2]?.data?.instock,
            minimumAmount:
              assets[0].walletId === "BTC"
                ? assets[0]?.data?.minimumAmount
                : assets[2]?.data?.minimumAmount,
            normalProcessingFee:
              assets[0].walletId === "BTC"
                ? assets[0]?.data?.normalProcessingFee
                : assets[2]?.data?.normalProcessingFee,
            priorityProcessingFee:
              assets[0].walletId === "BTC"
                ? assets[0]?.data?.priorityProcessingFee
                : assets[2]?.data?.priorityProcessingFee,
          };
        });

        // if (
        //   (order.orderType === "buy" &&
        //     order.currency === "btc" &&
        //     assets[0]?.data?.instock === false) ||
        //   (order.orderType === "buy" &&
        //     order.currency === "usdt" &&
        //     assets[1]?.data?.instock === false)
        // ) {
        //   Notify(
        //     "top-center",
        //     "Sorry, we are currently out of stock, \nTry selling.",
        //     "info"
        //   );
        //   setIsButtonDisabled(true);
        // } else {
        //   setIsButtonDisabled(false);
        // }

        if (order.orderType === "buy") {
          SetTotalBuyCost(
            assets[0].walletId.toLowerCase() === order.currency
              ? parseFloat(assets[0]?.data?.normalProcessingFee)
              : assets[2]?.data?.normalProcessingFee,
            order.amount,
            assets[0]?.walletId.toLowerCase() === order.currency
              ? assets[0]?.data?.buyRate
              : assets[2]?.data?.buyRate,
          );
        } else {
          SetTotalSellCost(
            order.amount,
            assets[0]?.walletId.toLowerCase() === order.currency
              ? assets[0]?.data?.sellRate
              : assets[2]?.data?.sellRate,
          );
        }
      })
      .catch((e) => {
        setState({ ...state, isRequesting: false });
      });
  }

  function formatTo2DP(amount: number) {
    return parseFloat((Math.round(amount * 100) / 100).toFixed(2));
  }

  function SetTotalBuyCost(fee: number, usd: number, rate: number) {
    let totalCostInUSD = fee + +usd;
    let totalCostInGHS = formatTo2DP(totalCostInUSD * rate);

    setState((prev) => {
      return {
        ...prev,
        amountInGHS: totalCostInGHS,
        amountInUSD: totalCostInGHS,
      };
    });
    setOrder({ type: "CEDI_AMOUNT", payload: totalCostInGHS });
  }

  function SetTotalSellCost(usd: number, rate: number) {
    let totalCostInGHS = formatTo2DP(usd * rate);
    setState((prev) => {
      return { ...prev, amountInGHS: usd, amountInUSD: totalCostInGHS };
    });
    setOrder({ type: "CEDI_AMOUNT", payload: totalCostInGHS });
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 630) {
        setWidth(window.innerWidth - 80);
      } else {
        setWidth(0);
      }
    });
    if (window.innerWidth < 630) {
      setWidth(window.innerWidth - 80);
    } else {
      setWidth(0);
    }
  }, [width]);

  useEffect(() => {
    loadCurrencies();

    axiosInstance
      .get(`${Constants.API_BASE_URL}/orders/has/pending`)
      .then((res) => {
        if (res.data) {
          setState({ ...state, hasPendingOrders: true });
        }
      })
      .catch(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (parseFloat(order.amount) >= 10000) {
      setLimitErrors(
        `Contact support on whatsapp for orders 10,000 USD and above`,
      );
    } else if (
      parseFloat(order.amount) >
      (userLevel === "Limited"
        ? level.Limited
        : userLevel === "Intermediate"
          ? level.Intermediate
          : level.Full)
    ) {
      setLimitErrors(
        `You cannot sell more than  ${
          userLevel === "Limited"
            ? level.cap
            : userLevel === "Intermediate"
              ? level.Intermediate
              : level.Full
        }.00 ${order.currency.toUpperCase()}. Please upgrade your account to increase limit`,
      );
    } else {
      setLimitErrors("");
    }
    if (order.orderType === "buy") {
      // SetTotalBuyCost(state.fees, order.amount, state.rate)
    } else {
      SetTotalSellCost(order.amount, state.sellRate);
    }
    // eslint-disable-next-line
  }, [order.amount]);
  // console.log(state.selectedCurrency?.code)
  const updateForm = (name: string, value: any) => {
    if (name === "orderType") {
      setOrder({ type: "ORDER_TYPE", payload: value });
      value === "sell"
        ? SetTotalSellCost(order.amount, state.sellRate)
        : SetTotalBuyCost(state.fees, order.amount, state.buyRate);
    }
    if (order.orderType === "buy") {
      switch (name) {
        case "amount":
          let amount = value ? parseInt(value, 10).toString() : 0;
          setOrder({ type: "AMOUNT", payload: amount ? amount : 0 });
          SetTotalBuyCost(state.fees, parseInt(value), state.buyRate);
          return;
        case "currency":
          let selectedCurrency = state.selectedCurrency;
          let buyRate = state.buyRate;
          let sellRate = state.sellRate;

          if (JSON.stringify(state.assets[0]).toLowerCase().includes(value)) {
            selectedCurrency = state.assets[0];
            buyRate = state.assets[0]?.data?.buyRate;
            sellRate = state.assets[0]?.data?.sellRate;
          } else {
            selectedCurrency = state.assets[2];
            buyRate = state.assets[2]?.data?.buyRate;
            sellRate = state.assets[2]?.data?.sellRate;
          }

          setOrder({ type: "CURRENCY", payload: value });
          setState((prev) => {
            return { ...prev, selectedCurrency, buyRate, sellRate };
          });
          SetTotalBuyCost(state.fees, order.amount, buyRate);

          return;
        case "priority":
          if (value === "high") {
            setState({
              ...state,
              fees: +state.selectedCurrency?.data?.priorityProcessingFee,
            });
            SetTotalBuyCost(
              +state.selectedCurrency?.data?.priorityProcessingFee,
              order.amount,
              state.buyRate,
            );
          } else {
            setState({
              ...state,
              fees: +state.selectedCurrency?.data?.normalProcessingFee,
            });
            SetTotalBuyCost(
              +state.selectedCurrency?.data?.normalProcessingFee,
              order.amount,
              state.buyRate,
            );
          }
          return;
        default:
          return;
      }
    } else {
      switch (name) {
        case "amount":
          let amount = value ? parseInt(value, 10).toString() : 0;
          setOrder({ type: "AMOUNT", payload: amount ? amount : 0 });
          SetTotalSellCost(value, state.sellRate);
          return;
        case "currency":
          setOrder({ type: "CURRENCY", payload: value });

          let selectedCurrency = state.selectedCurrency;
          let buyRate = +state.buyRate;
          let sellRate = +state.sellRate;

          if (JSON.stringify(state.assets[0]).toLowerCase().includes(value)) {
            selectedCurrency = state.assets[0];
            buyRate = +state.assets[0]?.data?.buyRate;
            sellRate = +state.assets[0]?.data?.sellRate;
          } else {
            selectedCurrency = state.assets[2];
            buyRate = +state.assets[2]?.data?.buyRate;
            sellRate = +state.assets[2]?.data?.sellRate;
          }

          setState((prev) => {
            return { ...prev, selectedCurrency, buyRate, sellRate };
          });

          SetTotalSellCost(order.amount, sellRate);
          return;
        default:
          return;
      }
    }
  };

  //   const handleTransaction = () => {
  //     if (
  //         ((order.orderType === "buy" || order.orderType === "sell") &&
  //             order.amount < state.assets[0]?.data?.minimumAmount) ||
  //         ((order.orderType === "buy" || order.orderType === "sell") &&
  //             order.amount < state.assets[1]?.data?.minimumAmount)
  //     ) {
  //         Notify(
  //             "top-center",
  //             `Minimum order amount is ${
  //             order.orderType === "buy"
  //                 ? state.assets[0]?.data?.minimumAmount
  //                 : state.assets[1]?.data?.minimumAmount
  //             }. \nCannot proceed with the transaction.`,
  //             "info"
  //         );
  //         setOrder({ type: "AMOUNT", payload: 0 });
  //     } else if (
  //         (order.orderType === "buy" &&
  //             order.currency === "btc" &&
  //             state.assets[0]?.data?.instock === false) ||
  //         (order.orderType === "buy" &&
  //             order.currency === "usdt" &&
  //             state.assets[1]?.data?.instock === false)
  //     ) {
  //         Notify(
  //             "top-center",
  //             "Sorry, we are currently out of stock, \nTry selling.",
  //             "info"
  //         );
  //         setOrder({ type: "AMOUNT", payload: 0 });
  //     }
  // };

  useEffect(() => {
    if (order.amount === "0") {
      setAmountError("");
    } else {
      if (
        ((order.orderType === "buy" || order.orderType === "sell") &&
          state.assets[0]?.data?.minimumAmount > parseFloat(order.amount)) ||
        ((order.orderType === "buy" || order.orderType === "sell") &&
          state.assets[2]?.data?.minimumAmount > parseFloat(order.amount))
      ) {
        setAmountError(
          `Minimum order amount is ${
            order.orderType === "buy"
              ? state.assets[0]?.data?.minimumAmount
              : state.assets[2]?.data?.minimumAmount
          }. Cannot proceed with the transaction.`,
        );
        setIsButtonDisabled(true);
      } else {
        setAmountError("");
        setIsButtonDisabled(false);
      }
    }

    updateForm("priority", order.orderPriority);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, order.orderType, order.amount]);

  useEffect(() => {
    validateAddress(order.wallet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.wallet, order.currency]);

  const validateAddress = async (address: string) => {
    if (order.orderType === "sell") {
      setErrorMessage("");
      setIsButtonDisabled(false);
      return;
    }

    try {
      const response = await axiosInstance.get(
        `${
          Constants.API_BASE_URL
        }/assets/validate/address?address=${address}&currency=${
          order.currency === "btc" ? "btc" : "trx"
        }`,
      );
      // console.log("response:", response)
      // console.log("response:", order.currency)
      // setIsValidAddress(response.data);

      if (response.data === false) {
        setErrorMessage("Invalid address. Please enter a valid address.");
        setIsButtonDisabled(true);
      } else {
        setErrorMessage("");
        setIsButtonDisabled(false);
      }
    } catch (error) {
      // console.error("Error validating address:", error);
    }
  };

  // console.log("currency", state.selectedCurrency)
  const SubmitOrder = (e?: React.FormEvent) => {
    e?.preventDefault();

    if (order.orderType === "sell" && !Boolean(paymentMethod?.network)) {
      Notify("top-center", "Please add a payment method", "warning");
      setState((prev) => {
        return { ...prev, showPaymentMethod: true };
      });
      return;
    }

    if (parseFloat(order.amount) >= 10000) {
      window.open("https://wa.me/message/HT3MBH3X46FPG1", "_blank");
    } else {
      setState({ ...state, isRequesting: true });
      switch (order.orderType) {
        case "buy":
          let data = {
            amount: +order.amount,
            rate: +state.buyRate,
            walletId:
              state.selectedCurrency?.code === "BTC" ? "BTC" : "USDT_TRC20",
            fee: +state.fees,
            feeType:
              order.orderPriority === "normal"
                ? "normalProcessingFee"
                : "priorityProcessingFee",
            asset: state.selectedCurrency?.code === "BTC" ? "BTC" : "USDT",
            paymentType: order.paymentType,
            walletAddress: order.wallet,
          };
          axiosInstance
            .post(Constants.API_BASE_URL + "/orders/buy", data)
            .then((response) => {
              close && close(response.data?.data);
              setState({ ...state, isRequesting: false });
              // console.log("data",data);
            })
            .catch((error: any) => {
              // console.log(error?.response?.data);
              // console.log("data",data);
              setState({ ...state, isRequesting: false });
            });
          break;
        default:
          let Selldata = {
            amount: +order.amount,
            rate: +state.sellRate,
            walletId:
              state.selectedCurrency?.code === "BTC" ? "BTC" : "USDT_TRC20",
            asset: state.selectedCurrency?.code === "BTC" ? "BTC" : "USDT",
            paymentType: order.currency === "btc" ? "btc" : "usdt_trc20",
          };
          axiosInstance
            .post(Constants.API_BASE_URL + "/orders/sell", Selldata)
            .then((response) => {
              setState({ ...state, isRequesting: false });
              close && close(response.data?.data);
            })
            .catch((error) => {
              setState({ ...state, isRequesting: false });
              // console.log("error", error);
            });
          break;
      }
    }
  };

  if (!state.showPaymentMethod) {
    return (
      <form
        onSubmit={(e) => SubmitOrder(e)}
        className={`${style.OrderContainer} mx-auto`}
      >
        {state.isRequesting && (
          <Loading
            loadText={state.loadText !== "" ? state.loadText : "Loading..."}
          />
        )}
        <section
          className={`flex flex-col w-[500px] ${style.orderInner} px-8 overflow-hidden`}
        >
          <h1 className={`font-semibold text-xl ${style.headen}`}>New Order</h1>
          <p className="text-gray-400">Create a new order</p>
          <br />

          <div className="flex mb-12 h-[50px] w-fit border-theme border-2 border-solid rounded-md overflow-hidden">
            <aside
              // onClick={() => {
              //   Notify('top-center', 'Sorry, we are currently out of stock, \nTry selling.', 'info')
              //   updateForm("orderType", "buy");
              // }}
              onClick={() => updateForm("orderType", "buy")}
              className={`cursor-pointer w-[125px] h-full bg-${
                order.orderType === "buy" ? "theme" : "white"
              } text-${
                order.orderType === "buy" ? "white" : "theme"
              } flex j-c-c`}
            >
              Buy Order
            </aside>
            <aside
              onClick={() => updateForm("orderType", "sell")}
              className={`cursor-pointer w-[125px] h-full bg-${
                order.orderType === "sell" ? "theme" : "white"
              } text-${
                order.orderType === "sell" ? "white" : "theme"
              } flex j-c-c`}
            >
              Sell Order
            </aside>
          </div>

          <div className={`flex flex-col ${style.amountHead}`}>
            <h1 className={``}>Amount to {order.orderType}</h1>
            <div className="flex items-center">
              <b className="mr-2 text-4xl text-gray-400">$</b>
              <input
                value={order.amount}
                onChange={(e) => {
                  updateForm("amount", e.target.value);
                }}
                type="text"
                name=""
                id=""
                placeholder="$0.0"
                className={`style.amountInput w-52 border-gray-200 border-solid border-[1px] rounded-lg h-[80px] text-center text-4xl text-gray-500 font-bold`}
              />
            </div>
            {amountError && (
              <small className="text-red-600 mt-2">{amountError}</small>
            )}
            {limitErrors.length > 0 && (
              <small className="text-red-600 mt-2">{limitErrors}</small>
            )}
          </div>
          <br />

          {parseFloat(order.amount) < 10000.1 && (
            <div className="w-full">
              <div className="flex flex-col mt-10">
                <p className="text-gray-500">Choose Currency</p>

                <div className="flex a-i-s mt-2">
                  <section
                    style={{
                      background: `${
                        order.currency === "btc"
                          ? "rgb(0,80,255, .05)"
                          : "white"
                      }`,
                    }}
                    onClick={() => {
                      updateForm("currency", "btc");
                    }}
                    className={`flex flex-col j-c-c p-10 border-${
                      order.currency === "btc" ? 2 : 0
                    } border-dashed rounded-lg`}
                  >
                    <div
                      className={`flex j-c-c bg-btc h-[50px] w-[50px] rounded-xl`}
                    >
                      <img src={bitcoin} alt="" />
                    </div>
                    <p>BTC</p>
                  </section>
                  <section
                    style={{
                      background: `${
                        order.currency === "usdt"
                          ? "rgb(0,80,255, .05)"
                          : "white"
                      }`,
                    }}
                    onClick={() => {
                      updateForm("currency", "usdt");
                    }}
                    className={`flex flex-col j-c-c p-10 border-${
                      order.currency === "usdt" ? 2 : 0
                    } border-dashed rounded-lg`}
                  >
                    <div className="flex j-c-c bg-usdt h-[50px] w-[50px] rounded-xl">
                      <img src={teather} alt="" />
                    </div>
                    <div className="flex flex-col">
                      <p>USDT</p>
                      <small className="text-gray-400">(TRC20)</small>
                    </div>
                  </section>
                </div>
              </div>
              <br />

              {(order.orderType === "buy" &&
                order.currency === "btc" &&
                state.assets[0]?.data?.instock === false) ||
              (order.orderType === "buy" &&
                order.currency === "usdt" &&
                state.assets[2]?.data?.instock === false) ? (
                <div className=" flex flex-col items-center ">
                  <div>
                    <img src={alert} className="w-28 h-28 mb-4" alt="Alert" />
                  </div>
                  <div className="font-bold">Out of stock</div>
                  <div className="mt-4 mb-10 text-[14px]">
                    We are currently out of stock. Please check back later
                  </div>
                </div>
              ) : (
                <div>
                  {order.orderType === "buy" && (
                    <div className="flex flex-col a-i-s w-full">
                      <p>
                        {order.currency === "btc" ? "Bitcoin" : "Tether"} wallet
                        address
                      </p>
                      <Input
                        required
                        style={{ minWidth: "100%" }}
                        placeholder="wallet address"
                        type="text"
                        value={order.wallet}
                        setValue={(val) =>
                          setOrder({ type: "WALLET", payload: val })
                        }
                        onChange={handleInputChange}
                      />
                      {errorMessage && (
                        <small className="text-red-600 mt-2">
                          {errorMessage}
                        </small>
                      )}

                      <br />

                      {order.currency === "btc" && (
                        <div className="w-full flex flex-col a-i-s mb-10">
                          <p>Set Fee Priority</p>
                          <div className="flex">
                            <aside className="flex mr-3">
                              <input
                                className="text-theme"
                                checked={order.orderPriority === "normal"}
                                readOnly
                                onClick={() =>
                                  setOrder({
                                    type: "PRIORITY",
                                    payload: "normal",
                                  })
                                }
                                type="radio"
                                name=""
                                id=""
                              />
                              <small className="ml-1">
                                Normal ( $
                                {state.assets[0]?.data.normalProcessingFee} )
                              </small>
                            </aside>
                            <aside className="flex">
                              <input
                                className="text-theme"
                                checked={order.orderPriority === "high"}
                                readOnly
                                onClick={() =>
                                  setOrder({
                                    type: "PRIORITY",
                                    payload: "high",
                                  })
                                }
                                type="radio"
                                name=""
                                id=""
                              />
                              <small className="ml-1">
                                Fast ( $
                                {state.assets[0]?.data.priorityProcessingFee} )
                              </small>
                            </aside>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="flex flex-col a-i-s w-full">
                    {order.orderType === "buy" ? (
                      <p>
                        You will send <small>( Rate: {state?.buyRate} )</small>
                      </p>
                    ) : (
                      <p>
                        You will receive{" "}
                        <small>( Rate: {state?.sellRate} )</small>
                      </p>
                    )}
                    <div className="w-full flex items-center">
                      <div className="flex justify-center items-center min-w-[25px]">
                        <b className="text-2xl text-theme">₵</b>
                      </div>
                      <h1 className="text-4xl font-bold text-theme">{`${
                        order.amount ? order.cediAmount?.toFixed(2) : "0.00"
                      }`}</h1>
                    </div>
                  </div>
                  <br />

                  <div className="flex flex-col a-i-s w-full">
                    <p>Select Payment Method</p>
                    <Select
                      required
                      style={{ minWidth: "100%" }}
                      value={order.paymentType}
                      setValue={(val) =>
                        setOrder({ type: "PAYMENT", payload: val })
                      }
                      options={[
                        {
                          id: "",
                          name: "Network Type",
                        },
                        {
                          id: "mtn",
                          name: "MTN Mobile Money",
                        },
                      ]}
                    />
                    <small
                      className="underline text-theme"
                      onClick={() =>
                        setState({ ...state, showPaymentMethod: true })
                      }
                    >
                      Add Payment Method
                    </small>
                  </div>

                  {order.amount > 0 && (
                    <Button
                      disabled={
                        limitErrors.includes("upgrade") ||
                        isButtonDisabled ||
                        hasErrors
                      }
                      text={
                        parseFloat(order.amount) >= 10000
                          ? "Continue on Whatsapp"
                          : "Continue"
                      }
                      onClick={() => {
                        // handleTransaction();
                      }}
                      className={`${
                        limitErrors.includes("upgrade")
                          ? "bg-gray-200 text-gray-300"
                          : isButtonDisabled || hasErrors
                            ? "bg-gray-200 text-gray-300"
                            : "bg-blue text-white"
                      } my-4 h-[50px] px-5 w-full`}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </section>
      </form>
    );
  } else {
    return (
      <PaymentMethod
        showBack={true}
        close={() => {
          setState({ ...state, showPaymentMethod: false });
          SubmitOrder();
        }}
      />
    );
  }
}

export default NewOrder;
