import React from "react";
import svg from "../../../Common/Assets/Icons/Snake.gif";

type prop = {
  loadText?: string;
};

function Loading({ loadText }: prop) {
  return (
    <div className="z-50 flex justify-center items-center w-screen h-screen fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.6)]">
      <section className="flex flex-col j-c-c max-w-[200px] min-w-[180px] min-h-[130px] rounded-lg bg-white p-5">
        <img src={svg} alt="" width={40} />
        {loadText && (
          <b className="font-normal text-[14px] mt-5 text-theme">{loadText}</b>
        )}
      </section>
    </div>
  );
}

export default Loading;
