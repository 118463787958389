import React from "react";
import PageWrapper from "../Components/Wrapper/Wrapper";
import { faqs } from "./faqs";
import style from "./style.module.css";

function Faq() {
  const Content = () => {
    const questions = document.getElementsByClassName("questions");

    const collapseQue = (id: string) => {
      for (let x = 0; x < questions.length; x++)
        questions[x].children[1].classList.add("hide");
      document.querySelector(`#${id}`)?.classList.remove("hide");
    };

    return (
      <div className="w-full mx-auto min-h-[calc(100%-50px)] flex flex-col items-start mb-10">
        <div className="my-6 mb-10">
          <h1 className="font-semibold text-2xl">Frequently Asked Questions</h1>
        </div>

        <div className="flex flex-col items-start w-full">
          {faqs.map((question, idx) => {
            return (
              <div key={idx} className="questions w-full overflow-hidden">
                <div
                  onClick={() => collapseQue(`faq${idx}`)}
                  className="z-10 flex cursor-pointer bg-white hover:text-theme justify-between w-full mb-3 h-[60px] px-5 rounded-lg"
                  style={{ boxShadow: "0 0 8px 0px rgb(0,0,0,.05)" }}
                >
                  <p>
                    <b className="text-xl mr-1">Q</b>
                    {question.question}
                  </p>
                  <i className="fa fa-caret-right text-gray-500"></i>
                </div>
                <div
                  id={`faq${idx}`}
                  className="hide z-0 group-focus: flex flex-col items-start w-full mb-3 min-h-[60px] text-left px-5 rounded-lg"
                >
                  {typeof question.ans === "object" ? (
                    <div className="w-full flex flex-col items-start">
                      {question.ans.map((ans, idx) => {
                        return (
                          <small
                            key={`point${idx}`}
                            className="text-blue font-semibold text-[15px] my-1 transition-opacity"
                          >
                            {idx + 1}. <b className="mx-1"></b> {ans}
                          </small>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="text-blue font-semibold transition-opacity">
                      A: {question.ans}
                    </p>
                  )}

                  {question.note && (
                    <div className="text-blue font-semibold my-5">
                      {question.note}
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          <br />
          <div className="px-3 flex flex-col items-start">
            <h1 className="text-3xl">In Summary:</h1>
            <div className="w-full flex flex-col text-left items-start pt-2">
              <aside className={`w-full flex items-start ${style.sum1}`}>
                <div className="flex flex-col items-start mr-8">
                  <h2 className="text-xl font-semibold text-theme">
                    Non-verified accounts
                  </h2>
                  <section className="flex flex-col items-start mt-1">
                    <p>- Max $100 buy orders</p>
                    <p>- Max $1000 sell orders</p>
                  </section>
                </div>
                <div className="flex flex-col items-start">
                  <h2 className="text-xl font-semibold text-theme">
                    Verified accounts
                  </h2>
                  <section className="flex flex-col items-start mt-1">
                    <p>- Max $1000 buy orders</p>
                    <p>- Unlimited sell orders</p>
                    <b className="my-1"></b>
                  </section>
                </div>
              </aside>
              <p>
                <b className="text-xl">Note: </b>Unlimited OTC trading via
                WhatsApp for verified accounts.
              </p>
              <b className="my-5"></b>

              <div className="flex flex-col my-1 items-start">
                <h2 className="text-xl font-semibold text-theme">
                  What are the steps to get started on Hodlpay?
                </h2>
                <aside className="flex items-center w-[50%]">
                  <div className="w-3 h-3 rounded-full bg-theme"></div>
                  <div className="border border-[rgba(1,132,123,0.3)] w-full"></div>
                </aside>
              </div>
              <section className="flex flex-col items-start text-left mb-5 mt-1">
                <p>
                  To get started on Hodlpay, simply sign up for an account,
                  deposit crypto funds and start trading by buying or selling
                  cryptocurrencies. No initial verification needed - you can
                  begin basic trading right away.
                </p>
              </section>
              <br />

              <div className="flex flex-col my-1 items-start">
                <h2 className="text-xl font-semibold text-theme">
                  Does Hodlpay support crypto staking?
                </h2>
                <aside className="flex items-center w-[50%]">
                  <div className="w-3 h-3 rounded-full bg-theme"></div>
                  <div className="border border-[rgba(1,132,123,0.3)] w-full"></div>
                </aside>
              </div>
              <section className="flex flex-col items-start text-left mb-5 mt-1">
                <p>
                  No, Hodlpay currently does not support staking services for
                  proof-ofstake cryptocurrencies. This means you cannot stake
                  and earn rewards on your crypto holdings within the Hodlpay
                  platform. We apologize for the confusion, as we do not have a
                  staking dashboard or functionality available at this time.
                </p>
              </section>
              <br />

              <div className="flex flex-col my-1 items-start">
                <h2 className="text-xl font-semibold text-theme">
                  How do I choose between basic and advanced trading interfaces?
                </h2>
                <aside className="flex items-center w-[50%]">
                  <div className="w-3 h-3 rounded-full bg-theme"></div>
                  <div className="border border-[rgba(1,132,123,0.3)] w-full"></div>
                </aside>
              </div>
              <section className="flex flex-col items-start text-left mb-5 mt-1">
                <p>
                  The basic interface on Hodlpay is ideal for newer traders,
                  with an easy buy/sell process. Advanced traders can take
                  advantage of our professional trading platform with charts,
                  order books and more analysis tools.
                </p>
              </section>
              <br />

              <div className="flex flex-col my-1 items-start">
                <h2 className="text-xl font-semibold text-theme">
                  Does Hodlpay have insurance protection for user funds?
                </h2>
                <aside className="flex items-center w-[50%]">
                  <div className="w-3 h-3 rounded-full bg-theme"></div>
                  <div className="border border-[rgba(1,132,123,0.3)] w-full"></div>
                </aside>
              </div>
              <section className="flex flex-col items-start text-left mb-5 mt-1">
                <p>
                  Yes, user assets stored in Hodlpay's exchange wallets are
                  insured, protecting your funds in unlikely cases of account
                  breaches or theft, subject to coverage limits.
                </p>
              </section>
              <br />

              <div className="flex flex-col my-1 items-start">
                <h2 className="text-xl font-semibold text-theme">
                  How can I reach Hodlpay customer support if I need assistance?
                </h2>
                <aside className="flex items-center w-[50%]">
                  <div className="w-3 h-3 rounded-full bg-theme"></div>
                  <div className="border border-[rgba(1,132,123,0.3)] w-full"></div>
                </aside>
              </div>
              <section className="flex flex-col items-start text-left mb-5 mt-1">
                <p>
                  Our customer support team is available 24/7 via live chat and
                  email. We also offer phone support during business hours. Get
                  in touch if you ever need any help!
                  <br />
                  <br />
                  Reach out if you need any other details! We are always happy
                  to help both new and experienced crypto traders.
                </p>
                <br />
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <PageWrapper content={<Content />} />;
}

export default Faq;
