import React from "react";

function NewsLearnShema() {
  const data = [1, 1, 1, 1];

  return (
    <>
      {data.map((val, idx) => {
        return (
          <div
            key={idx}
            className="w-full h-[170px] rounded-lg bg-gray-100 p-[8px] flex j-c-b mt-1 mb-3"
          >
            <div className="flex h-full w-full">
              <div className="min-w-[180px] rounded-lg h-full bg-gray-200 shema"></div>
              <div className="flex flex-col justify-between items-start ml-1 h-full py-1 w-full">
                <div className="w-full flex flex-col items-start">
                  <div className="w-[100%] h-5 rounded-md bg-gray-200 shema"></div>
                  <div className="w-[80%] h-3 rounded-md bg-gray-200 shema mt-2"></div>
                  <br />
                  <br />
                </div>

                <div className="w-[50%] h-3 rounded-md bg-gray-200 shema mt-2"></div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
export default NewsLearnShema;
