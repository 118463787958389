import React from "react";
import close from "../../../Common/Assets/Images/Close.png";
import empty from "../../../Common/Assets/Images/emptystate.png";
import Switch from "react-switch";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  walletdata: any;
  handleOpenRecieveModal: () => void;
  handleCloseSendModal: () => void;
  handleOpenConfirmModal: () => void;
  handleAmountChange: (e: any) => void;
  handleAddressChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleTransferPriorityChange: (priority: string) => void;
  amountError: string;
  addressError: string;
  transferPriorityError: string;
  amount: string;
  address: string;
  transferPriority: string;
  handleSubmit: (e: React.FormEvent) => void;
  isButtonDisabled: boolean;
  money: any;
  checked: boolean;
  handleChange: (nextChecked: boolean) => void;
  btcamount: string;

  fiatamount: string;
  hasErrors: any;
  isEmpty: any;
  handleReceiveModal(): void;
}

function SendModal({
  isOpen,
  onClose,
  walletdata,
  handleOpenRecieveModal,
  handleCloseSendModal,
  handleOpenConfirmModal,
  handleAmountChange,
  handleAddressChange,
  handleTransferPriorityChange,
  handleSubmit,
  amountError,
  addressError,
  transferPriorityError,
  amount,
  address,
  transferPriority,
  isButtonDisabled,
  money,
  checked,
  btcamount,
  hasErrors,
  isEmpty,

  fiatamount,
  handleChange,
  handleReceiveModal,
}: ModalProps) {
  const btcbalance = walletdata?.walletdata?.balance;
  // const currency = walletdata?.walletdata?.walletName.toLowerCase();
  // console.log("money", walletdata?.walletdata);

  return (
    <>
      <div
        className={`modal fixed w-full h-full  top-0 left-0 flex justify-center overflow-y-auto z-50  p-3 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div className="relative bg-white rounded-lg shadow-md h-auto w-[480px] p-6  h-[600px] ">
          <button onClick={onClose} className="absolute top-0 right-0 p-4 z-50">
            <img src={close} alt="Close" className="h-3 w-3" />
          </button>

          {btcbalance === "0" ? (
            <>
              <div className="modal-content text-center">
                <div className="modal-header pb-2 flex items-center justify-center">
                  <div className="pr-3">
                    <img
                      src="https://exolix.com/icons/coins/BTC.png"
                      alt=""
                      className="w-10 h-10"
                    />
                  </div>
                  <h3 className="modal-title text-[24px] font-bold ">
                    Send Bitcoin
                  </h3>
                </div>
              </div>
              <div className="flex justify-center mt-6 p-12">
                <img src={empty} alt="" className="w-24 h-24" />
              </div>
              <div className="mt-7 text-center">
                <div className="font-bold  text-[20px]">
                  {" "}
                  Looks like you don't have funds yet.{" "}
                </div>
                <div className="text-[14px] mt-2">
                  {" "}
                  Receive crypto to fund wallet.
                </div>
              </div>
              <div
                onClick={handleReceiveModal}
                className="border bg-prevBlue flex rounded-[10px] px-2 py-4 mt-20 items-center justify-center w-full mx-auto cursor-pointer"
              >
                <div className="text-[15px] text-white cursor-pointer ">
                  Receive Crypto
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="modal-content text-center">
                <div className="modal-header pb-2 flex items-center justify-center">
                  <div className="pr-3">
                    <img
                      src="https://exolix.com/icons/coins/BTC.png"
                      alt=""
                      className="w-10 h-10"
                    />
                  </div>
                  <h3 className="modal-title text-[24px] font-bold ">
                    Send Bitcoin
                  </h3>
                </div>

                <div className="flex flex-col items-start  ">
                  <div className="mt-6 font-medium text-[16px]">Amount</div>
                  <div className="w-full flex justify-between border border-gray-300 mt-2 overflow-hidden  rounded-[10px]">
                    <div className="w-full border-r border-gray-300 ">
                      <input
                        className=" px-2  py-4 w-full "
                        value={amount}
                        onChange={handleAmountChange}
                        placeholder={
                          checked ? "Enter USD amount" : "Enter BTC amount"
                        }
                      />
                    </div>

                    <div className="flex items-center">
                      <p className={checked ? "mr-2" : ""}>
                        <span className="text-gray-500 px-4">
                          {checked ? "USD" : " BTC"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex  w-full justify-between  mt-3">
                    <div>
                      {checked ? btcamount + " BTC" : fiatamount + " USD"}
                    </div>

                    <div className="flex items-center">
                      <p className={checked ? "mr-2" : ""}>
                        <span>{checked ? "USD" : "BTC"}</span>
                      </p>
                      <Switch
                        onChange={handleChange}
                        checked={checked}
                        className="mx-2"
                        onColor="#01A69F"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        handleDiameter={20}
                        offColor="#E3E6EA"
                        offHandleColor="#01A69F"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={30}
                        width={60}
                      />
                    </div>
                  </div>

                  {amountError && (
                    <div className="text-red-500 text-sm mt-2   ">
                      {amountError}
                    </div>
                  )}
                </div>

                <div className="flex flex-col items-start">
                  <div className="mt-4 font-medium text-[16px]">
                    {" "}
                    To address
                  </div>

                  <textarea
                    className="border border-gray-300 rounded-[10px] px-2 py-4  w-full mt-2 h-[110px]"
                    value={address}
                    onChange={handleAddressChange}
                    placeholder="Enter an address"
                  />
                  {addressError && (
                    <div className="text-red-500  text-sm  mt-2  ">
                      {addressError}
                    </div>
                  )}
                </div>

                <div className="flex flex-col items-start">
                  <div className="mt-6 font-bold text-[16px]">
                    Transfer Priority
                  </div>
                  <div className=" flex  mt-4">
                    <button
                      className={`border border-gray-300 rounded-[10px] px-4 py-2 mr-6 ${
                        transferPriority === "low"
                          ? "bg-prevBlue text-white border-none"
                          : ""
                      }`}
                      onClick={() => handleTransferPriorityChange("low")}
                    >
                      Low
                    </button>
                    <button
                      className={`border border-gray-300 rounded-[10px] px-4 py-2 mr-6 ${
                        transferPriority === "medium"
                          ? "bg-prevBlue text-white border-none"
                          : ""
                      }`}
                      onClick={() => handleTransferPriorityChange("medium")}
                    >
                      Medium
                    </button>
                    <button
                      className={`border border-gray-300 rounded-[10px] px-4 py-2 ${
                        transferPriority === "high"
                          ? "bg-prevBlue text-white border-none"
                          : ""
                      }`}
                      onClick={() => handleTransferPriorityChange("high")}
                    >
                      High
                    </button>
                  </div>
                  {transferPriorityError && (
                    <span className="text-red-500  text-sm  mt-3">
                      {transferPriorityError}
                    </span>
                  )}
                </div>

                <div className="mt-20">
                  {hasErrors || isEmpty ? (
                    <button
                      className="bg-gray-400 text-gray-300 rounded-[10px] px-4 py-4 w-full cursor-not-allowed"
                      disabled
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      className="bg-prevBlue text-white rounded-[10px] px-4 py-4 w-full hover:bg-prevBlue-600"
                      onClick={handleSubmit}
                    >
                      Next
                    </button>
                  )}
                  <button
                    className="bg-gray-200 text-gray-500 rounded-[10px] px-4 py-4 w-full mt-4"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SendModal;
