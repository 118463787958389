import React, { FormEvent, useContext, useState } from "react";
import Constants from "../../../../Utils/Constants";
import axiosInstance from "../../../../setup/axios";
import Context from "../../../../store/Contex";
import ChangePasswordForm from "./ChangePasswordForm";
import { loader } from "../../../../Common/Assets/Icons";
import OtpInput from "react-otp-input";

function OtpForm() {
  const [otpState, setOtpState] = useState("");
  const { dispatch } = useContext(Context);
  const [isRequesting, setRequesting] = useState(false);

  const handleChangePassword = (e: FormEvent) => {
    e.preventDefault();
    dispatch({
      type: "TURNON",
      payload: <ChangePasswordForm otpNumber={otpState} />,
    });
  };

  const getOtp = () => {
    setRequesting(true);
    axiosInstance
      .post(`${Constants.API_BASE_URL}/auth/user/phone/otp`, {})
      .then((res) => {
        setRequesting(false);
      })
      .catch((e) => {
        setRequesting(false);
      });
  };

  return (
    <>
      <div className="w-[400px]">
        {isRequesting && (
          <div className="absolute w-full h-full bg-[white] flex j-c-c">
            <img src={loader} alt="" width={50} />
          </div>
        )}
        <div className="modal-content text-center">
          <div className="modal-header  flex items-center justify-center">
            <h3 className="modal-title text-[24px] font-bold ">
              OTP Verification
            </h3>
          </div>
        </div>
        <br />
        <form action="" onSubmit={(e) => handleChangePassword(e)}>
          <div className="flex flex-col items-center mt-2">
            <div className=" ">
              <OtpInput
                value={otpState}
                onChange={(value) => setOtpState(value)}
                numInputs={6}
                renderSeparator={<span className="mx-2"></span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  border: "1px solid #d1d5db",
                  textAlign: "center",
                  fontSize: "1.5rem",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  borderRadius: "10px",
                  boxSizing: "border-box",
                  maxWidth: "100%",
                  width: "100%",
                  height: "60px",
                  minHeight: "60px",
                  maxHeight: "20px",
                }}
                inputType="text"
                containerStyle={{
                  display: "flex",
                  width: "100%",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  height: "100%",
                }}
              />
            </div>

            {/* <input
                        required
                        value={otpState} // Access 'otpNumber' directly from 'otpState'
                        onChange={(e) => setOtpState(e.target.value)}
                        type="text"
                        className="p-2"
                        style={{ width: '80%', height: '100%', border: 'none', outline: 'none' }}
                        placeholder="xxxx"
                        autoFocus
                        
                    /> */}
          </div>
          <div className="flex mb-8">
            <div className="flex">
              Didn't receive?{" "}
              <b
                className="text-info p-2 text-blue ml-[1px] font-normal hover:text-blue "
                onClick={() => getOtp()}
                style={{ cursor: "pointer" }}
              >
                Resend
              </b>
            </div>
          </div>
          <button className=" bg-blue text-white w-full h-[45px] mt-9 rounded-[10px] mb-8 m">
            Next
          </button>
        </form>
      </div>
    </>
  );
}

export default OtpForm;
